import {
  BasicExperienceObject,
  JobExperienceData,
  ProjectExperienceData,
} from './ExperienceObject';
import TeamObject, { BasicTeamObject } from './TeamObject';
import {
  BasicConnectionObject,
  BasicConnectionObjectV2,
} from './ConnectionObject';
import { DateISOString } from './misc';
import TimezoneObject from './TimezoneObject';
import LocationObject from './LocationObject';
import RoleCategoryObject from './RoleCategory';
import { BasicMissionObject } from './MissionObject';
import { MissionRoleId } from './MissionRole';
import UserQuestionnaire from './UserQuestionnaire';
import { MissionApplicationId } from './MissionApplicationObject';
import { TalentProfile, TalentSkillId } from './TalentCategories';
import { AvailabilitySummaryObject } from './AvailabilityObject';
import { UserFeatureFlag } from './FeatureFlag';
import { AdminNotesScore } from './AdminNotesObject';
import { BasicClientCompanyObject } from './ClientCompanies';
import { WorkingHoursSchema } from './WorkingHoursObject';
import PortfolioObject from './PortfolioObject';
import { CustomUserTagObject } from './CustomUserTagObject';
import { VettingType } from './vetting-processes/vetting-processes';
import { NUXQuestionnaire } from './NuxQuestionnaire';
import { ClientRegistration } from './ClientRegistration';
import {
  CalComEventType,
  CalComSchedule,
  CalComSelectedCalendar,
} from './vetting-processes/calcom';

export type UserId = string;
export type UserUsername = string;

export enum UserType {
  User = 'user',
  CompanyUser = 'companyUser',
}

export enum UserStatus {
  Registered = 'Registered',
  Active = 'Active',
  ActiveWaitlisted = 'ActiveWaitlisted',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
}

export enum CollaboratorStatus {
  Pending = 'Pending',
  Active = 'Active',
}

export enum UserScrubbed {
  Exceptional = 'Exceptional',
  Verified = 'Verified',
  Unknown = 'Unknown',
  Insufficient = 'Insufficient',
}

export type UserScrubbedValue = 0 | 1 | -1 | 2;

export const InsufficentUserScrubbedValue: UserScrubbed[] = [
  UserScrubbed.Insufficient,
];
export const UnverifiedUserScrubbedValues: UserScrubbed[] = [
  UserScrubbed.Unknown,
  UserScrubbed.Insufficient,
];

export const UserScrubbedLabels: Record<UserScrubbed, string> = {
  [UserScrubbed.Exceptional]: 'Exceptional (0)',
  [UserScrubbed.Verified]: 'Verified (1)',
  [UserScrubbed.Unknown]: 'Unknown (-1)',
  [UserScrubbed.Insufficient]: 'Insufficient (2+)',
};

export const UserScrubbedCodes: Record<UserScrubbed, UserScrubbedValue> = {
  [UserScrubbed.Exceptional]: 0,
  [UserScrubbed.Verified]: 1,
  [UserScrubbed.Unknown]: -1,
  [UserScrubbed.Insufficient]: 2,
};

export enum UserExpertise {
  Developers = 'Development',
  Designers = 'Design',
  ProductManagers = 'Product',
  DataSpecialists = 'Data Scientist',
  Marketing = 'Growth/Marketing',
  Others = 'Others',
}

export interface UserTags<T> {
  expertise: Array<T>;
  industries: Array<T>;
  skills: Array<T>;
}

export interface AnonymousUserObject {
  username: null;
  email?: string;
  fullName: string;
}

export interface BasicUserObject {
  uid: UserId;
  type: UserType;
  fullName: string;
  firstName: string;
  lastName: string;
  username: UserUsername;
  email?: string;
  profilePictureURL: string;
  profileURL: string;
  title: string;
  verified: boolean;
  timezone?: TimezoneObject;
  workingHours?: WorkingHoursSchema;
  badges: UserBadge[];
  status: UserStatus;
  // Will return only for company users
  clientCompany?: BasicClientCompanyObject[];
}

export interface AdminBasicUserObject extends BasicUserObject {
  scrubbed?: UserScrubbed;
  englishScore?: AdminNotesScore;
  expertiseScore?: AdminNotesScore;
  interactionExperienceScore?: AdminNotesScore;
  accentScore?: AdminNotesScore;
  createdAt: DateISOString;
  email: string;
  acceptTOS?: {
    signedAt?: Date;
  };
  communityBadges?: CommunityBadgeObject[];
}

export interface AdminUnvettedApplicant extends AdminBasicUserObject {
  roleCategory: RoleCategoryObject | null;
  appliedMissions: BasicMissionObject[];
}

export interface SenderUserObject extends BasicUserObject {
  email: string;
}

export type MinimalSenderUserObject = Pick<
  SenderUserObject,
  'uid' | 'email' | 'fullName' | 'firstName'
>;

export interface TaggedSenderUserObject extends MinimalSenderUserObject {
  tags?: Record<string, string | undefined>;
}

export interface UserContentData {
  status: UserStatus;
  linkedIn?: {
    username: string;
    profileUrl: string;
  };
  google?: {
    openId: string;
  };
  facebook?: {
    id: string;
    profileUrl: string;
  };
  github?: {
    username: string;
    profileUrl: string;
  };
  titles: string[];
  location?: LocationObject;
  timezone?: TimezoneObject;
  workingHours?: WorkingHoursSchema;
  websites?: string[];
  dribbble?: {
    username: string;
    profileURL: string;
  };
  behance?: {
    username: string;
    profileURL: string;
  };
  medium?: {
    username: string;
    profileURL: string;
  };
  productHunt?: {
    username: string;
    profileURL: string;
  };
  companyRole?: string;
  schedulingLink?: string;
}

export interface UserCollaborator extends BasicUserObject {
  memberTitle: string;
  experiencesCount: number;
  collaboratorRank: number;
}

export type NarrativeKey =
  | 'missions'
  | 'projects'
  | 'teams'
  | 'invitations'
  | 'connections'
  | 'companies'
  | 'invitedBy'
  | 'missionRecommendations';

export type ConnectionSummaryType =
  | NarrativeKey
  | 'mutualConnections'
  | 'location'
  | 'role';

export interface NarrativeConnectionSummary {
  types: ConnectionSummaryType[];
  projectsCount?: number;
  companies?: string[];
  inviterUser?: string;
  mutualConnectionsCount?: number;
  missionsCount?: number;
}

export interface UserCardObject extends BasicUserObject {
  aboutMe?: string;
  roleCategory: RoleCategoryObject | null;
  connection: BasicConnectionObject | null;
  connections: BasicConnectionObjectV2[] | [];
  narrativeConnection?: NarrativeConnectionSummary;
  talentProfile?: TalentProfile;
  location?: LocationObject;
  calComUserId?: number;
  calComConnected?: boolean;
  isUsingSharedCalendar?: boolean;
  pendingAutomatedRejection?: boolean;
}

export interface UserSuggestedTeamUp extends UserCardObject {
  invitationStatus?: 'considered' | 'invitedByYou' | 'invitedByThem';
  nonAvailabilityReason?: 'notSelected' | 'unavailable';
}

export interface TeammateCardObject extends UserCardObject {
  location?: LocationObject;
  profileTags: UserTags<string>;
  teammateTags: UserTags<{ label: string; mutual: boolean }>;
}

export interface SelectionTeamCardObject extends UserCardObject {
  hasCalendarConnected: boolean;
  vettingType: VettingType;
}

export interface TeammateSuggestionObject extends TeammateCardObject {
  aid?: MissionApplicationId;
  roleIds: MissionRoleId[];
}
export interface PromptAvailUpdateObject {
  shouldUpdate: boolean;
  profileHours: number;
  applicationHours: number;
}

export interface ConnectionTypeSummary {
  totalConnections: number;
  sampleUsersToBeDisplayed: BasicUserObject[];
}

export interface ConnectionsSummary {
  work: ConnectionTypeSummary;
  teamUp: ConnectionTypeSummary;
  totalConnections: number;
  sampleUsersToBeDisplayed: BasicUserObject[];
  totalPendingConnections: number;
}

export interface CustomTagsMetadata {
  assignedAt?: DateISOString;
  tagId: string;
}

export interface DefaultRates {
  hourlyRate: number;
  monthlyRate: number;
}

export default interface UserObject
  extends Omit<TeammateCardObject, 'teammateTags'>,
    UserContentData {
  createdAt: DateISOString;
  activatedAt?: DateISOString;
  jobs: Array<BasicExperienceObject & JobExperienceData>;
  projects: Array<BasicExperienceObject & ProjectExperienceData>;
  teams?: BasicTeamObject[];
  connectionsCount: number;
  // connection: MinimalConnectionObject | null;
  yearsOfExperience?: number;
  invitedBy?: BasicUserObject;
  frequentCollaborators: UserCollaborator[];
  isVetter?: boolean;
  calComUserId?: number;
  availability?: AvailabilitySummaryObject;
  talentProfile?: TalentProfile;
  verifiedSkills?: TalentSkillId[];

  // will return only for admins
  scrubbed?: UserScrubbed;
  email?: string;
  phoneNumber?: string;
  englishScore?: AdminNotesScore;
  interactionExperienceScore?: AdminNotesScore;
  expertiseScore?: AdminNotesScore;
  accentScore?: AdminNotesScore;
  manualVettingProcess?: boolean;
  rateRange?: {
    min: number;
    max: number;
  };
  exclusiveApplication?: {
    aid: MissionApplicationId;
    updatedAt: DateISOString;
  };
  connectionsSummary?: ConnectionsSummary;
  portfolio?: {
    url: string;
    description: string;
    password?: string;
  };
  customTags?: CustomUserTagObject[];
  customTagsMetadata?: CustomTagsMetadata[];
  communityBadges?: CommunityBadgeObject[];
  scrubbedMinusOneReasons?: ScrubReason[];
  cvURL?: string;
  isAdmin?: boolean;
  NUXQuestionnaire?: NUXQuestionnaire;
  clientRegistration?: ClientRegistration;
  customCalendar?: CustomCalendarObject;
  unSupportedCalendarName?: string;
  calComConnected?: boolean;
  isUsingSharedCalendar?: boolean;
  lastEvaluationProcess?: {
    createdAt?: DateISOString;
    id: string;
  };
  defaultRates?: DefaultRates;
  historicAverageHourlyRate?: number;
  calendarConnected?: boolean;
  pendingAutomatedRejection?: boolean;
}

export interface BasicRegisteredUserObject
  extends Omit<BasicUserObject, 'username' | 'profileURL'> {
  username: UserUsername | null;
}

export interface UserReferral {
  firstName: string;
  lastName?: string;
  linkedinUrl?: string;
  website?: string;
  email: string;
}

export interface RegisteredUserObject
  extends BasicRegisteredUserObject,
    UserContentData {
  aboutMe?: string;
  roleCategory: RoleCategoryObject | null;
  email: string;
  questionnaire?: UserQuestionnaire;
  utmSource?: string;
  utmCampaign?: string;
  isAdmin?: boolean;
  wasScrubbed?: boolean;
  lacksInformation?: boolean;
  needsAcceptTOS?: boolean;
  needsAcceptPlatformCodeOfConduct?: boolean;
  needsAcceptMissionCodeOfConduct?: boolean;
  needsWhatsNewModal?: boolean;
  needsWhatsNewBanner?: boolean;
  optedOutOfClientDiscovery?: boolean;
  optedOutOfBuilderDiscovery?: boolean;
  abFlags: Record<string, boolean>;
  outdatedToken?: boolean;
  yearsOfExperience?: number;
  talentProfile?: TalentProfile;
  featureFlags?: UserFeatureFlag[];
  jobs?: Array<BasicExperienceObject & JobExperienceData>;
  projects?: Array<BasicExperienceObject & ProjectExperienceData>;
  linkedInOptOut?: boolean;
  rateRange?: {
    min: number;
    max: number;
  };
  referrals?: UserReferral[];
  teams?: BasicTeamObject[];
  portfolio?: PortfolioObject;
  joinedAt: DateISOString;
  cvURL?: string;
  hasAccessToCommunity?: boolean;
  NUXQuestionnaire?: NUXQuestionnaire;
  clientRegistration?: ClientRegistration;
  unSupportedCalendarName?: string;
  defaultRates?: DefaultRates;
  historicAverageHourlyRate?: number;
}

export interface CurrentUserObject extends UserObject {
  initialReviewDate?: DateISOString; // Refers to scrub date
  email: string;
  phoneNumber?: string;
  activeMissions: BasicMissionObject[];
  questionnaire?: UserQuestionnaire;
  hasArchivedMissions: boolean;
  isAdmin?: boolean;
  wasScrubbed?: boolean;
  needsAcceptTOS?: boolean;
  lacksInformation?: boolean;
  needsAcceptPlatformCodeOfConduct?: boolean;
  needsAcceptMissionCodeOfConduct?: boolean;
  needsWhatsNewModal?: boolean;
  needsWhatsNewBanner?: boolean;
  profileCompleteness?: {
    haveNotHadThreeJobs?: boolean;
    dismissed?: boolean;
  };
  optedOutOfClientDiscovery?: boolean;
  optedOutOfBuilderDiscovery?: boolean;
  abFlags: Record<string, boolean>;
  outdatedToken?: boolean;
  featureFlags?: UserFeatureFlag[];
  linkedInOptOut?: boolean;
  teams?: TeamObject[];
  invitedUsers?: InvitedUserObject[];
  activatedAt?: DateISOString;
  // we have a logic that trigers newly registered user flow if there is is createdAt, that's why we need to call it joinedAt to avoid conflict.
  joinedAt: DateISOString;
  cvURL?: string;
  hasAccessToCommunity?: boolean;
  needsTimesheetInitiativesGuidance?: boolean;
}

export interface InvitedUserObject {
  username: string;
  firstName: string;
  lastName: string;
  createdAt: string;
}

export enum CommunityBadgeType {
  PowerUser = 'beta_poweruser',
  CommunityLeader = 'community_leader',
  GuildFintech = 'guild_fintech',
  GuildHealthcare = 'guild_healthcare',
  GuildAI = 'guild_ai',
  GuildFrontend = 'guild_frontend',
  ValuableFeedbackGiver = 'beta_feedbackgiver',
  AIAugmentedCourseGraduates = 'ai_augmented_course_graduates',
}

export interface CommunityBadgeObject {
  type: CommunityBadgeType;
  assignedAt: DateISOString;
}

export enum UserBadge {
  SelectionTeam = 'SelectionTeam',
  BeenOnMission = 'BeenOnMission',
  ExceptionalATeamer = 'ExceptionalATeamer',
  ATeamerResidence = 'ATeamerResidence',
  ATeamer = 'ATeamer',
  VettingScheduled = 'VettingScheduled',
  VettingInterviewDate = 'VettingInterviewDate',
  HighPotential = 'HighPotential',
  Unvetted = 'Unvetted',
  NotScrubbed = 'NotScrubbed',
  UnqualifiedUser = 'UnqualifiedUser',
  LimitedAccess = 'LimitedAccess',
}

export interface ScrubbingInfoUserObject {
  uid: UserId;
  type: UserType;
  firstName: string;
  lastName: string;
  email: string;
  username?: UserUsername;
  status: UserStatus;
  scrubbed?: UserScrubbed;
  createdAt: DateISOString;
  scrubDate?: Date;
  activatedAt?: DateISOString;
  hasAcceptedTOS: boolean;
  hasPassword: boolean;
  authToken: string;
}

export interface GoogleAuthUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  pictureURL?: string;
  google: { email: string };
}

export interface ScrubReason {
  text: string;
  section?: string;
  details?: string[];
}
export interface CalendarSettingsObject {
  selectedCalendar?: CalComSelectedCalendar;
  schedule?: CalComSchedule;
  eventType?: CalComEventType;
  autoRecord?: boolean;
  hasAvailability?: boolean;
}

export enum NoCalendarReason {
  NonSupportedCalendar = 'NonSupportedCalendar',
  PrivacyConcern = 'PrivacyConcern',
  MultipleCalendars = 'MultipleCalendars',
  NoGoogle = 'NoGoolge',
  NoReason = 'NoReason',
  TechIssue = 'TechIssue',
  ClientPrivacy = 'ClientPrivacy',
  CustomAvailability = 'CustomAvailability',
  DataSecurity = 'DataSecurity',
  Other = 'Other',
}

export enum OtherCalendarType {
  Apple = 'Apple',
  Outlook = 'Outlook',
  Yahoo = 'Yahoo',
  Other = 'Other',
}

export interface CustomCalendarObject {
  mainReason: NoCalendarReason;
  calendarLink: string;
  calendarType?: OtherCalendarType;
  calendarName?: string;
  reasonText?: string;
}

export interface CalendarSettings {
  calcom?: CalendarSettingsObject;
  custom?: CustomCalendarObject;
}

export interface CalendarOptoutObject {
  mainReason: NoCalendarReason;
  reasonText?: string;
}
