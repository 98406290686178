import React, { ReactElement } from 'react';
import OutlinedInput from '../Inputs/OutlinedInput';
import { createUseStyles } from 'react-jss';
import useDebounceState from '@src/hooks/useDebounceState';

const MIN_HOURLY_RATE_DEFAULT = 0;
const MAX_HOURLY_RATE_DEFAULT = 250;

const useStyles = createUseStyles({
  ratesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  rateInput: {
    decoration: 'none',
    margin: 0,
    '& > div': {
      borderRadius: 4,
      minHeight: 'unset',
      padding: '0.6em 0.5em',
    },
  },
});

interface Props {
  onChange: (rates: (number | undefined)[]) => void;
  hourlyRateRangeMin?: number;
  hourlyRateRangeMax?: number;
}

const CURRENCY_SYMBOL = '$';

const HourlyRateRange = (props: Props): ReactElement => {
  const styles = useStyles();
  const { hourlyRateRangeMax, hourlyRateRangeMin, onChange } = props;
  const [displayedRange, setDisplayedRange] = useDebounceState(
    [
      hourlyRateRangeMin ? CURRENCY_SYMBOL + hourlyRateRangeMin : '',
      hourlyRateRangeMax ? CURRENCY_SYMBOL + hourlyRateRangeMax : '',
    ],
    (rates: (string | undefined)[]) => {
      onChangeInternal(rates);
    },
    700,
  );

  const onChangeInternal = (rates: (string | undefined)[]) => {
    let hourlyRateRangeMin: number | undefined = undefined;
    let hourlyRateRangeMax: number | undefined = undefined;

    if (rates[0]) {
      hourlyRateRangeMin = Number(rates[0].replace(CURRENCY_SYMBOL, ''));
      if (isNaN(hourlyRateRangeMin)) {
        hourlyRateRangeMin = undefined;
      } else {
        hourlyRateRangeMin = Math.max(
          hourlyRateRangeMin,
          MIN_HOURLY_RATE_DEFAULT,
        );
      }
    }

    if (rates[1]) {
      hourlyRateRangeMax = Number(rates[1].replace(CURRENCY_SYMBOL, ''));
      if (isNaN(hourlyRateRangeMax)) {
        hourlyRateRangeMax = undefined;
      } else {
        hourlyRateRangeMax = Math.min(
          hourlyRateRangeMax,
          MAX_HOURLY_RATE_DEFAULT,
        );
      }
    }

    onChange([hourlyRateRangeMin, hourlyRateRangeMax]);
  };

  return (
    <div className={styles.ratesContainer}>
      <OutlinedInput
        type="text"
        className={styles.rateInput}
        onBlur={(e) => setDisplayedRange([e.target.value, displayedRange[1]])}
        onChange={(e) => setDisplayedRange([e.target.value, displayedRange[1]])}
        value={displayedRange[0]}
        precursor={<span style={{ marginLeft: 4 }}>From</span>}
        textAlign="right"
      />
      <OutlinedInput
        type="text"
        className={styles.rateInput}
        onBlur={(e) => setDisplayedRange([displayedRange[0], e.target.value])}
        onChange={(e) => setDisplayedRange([displayedRange[0], e.target.value])}
        precursor={<span style={{ marginLeft: 4 }}>To</span>}
        value={displayedRange[1]}
        textAlign="right"
      />
    </div>
  );
};

export default HourlyRateRange;
