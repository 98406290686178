import { action, computed, observable } from 'mobx';
import type { MissionId } from '@a_team/models/dist/MissionObject';
import MissionObject, {
  BasicMissionObject,
  MissionAdminObject,
  MissionCardObject,
  MissionSearchResult,
  MissionStatus,
} from '@a_team/models/dist/MissionObject';
import type { MissionRoleId } from '@a_team/models/dist/MissionRole';
import MissionRole, {
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import AuthStore from '@src/stores/Auth';
import {
  apiMissions,
  apiRegistration,
  apiUsers,
} from '@src/logic/services/endpoints';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import { MissionData } from '@ateams/api/dist/endpoints/Missions';
import { RoleCategoryId } from '@a_team/models/dist/RoleCategory';
import MissionApplicationObject, {
  AdminMissionApplicationObject,
  MissionApplicationBasicObject,
  MissionApplicationData,
  MissionApplicationDraftObject,
  MissionApplicationId,
  MissionApplicationPreview,
} from '@a_team/models/dist/MissionApplicationObject';
import { Stores } from '@src/stores';
import Mission, {
  Modes,
  SerializedMissionStoreData,
} from '@src/stores/Missions/Mission';
import { CreateCompanyUserPayload } from '@ateams/api/dist/endpoints/Registration';
import { ServiceAuth } from '@ateams/api/dist/endpoints/utils';
import type UserObject from '@a_team/models/dist/UserObject';
import { TeammateSuggestionObject } from '@a_team/models/dist/UserObject';
import { MinimalRecommendationData } from '@a_team/models/dist/RecommendationObject';
import { apiDiscovery } from '@ateams/api';
import Profile from '@src/stores/Profile/Profile';
import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import MissionPaymentCycle, {
  MissionPaymentCycleStoreData,
} from '@src/stores/Missions/MissionPaymentCycle';
import {
  MissionPaymentCycleAdminObject,
  MissionPaymentCycleAdminReportObject,
} from '@a_team/models/dist/MissionPaymentCycleObject';
import { TalentIndustrySummary } from '@a_team/models/dist/TalentIndustry';
import { uniq } from 'lodash';

type SerializedTeamWorkStoreData = Omit<
  MissionsStoreData,
  'currentMission' | 'endedPaymentCycles'
> & {
  currentMission?: SerializedMissionStoreData;
  endedPaymentCycles: MissionPaymentCycleStoreData[];
};

export interface MissionsStoreData {
  currentApplication?: Profile;
  currentMission?: Mission;
  adminViewMissions: {
    createdMissions?: MissionCardObject[];
    publishedMissions?: MissionCardObject[];
    pendingMissions?: MissionCardObject[];
    runningMissions?: MissionCardObject[];
    archivedMissions?: MissionCardObject[];
    endedMissions?: MissionCardObject[];
    scheduledToEndMissions?: MissionCardObject[];
  };
  clientMissions?: {
    active: QueryResult<MissionCardObject>;
    pending: QueryResult<MissionCardObject>;
    archived: QueryResult<MissionCardObject>;
  };
  adminNextTokens: {
    createdMissions: QueryNextToken | null;
    publishedMissions: QueryNextToken | null;
    pendingMissions: QueryNextToken | null;
    runningMissions: QueryNextToken | null;
    archivedMissions: QueryNextToken | null;
    endedMissions: QueryNextToken | null;
    scheduledToEndMissions: QueryNextToken | null;
    endedCycles: QueryNextToken | null;
  };
  endedPaymentCycles: MissionPaymentCycle[];
}

export interface FilterQuery {
  query?: string;
  roles?: RoleCategoryId[];
}

export default class MissionsStore implements MissionsStoreData {
  @observable currentApplication: MissionsStoreData['currentApplication'];
  @observable currentMission: MissionsStoreData['currentMission'];
  @observable adminViewMissions: MissionsStoreData['adminViewMissions'] = {
    createdMissions: undefined,
    publishedMissions: undefined,
    pendingMissions: undefined,
    runningMissions: undefined,
    archivedMissions: undefined,
    endedMissions: undefined,
    scheduledToEndMissions: undefined,
  };
  @observable clientMissions: MissionsStoreData['clientMissions'];
  @observable adminNextTokens: MissionsStoreData['adminNextTokens'] = {
    createdMissions: null,
    publishedMissions: null,
    pendingMissions: null,
    runningMissions: null,
    archivedMissions: null,
    endedMissions: null,
    scheduledToEndMissions: null,
    endedCycles: null,
  };
  @observable endedPaymentCycles: MissionsStoreData['endedPaymentCycles'] = [];

  private readonly rootStore: Stores;
  private readonly analytics: PlatformServiceAnalytics;

  public constructor(
    rootStore: Stores,
    analytics: PlatformServiceAnalytics,
    initialState?: MissionsStoreData,
  ) {
    this.rootStore = rootStore;
    this.analytics = analytics;

    if (initialState) {
      this.currentApplication = initialState.currentApplication;
      this.adminNextTokens = initialState.adminNextTokens;
      this.currentMission = initialState.currentMission
        ? new Mission(
            initialState.currentMission?.data,
            this.rootStore.auth,
            this.rootStore.profile,
            this,
            this.rootStore.payments,
            initialState.currentMission.mode === Modes.Admin,
            initialState.currentMission,
            initialState.currentMission.isRecommended,
          )
        : undefined;
      this.adminViewMissions = initialState.adminViewMissions;

      this.clientMissions = initialState.clientMissions;
      this.endedPaymentCycles =
        initialState.endedPaymentCycles.map(
          (cycle) => new MissionPaymentCycle(cycle.data),
        ) || [];
    }
  }

  public serialize(): SerializedTeamWorkStoreData {
    return {
      currentApplication: this.currentApplication,
      currentMission: this.currentMission?.serialize(),
      adminViewMissions: this.adminViewMissions,
      clientMissions: this.clientMissions,
      adminNextTokens: this.adminNextTokens,
      endedPaymentCycles:
        this.endedPaymentCycles.map((cycle) => cycle.serialize()) || [],
    };
  }

  public getClientMissions = async (): Promise<void> => {
    const active = await this.getActiveMissions(this.rootStore.auth);
    const pending = await this.getPendingMissions(this.rootStore.auth);
    const archived = await this.getArchivedMissions(this.rootStore.auth);
    this.setClientMissions(active, pending, archived);
  };

  public reloadCurrentMission = async (
    mid: MissionId,
    isAdmin: boolean,
  ): Promise<void> => {
    let mission: MissionAdminObject | MissionObject;
    if (
      this.rootStore.auth.maybeAuth &&
      (isAdmin || this.rootStore.auth.maybeAuth?.isAdmin)
    ) {
      mission = await apiMissions.adminGetMissionById(
        this.rootStore.auth.maybeAuth,
        mid,
      );
    } else {
      mission = await apiMissions.getMissionById(
        this.rootStore.auth.maybeAuth,
        mid,
      );
    }

    if (
      !this.rootStore.missionControl.recommended &&
      this.rootStore.auth.currentUser
    ) {
      await this.rootStore.missionControl.loadRecommendedMissions();
    }

    const isRecommended =
      !!this.rootStore.missionControl.recommended?.items.find(
        (rec) => rec.mid === mission.mid,
      );

    if (
      !this.rootStore.missionControl.notInterested &&
      this.rootStore.auth.currentUser
    ) {
      await this.rootStore.missionControl.loadNotInterestedMissions();
    }

    const isNotInterested =
      !!this.rootStore.missionControl.notInterested?.items.find(
        (notInt) => notInt.mid === mission.mid,
      );

    this.setCurrent(mission, isAdmin, isRecommended, isNotInterested);
  };

  public loadCurrent = async (
    mid: MissionId,
    isAdmin: boolean,
    loadAppDraft?: boolean,
    rid?: string,
    auth?: ServiceAuth,
    aid?: string,
  ): Promise<void> => {
    const changing = !!this.currentMission?.isAdminMode !== isAdmin;
    this.currentMission?.setMode(isAdmin ? Modes.Admin : Modes.User);

    if (mid === this.currentMission?.mid && !changing) {
      if (!loadAppDraft) {
        return Promise.resolve();
      } else {
        if (this.currentApplication) return Promise.resolve();
      }
    }

    await this.reloadCurrentMission(mid, isAdmin);
    await this.currentMission?.refreshTeamPulse(isAdmin);
    try {
      isAdmin && (await this.currentMission?.getMissionNotifications());
    } catch (err) {
      // do nothing
    }
  };

  public async update(
    auth: AuthStore,
    mid: MissionId,
    data: MissionData,
  ): Promise<void> {
    const doc = await apiMissions.updateMission(auth, mid, data);

    this.setCurrent(doc);
  }

  public async getActiveMissions(
    auth: AuthStore,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.getActiveMissions(auth);

    return doc;
  }

  public async getPendingMissions(
    auth: AuthStore,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.getPendingMissions(auth);

    return doc;
  }

  public async getArchivedMissions(
    auth: AuthStore,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.getArchivedMissions(auth);

    return doc;
  }

  public async getAdminViewMissions(
    auth: AuthStore,
    filter?: string,
    extendedSearch?: boolean,
  ): Promise<void> {
    const doc = await apiMissions.adminViewMissions(
      auth,
      filter,
      extendedSearch,
    );
    this.updateAdminCreatedMissions(doc.createdMissions.items);
    this.updateAdminPublishedMissions(doc.publishedMissions.items);
    this.updateAdminPendingMissions(doc.pendingMissions.items);
    this.updateAdminPublishedMissions(doc.publishedMissions.items);
    this.updateAdminRunningMissions(doc.runningMissions.items);
    this.updateAdminArchivedMissions(doc.archivedMissions.items);
    this.updateAdminEndedMissions(doc.endedMissions.items);
    this.updateAdminScheduledToEndMissions(doc.scheduledToEndMissions.items);

    this.setAdminTokens('createdMissions', doc.createdMissions.next);
    this.setAdminTokens('publishedMissions', doc.publishedMissions.next);
    this.setAdminTokens('pendingMissions', doc.pendingMissions.next);
    this.setAdminTokens('runningMissions', doc.runningMissions.next);
    this.setAdminTokens('archivedMissions', doc.archivedMissions.next);
    this.setAdminTokens('endedMissions', doc.endedMissions.next);
    this.setAdminTokens(
      'scheduledToEndMissions',
      doc.scheduledToEndMissions.next,
    );
  }

  public async getAdminCreatedMissions(
    isLoadMore?: boolean,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.adminGetMissions(
      this.rootStore.auth,
      MissionStatus.Created,
      this.adminNextTokens.createdMissions || undefined,
    );
    this.updateAdminCreatedMissions(doc.items, isLoadMore);
    this.setAdminTokens('createdMissions', doc.next);

    return doc;
  }

  public async getAdminPublishedMissions(
    isLoadMore?: boolean,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.adminGetMissions(
      this.rootStore.auth,
      MissionStatus.Published,
      this.adminNextTokens.publishedMissions || undefined,
    );

    this.updateAdminPublishedMissions(doc.items, isLoadMore);
    this.setAdminTokens('publishedMissions', doc.next);

    return doc;
  }

  public async getAdminPendingMissions(
    isLoadMore?: boolean,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.adminGetMissions(
      this.rootStore.auth,
      MissionStatus.Pending,
      this.adminNextTokens.pendingMissions || undefined,
    );

    this.updateAdminPendingMissions(doc.items, isLoadMore);
    this.setAdminTokens('pendingMissions', doc.next);

    return doc;
  }

  public async getAdminRunningMissions(
    isLoadMore?: boolean,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.adminGetMissions(
      this.rootStore.auth,
      MissionStatus.Running,
      this.adminNextTokens.runningMissions || undefined,
    );

    this.updateAdminRunningMissions(doc.items, isLoadMore);
    this.setAdminTokens('runningMissions', doc.next);

    return doc;
  }

  public async getAdminArchivedMissions(
    isLoadMore?: boolean,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.adminGetMissions(
      this.rootStore.auth,
      MissionStatus.Archived,
      this.adminNextTokens.archivedMissions || undefined,
    );

    this.updateAdminArchivedMissions(doc.items, isLoadMore);
    this.setAdminTokens('archivedMissions', doc.next);

    return doc;
  }

  public async getAdminEndedMissions(
    isLoadMore?: boolean,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.adminGetMissions(
      this.rootStore.auth,
      MissionStatus.Ended,
      this.adminNextTokens.endedMissions || undefined,
    );

    this.updateAdminEndedMissions(doc.items, isLoadMore);
    this.setAdminTokens('endedMissions', doc.next);

    return doc;
  }

  public async getAdminScheduledToEndMissions(
    isLoadMore?: boolean,
  ): Promise<QueryResult<MissionCardObject>> {
    const doc = await apiMissions.adminGetMissions(
      this.rootStore.auth,
      MissionStatus.ScheduledToEnd,
      this.adminNextTokens.scheduledToEndMissions || undefined,
    );

    this.updateAdminScheduledToEndMissions(doc.items, isLoadMore);
    this.setAdminTokens('scheduledToEndMissions', doc.next);

    return doc;
  }

  public async getUserArchivedMissions(
    auth: AuthStore,
  ): Promise<QueryResult<BasicMissionObject>> {
    return await apiMissions.getArchivedMissions(auth);
  }

  public async getMissionApplicationDraft(
    auth: AuthStore,
    rid: string,
  ): Promise<MissionApplicationDraftObject> {
    const doc = await apiMissions.getMissionApplicationDraft(auth, rid);
    return doc;
  }

  public async applyMission(
    rid: string,
    data: MissionApplicationData,
    mid: MissionId,
  ): Promise<MissionApplicationObject> {
    const doc = await apiMissions.applyMission(this.rootStore.auth, rid, data);
    this.updateRole(rid, doc, mid);
    return doc;
  }

  public getTeammateSuggestions = async (
    rid: MissionRoleId,
  ): Promise<QueryResult<TeammateSuggestionObject>> => {
    if (!this.currentMission) return Promise.reject();
    return await apiMissions.getTeammateSuggestions(
      this.rootStore.auth,
      this.currentMission?.mid,
      rid,
    );
  };

  public searchTeammateSuggestions = async (
    rid: MissionRoleId,
    query: string,
  ): Promise<QueryResult<TeammateSuggestionObject>> => {
    if (!this.currentMission) return Promise.reject('Missing current Mission');
    return await apiDiscovery.searchProspectiveTeammates(
      this.rootStore.auth,
      this.currentMission?.mid,
      rid,
      query,
    );
  };

  public submitTeammateRecommendations = async (
    aid: MissionApplicationId,
    recommendations: MinimalRecommendationData[],
  ): Promise<void> => {
    if (!this.currentMission) return Promise.reject();
    return await apiMissions.submitTeammateRecommendations(
      this.rootStore.auth,
      this.currentMission?.mid,
      aid,
      recommendations,
    );
  };

  public async publish(auth: AuthStore, mid: MissionId): Promise<void> {
    const doc = await apiMissions.adminPublishMission(auth, mid);

    this.setCurrent(doc);
  }

  public async delete(auth: AuthStore, mid: MissionId): Promise<void> {
    await apiMissions.deleteMission(auth, mid);
  }

  public createCompanyUser = async (
    data: CreateCompanyUserPayload,
  ): Promise<void> => {
    await apiRegistration.adminCreateCompanyUser(this.rootStore.auth, data);
  };

  public fetchApplicationPreview = async (
    uid: string,
    username: string,
    targeted: boolean | undefined,
    mid: MissionId,
    rid: MissionRoleId,
  ): Promise<MissionApplicationPreview> => {
    const [user, preview] = await Promise.all([
      apiUsers.getUserByUsername(this.rootStore.auth, username),
      apiMissions.previewTeammateApplication(this.rootStore.auth, uid, mid),
    ]);

    this.currentApplication = new Profile(
      user,
      this.rootStore.auth,
      this.analytics,
      this.rootStore.missions,
      !!targeted,
    );

    return preview;
  };

  public searchAdminMission = async (
    input: string,
    extendedSearch?: boolean,
  ): Promise<MissionSearchResult[]> => {
    return await apiMissions.adminFindMissions(
      this.rootStore.auth,
      input,
      extendedSearch,
    );
  };

  getApplicationProfile = async (
    username: string,
    currentApplication?: MissionApplicationObject,
    rid?: MissionRoleId,
  ): Promise<Profile> => {
    const user = await apiUsers.getUserByUsername(
      this.rootStore.auth,
      username,
    );
    this.currentApplication = new Profile(
      user,
      this.rootStore.auth,
      this.analytics,
      this.rootStore.missions,
    );
    rid &&
      currentApplication &&
      this.setCurrentApplication(rid, currentApplication, user);
    return this.currentApplication;
  };

  public fetchEndedPaymentCycles = async (): Promise<void> => {
    const cycles = await apiMissions.adminQueryPassedMissionPaymentCycles(
      this.rootStore.auth,
      this.adminNextTokens.endedCycles || undefined,
    );
    this.setEndedPaymentCycles(cycles.items);
    this.setAdminTokens('endedCycles', cycles.next);
  };

  @computed get availableMissionStatusOptions(): string[] {
    const { Running, ScheduledToEnd } = MissionStatus;
    const newStatus = this.current?.status;
    if (newStatus && newStatus === MissionStatus.Running) {
      return Object.values({
        Running,
        ...{ ScheduledToEnd },
      });
    }

    if (newStatus && newStatus === MissionStatus.ScheduledToEnd) {
      return Object.values(MissionStatus).filter(
        (status) => status !== MissionStatus.Ended,
      );
    }
    if (newStatus && newStatus === MissionStatus.Ended) {
      return Object.values(MissionStatus).filter(
        (status) => status !== MissionStatus.ScheduledToEnd,
      );
    }
    return Object.values(MissionStatus);
  }

  public getAvailableRoleStatusOptions = (
    rid: MissionRoleId | undefined,
  ): string[] => {
    const { Canceled, Open, Ended, ScheduledToEnd, Active } = MissionRoleStatus;
    let currentStatus: MissionRoleStatus | undefined;
    if (rid) currentStatus = this.currentMissionRoleById(rid)?.status;
    if (rid && currentStatus && currentStatus === MissionRoleStatus.Active) {
      return Object.values({
        Active,
        ScheduledToEnd,
        Ended,
      });
    }
    const bestMissionStatus = this.currentMissionStatusSelection;

    if (bestMissionStatus) {
      const options: string[] = [];
      if (currentStatus) {
        options.push(currentStatus);
      }
      switch (bestMissionStatus) {
        case MissionStatus.Created:
        case MissionStatus.Published:
        case MissionStatus.Pending:
          options.push(...Object.values({ Canceled, Open }));
          break;
        case MissionStatus.Running:
          options.push(
            ...Object.values({
              Canceled,
              Open,
              Active,
              ScheduledToEnd,
              Ended,
            }),
          );
          break;
        case MissionStatus.Ended:
          options.push(...Object.values({ Ended, Canceled }));
          break;
        case MissionStatus.Archived:
          options.push(...Object.values({ Canceled }));
          break;
        default:
          options.push(
            ...Object.values({
              Canceled,
              Open,
              Active,
              ScheduledToEnd,
              Ended,
            }),
          );
          break;
      }
      return uniq(options);
    }
    return Object.values(MissionRoleStatus);
  };

  public handleRemoveRoleVerification = async (
    role: MissionRole,
  ): Promise<boolean> => {
    await this.currentMission?.getMissionApplications();
    if (
      this.currentMission?.applications &&
      this.currentMission?.applications.length > 0
    ) {
      return false;
    }

    const { status } = role;
    if (status === 'Active' || status === 'Ended') {
      return false;
    }
    return true;
  };

  public currentMissionRoleById = (rid: MissionRoleId): MissionRole | null => {
    return (
      this.currentMission?.data.roles.find((role) => role.rid === rid) || null
    );
  };

  @computed get isEmptyTimesheet(): boolean {
    return !this.currentMission?.selectedPaymentCycle?.data.summary
      ?.totalMinutes;
  }

  @computed get current(): MissionAdminObject | MissionObject | null {
    return this.currentMission?.data || null;
  }

  @computed get currentMissionStatusSelection(): MissionStatus | null {
    // See if there is a current selection, if not, send the current mission status
    return this.currentMission?.data.selectedStatus
      ? this.currentMission?.data.selectedStatus
      : this.currentMission?.data.status || null;
  }

  @action setSelectedStatus = (status: MissionStatus): void => {
    if (this.currentMission) {
      this.currentMission.data.selectedStatus = status;
    }
  };
  @computed get currentTalentIndustryIds(): TalentIndustrySummary[] {
    return this.currentMission?.data.industries ?? [];
  }

  @computed get isViewModeOnly(): boolean {
    if (
      this.current?.status === MissionStatus.Ended ||
      this.current?.status === MissionStatus.Archived
    ) {
      return true;
    }

    if (
      this.rootStore.profile?.application?.currentRole?.status ===
        MissionRoleStatus.Ended ||
      this.rootStore.profile?.application?.currentRole?.status ===
        MissionRoleStatus.Canceled
    ) {
      return true;
    }

    return false;
  }

  @action updateNotInterested = (isNotInterested: boolean): void => {
    if (this.currentMission !== undefined) {
      this.currentMission.isNotInterested = isNotInterested;
    }
  };

  @action setCurrent(
    currentMission: MissionAdminObject | MissionObject | null,
    initialAdminView?: boolean,
    isRecommended?: boolean,
    isNotInterested?: boolean,
  ): void {
    this.currentMission = currentMission
      ? new Mission(
          currentMission,
          this.rootStore.auth,
          this.rootStore.profile,
          this,
          this.rootStore.payments,
          initialAdminView,
          undefined,
          isRecommended,
          isNotInterested,
        )
      : undefined;
  }

  @action setCurrentApplication(
    rid: MissionRoleId,
    currentApplication: MissionApplicationObject,
    user: UserObject,
  ): void {
    if (!this.currentMission) return;
    this.currentApplication = new Profile(
      user,
      this.rootStore.auth,
      this.analytics,
      this.rootStore.missions,
    );
    this.currentApplication.setApplication(
      {
        rid,
        mid: this.currentMission?.mid,
      },
      currentApplication,
    );
  }

  @action resetCurrentApplication = (): void => {
    this.currentApplication = undefined;
  };

  @action add(currentMission: MissionAdminObject | MissionObject): void {
    this.setCurrent(currentMission);
  }

  @action updateRole(
    roleId: MissionRoleId,
    application: MissionApplicationBasicObject,
    mid: MissionId,
  ): void {
    if (!this.currentMission || this.currentMission.data.mid !== mid) {
      return;
    }

    this.currentMission.updateRole(roleId, application);
  }

  @action setClientMissions = (
    active: QueryResult<MissionCardObject>,
    pending: QueryResult<MissionCardObject>,
    archived: QueryResult<MissionCardObject>,
  ): void => {
    this.clientMissions = {
      active,
      pending,
      archived,
    };
  };

  @action setEndedPaymentCycles = (
    cycles: MissionPaymentCycleAdminReportObject[],
  ): void => {
    this.endedPaymentCycles = [
      ...this.endedPaymentCycles,
      ...cycles.map((cycle) => new MissionPaymentCycle(cycle)),
    ];
  };

  updateEndedPaymentCycle = (cycle: MissionPaymentCycleAdminObject): void => {
    this.endedPaymentCycles
      .find((c) => c.data.yid === cycle.yid)
      ?.syncData(cycle);
  };

  @action setAdminTokens = (
    key: keyof MissionsStoreData['adminNextTokens'],
    token: QueryNextToken | null,
  ): void => {
    this.adminNextTokens[key] = token;
  };

  @action updateAdminCreatedMissions = (
    missions: MissionCardObject[],
    isLoadMore?: boolean,
  ): void => {
    this.adminViewMissions.createdMissions = [
      ...(isLoadMore ? this.adminViewMissions.createdMissions || [] : []),
      ...missions,
    ];
  };

  @action updateAdminPublishedMissions = (
    missions: MissionCardObject[],
    isLoadMore?: boolean,
  ): void => {
    this.adminViewMissions.publishedMissions = [
      ...(isLoadMore ? this.adminViewMissions.publishedMissions || [] : []),
      ...missions,
    ];
  };

  @action updateAdminPendingMissions = (
    missions: MissionCardObject[],
    isLoadMore?: boolean,
  ): void => {
    this.adminViewMissions.pendingMissions = [
      ...(isLoadMore ? this.adminViewMissions.pendingMissions || [] : []),
      ...missions,
    ];
  };

  @action updateAdminRunningMissions = (
    missions: MissionCardObject[],
    isLoadMore?: boolean,
  ): void => {
    this.adminViewMissions.runningMissions = [
      ...(isLoadMore ? this.adminViewMissions.runningMissions || [] : []),
      ...missions,
    ];
  };

  @action updateAdminArchivedMissions = (
    missions: MissionCardObject[],
    isLoadMore?: boolean,
  ): void => {
    this.adminViewMissions.archivedMissions = [
      ...(isLoadMore ? this.adminViewMissions.archivedMissions || [] : []),
      ...missions,
    ];
  };

  @action updateAdminEndedMissions = (
    missions: MissionCardObject[],
    isLoadMore?: boolean,
  ): void => {
    this.adminViewMissions.endedMissions = [
      ...(isLoadMore ? this.adminViewMissions.endedMissions || [] : []),
      ...missions,
    ];
  };

  @action updateAdminScheduledToEndMissions = (
    missions: MissionCardObject[],
    isLoadMore?: boolean,
  ): void => {
    this.adminViewMissions.scheduledToEndMissions = [
      ...(isLoadMore
        ? this.adminViewMissions.scheduledToEndMissions || []
        : []),
      ...missions,
    ];
  };

  public async updateApplicationRole(
    aid: string,
    rid: MissionRoleId,
  ): Promise<AdminMissionApplicationObject> {
    const application = await apiMissions.updateApplicationRole(
      this.rootStore.auth,
      aid,
      rid,
    );

    await this.currentMission?.getMissionApplications(true);
    return application;
  }
}
