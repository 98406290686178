import { useState } from 'react';
import { VettingFormVariant } from '@a_team/models/dist/vetting-processes/vetting-processes';
import {
  CompanySize,
  DidBuildProductsFromScratch,
  ManagementExperience,
  PrimaryExpertise,
  ProductType,
  TypicalTeamSize,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { VettingProcessFeedbackRoles } from '@a_team/models/dist/vetting-processes/feedback';
import { CodeSample, CodeSampleType } from './inputs/code-sample-input';
import { CaseStudy, CaseStudyType } from './inputs/case-study';

const ERRORS = {
  REQUIRED: 'Required field',
  TIME_SLOTS: 'Select start and end time slots for at least 3 days',
};

export interface PreVettingFormErrors {
  primaryExpertise?: string;
  primaryRoles?: string;
  companySize?: string;
  productType?: string;
  didBuildProductsFromScratch?: string;
  managementExperience?: string;
  interviewAvailability: {
    calendarUrl?: string;
    weeklyHoursTimezone?: string;
    weeklyHoursDays?: string;
  };
  codeSampleProgrammingLanguages?: string;
  codeSample: {
    codeSampleRemoteUrl?: string;
    codeSampleFile?: string;
  };
  caseStudy: {
    caseStudyRemoteUrl?: string;
    caseStudyFile?: string;
  };
  hadDesignedCaseStudyAsTeamOrIndividual?: string;
  hasExperienceWorkingOnAProduct?: string;
  typicalTeamSize?: string;
  hadProductDesignerOnTeam?: string;
  hasProfitAndLossResponsibility?: string;
  hasTechnicalBackground?: string;
}

export type InterviewAvailabilityErrors =
  PreVettingFormErrors['interviewAvailability'];

export const usePreVettingFormErrors = (
  variant: VettingFormVariant,
  primaryExpertise: PrimaryExpertise[],
  primaryRoles: VettingProcessFeedbackRoles[],
  companySize: CompanySize[],
  productType: ProductType[],
  didBuildProductsFromScratch: DidBuildProductsFromScratch | undefined,
  managementExperience: ManagementExperience | undefined,
  codeSample: CodeSample,
  caseStudy: CaseStudy,
  hasExperienceWorkingOnAProduct?: boolean,
  typicalTeamSize?: TypicalTeamSize,
  hadProductDesignerOnTeam?: boolean,
  hasTechnicalBackground?: boolean,
) => {
  const {
    codeSampleProgrammingLanguages,
    codeSampleType,
    codeSampleRemoteUrl,
    codeSampleFile,
  } = codeSample;
  const {
    caseStudyType,
    caseStudyRemoteUrl,
    caseStudyFile,
    hadDesignedCaseStudyAsTeamOrIndividual,
  } = caseStudy;
  const [formErrors, setFormErrors] = useState<PreVettingFormErrors>(() => ({
    interviewAvailability: {},
    codeSample: {},
    caseStudy: {},
  }));

  function validatePrimaryExpertise(newPrimaryExpertise = primaryExpertise) {
    if (newPrimaryExpertise.length) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        primaryExpertise: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        primaryExpertise: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validatePrimaryRoles(newPrimaryRoles = primaryRoles) {
    if (newPrimaryRoles.length) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        primaryRoles: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        primaryRoles: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateCompanySize(newCompanySize = companySize): boolean {
    if (newCompanySize.length) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        companySize: undefined,
      }));

      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        companySize: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateProductType(newProductType = productType): boolean {
    if (newProductType.length) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        productType: undefined,
      }));

      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        productType: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateDidBuildProductsFromScratch(
    newDidBuildProductsFromScratch = didBuildProductsFromScratch,
  ) {
    if (newDidBuildProductsFromScratch) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        didBuildProductsFromScratch: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        didBuildProductsFromScratch: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateManagementExperience(
    newManagementExperience = managementExperience,
  ) {
    if (newManagementExperience) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        managementExperience: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        managementExperience: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateCodeSampleProgrammingLanguages(
    newCodeSampleProgrammingLanguages = codeSampleProgrammingLanguages,
  ) {
    if (newCodeSampleProgrammingLanguages?.length) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        codeSampleProgrammingLanguages: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        codeSampleProgrammingLanguages: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateCodeSample() {
    let hasErrors = false;
    if (codeSampleType === CodeSampleType.URL) {
      if (!codeSampleRemoteUrl.trim()) {
        setFormErrors((formErrors) => ({
          ...formErrors,
          codeSample: {
            codeSampleRemoteUrl: ERRORS.REQUIRED,
          },
        }));
        hasErrors = true;
      }
    } else {
      if (!codeSampleFile) {
        setFormErrors((formErrors) => ({
          ...formErrors,
          codeSample: {
            codeSampleFile: ERRORS.REQUIRED,
          },
        }));
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      clearCodeSampleErrors();
    }

    return hasErrors;
  }

  function validateCaseStudy() {
    let hasErrors = false;
    if (caseStudyType === CaseStudyType.URL) {
      if (!caseStudyRemoteUrl.trim()) {
        setFormErrors((formErrors) => ({
          ...formErrors,
          caseStudy: {
            caseStudyRemoteUrl: ERRORS.REQUIRED,
          },
        }));
        hasErrors = true;
      }
    } else {
      if (!caseStudyFile) {
        setFormErrors((formErrors) => ({
          ...formErrors,
          caseStudy: {
            caseStudyFile: ERRORS.REQUIRED,
          },
        }));
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      clearCaseStudyErrors();
    }

    return hasErrors;
  }

  function validateHadDesignedCaseStudyAsTeamOrIndividual(
    newHadDesignedCaseStudyAsTeamOrIndividual = hadDesignedCaseStudyAsTeamOrIndividual,
  ) {
    if (hadDesignedCaseStudyAsTeamOrIndividual.trim()) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        hadDesignedCaseStudyAsTeamOrIndividual: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        hadDesignedCaseStudyAsTeamOrIndividual: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateHasExperienceWorkingOnAProduct(
    newHasExperienceWorkingOnAProduct = hasExperienceWorkingOnAProduct,
  ) {
    if (typeof newHasExperienceWorkingOnAProduct === 'boolean') {
      setFormErrors((formErrors) => ({
        ...formErrors,
        hasExperienceWorkingOnAProduct: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        hasExperienceWorkingOnAProduct: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateTypicalTeamSize(newTypicalTeamSize = typicalTeamSize) {
    if (newTypicalTeamSize) {
      setFormErrors((formErrors) => ({
        ...formErrors,
        typicalTeamSize: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        typicalTeamSize: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateHadProductDesignerOnTeam(
    newHadProductDesignerOnTeam = hadProductDesignerOnTeam,
  ) {
    if (typeof newHadProductDesignerOnTeam === 'boolean') {
      setFormErrors((formErrors) => ({
        ...formErrors,
        hadProductDesignerOnTeam: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        hadProductDesignerOnTeam: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function validateHasTechnicalBackground(
    newHasTechnicalBackground = hasTechnicalBackground,
  ) {
    if (typeof newHasTechnicalBackground === 'boolean') {
      setFormErrors((formErrors) => ({
        ...formErrors,
        hasTechnicalBackground: undefined,
      }));
      return false;
    } else {
      setFormErrors((formErrors) => ({
        ...formErrors,
        hasTechnicalBackground: ERRORS.REQUIRED,
      }));
      return true;
    }
  }

  function clearInterviewAvailabilityTimezoneError() {
    setFormErrors((formErrors) => ({
      ...formErrors,
      interviewAvailability: {
        ...formErrors.interviewAvailability,
        weeklyHoursTimezone: undefined,
      },
    }));
  }

  function clearInterviewAvailabilityErrors() {
    setFormErrors((formErrors) => ({
      ...formErrors,
      interviewAvailability: {},
    }));
  }

  function clearCodeSampleErrors() {
    setFormErrors((formErrors) => ({
      ...formErrors,
      codeSample: {},
    }));
  }

  function clearCaseStudyErrors() {
    setFormErrors((formErrors) => ({
      ...formErrors,
      caseStudy: {},
    }));
  }

  const validators = {
    validatePrimaryExpertise,
    validatePrimaryRoles,
    validateCompanySize,
    validateProductType,
    validateDidBuildProductsFromScratch,
    validateManagementExperience,
    validateCodeSampleProgrammingLanguages,
    validateCodeSample,
    validateCaseStudy,
    validateHadDesignedCaseStudyAsTeamOrIndividual,
    validateHasExperienceWorkingOnAProduct,
    validateTypicalTeamSize,
    validateHadProductDesignerOnTeam,
    validateHasTechnicalBackground,
  };

  const clearErrors = {
    clearInterviewAvailabilityTimezoneError,
    clearInterviewAvailabilityErrors,
    clearCodeSampleErrors,
    clearCaseStudyErrors,
  };

  function checkHasErrors(): boolean {
    if (
      [
        VettingFormVariant.ProjectManagement,
        VettingFormVariant.ProductManagement,
      ].includes(variant)
    ) {
      return ![
        validatePrimaryExpertise,
        validateCompanySize,
        validateProductType,
        validateHasExperienceWorkingOnAProduct,
        validateTypicalTeamSize,
        validateHadProductDesignerOnTeam,
        validateHasTechnicalBackground,
      ]
        .map((validator) => validator())
        .every((hasErrors) => hasErrors === false);
    }

    if (variant === VettingFormVariant.Design) {
      return ![
        validatePrimaryRoles,
        validateCompanySize,
        validateCaseStudy,
        validateHadDesignedCaseStudyAsTeamOrIndividual,
      ]
        .map((validator) => validator())
        .every((hasErrors) => hasErrors === false);
    }

    return ![
      validateCompanySize,
      validateDidBuildProductsFromScratch,
      validateManagementExperience,
      validateCodeSampleProgrammingLanguages,
      validateCodeSample,
    ]
      .map((validator) => validator())
      .every((hasErrors) => hasErrors === false);
  }

  return {
    formErrors,
    validators,
    clearErrors,
    checkHasErrors,
  };
};

export type PreVettingFormValidators = ReturnType<
  typeof usePreVettingFormErrors
>['validators'];

export type PreVettingFormClearErrors = ReturnType<
  typeof usePreVettingFormErrors
>['clearErrors'];
