import React, {
  ChangeEvent,
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { add } from 'date-fns';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import TextButton from '@src/components/TextButton';
import RoleCategorySelect from '@src/views/Mission/EditMission/RoleCategorySelect';
import BasicUserSelector from '@src/components/BasicUserSelector';
import MissionRole, {
  BasicMissionRole,
  ClientRoleQuestion,
  MissionAdminRole,
  MissionRoleStatus,
  MissionRoleVisibilityStatus,
} from '@a_team/models/dist/MissionRole';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import { SkillSelector } from '@src/components/SkillSelector';
import { DateInput } from '@src/components/DateInput';
import { useStores } from '@src/stores';
import CountryList from '@src/components/TeamGraphBuilderQuery/CountryList';
import { BasicUserObject, UserId } from '@a_team/models/dist/UserObject';
import BuilderAssignmentWarningModal from '../BuilderAssignmentWarningModal';
import { TeamWorkWorkingHours } from './TeamWorkWorkingHours';
import { isEqual, round } from 'lodash';
import {
  BorderColors,
  BorderRadius,
  Checkbox,
  Colors,
  FontSizes,
  FontWeights,
  HelpTooltip,
  Spacing,
  ToggleSwitch,
} from '@ateams/components';
import cx from 'classnames';
import { CustomQuestionEdit } from '@src/views/Mission/EditMission/TeamWorkRolesInput/CustomQuestionEdit';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import { TextColors } from '@ateams/components';
import { Paddings } from '@ateams/components';

interface Props {
  role?: MissionRole;
  roleApplicants?: UserId[];
  onChange(role: MissionRole): void;
  onRemove?(): void;
  disabled?: boolean;
  visible?: boolean;
}

const useStyles = createUseStyles({
  root: {},
  row: {
    display: 'flex',

    '& > *': {
      width: '50%',
      margin: 0,
    },
    '& > * + *': {
      marginLeft: '16px',
    },
  },
  remove: {
    textAlign: 'right',
    marginTop: Spacing.medium,
  },
  deleteError: {
    color: 'red',
    maxWidth: 442,
    marginLeft: 'auto',
    marginTop: 0,
  },
  availabilityContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  blockDatePickerWrapper: {
    width: '100%',
    borderRadius: 8,
    height: 'fit-content',
  },
  blockDatePicker: {
    padding: '1em',
    minHeight: 56,
    width: '100%',
    lineHeight: 'initial',
    borderRadius: 8,
    border: '1px solid #c0c0c0',
    '&:focus': {
      border: '1px solid #000',
    },
  },
  hourInput: {
    marginTop: 6,
    marginLeft: 16,
    marginBottom: 20,
    maxWidth: '50%',
  },
  scheduledEndDate: {
    width: '100%',
    borderRadius: 8,
    height: 'fit-content',
    marginLeft: 16,
  },
  timezoneSelectInput: {
    '& > div > div': {
      marginBottom: 16,
    },
  },
  skillSelectorInput: {
    marginTop: Spacing.small,
    '& > div:first-child > div': {
      minHeight: 56,
      marginBottom: Spacing.small,
    },
  },
  disableAutomatedStatusesCheckbox: {
    marginTop: Spacing.medium,
    marginBottom: Spacing.medium,
  },
  fullWidth: {
    width: '100%',
  },
  toggleWrapper: {
    backgroundColor: Colors.backgroundLight,
    padding: Spacing.medium,
    borderRadius: BorderRadius.medium,
    width: '100%',
  },
  rateWrap: {
    display: 'inline',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: Spacing.small,

    '&.retainer': {
      display: 'flex',
    },
  },
  inputLabel: {
    color: TextColors.regularLight,
    display: 'block',
    fontSize: FontSizes.regular,
    marginBottom: Spacing.small,
  },
  rateInput: {
    position: 'relative',
    margin: 0,
  },
  input: {
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: BorderRadius.default,
    padding: `${Paddings.medium}px ${Paddings.medium}px ${Paddings.medium}px ${Paddings.large}px`,
    minWidth: 140,
    fontWeight: FontWeights.regular,
  },
  prefix: {
    position: 'absolute',
    left: 10,
    top: 45,
  },
  suffix: {
    position: 'absolute',
    right: 10,
    top: 45,
    background: Colors.backgroundWhite,
  },
  rateHint: {
    display: 'flex',
    background: Colors.backgroundLight,
    padding: `${Paddings.small}px ${Paddings.medium}px`,
    borderRadius: BorderRadius.default,
    gap: Spacing.small,
    marginTop: Spacing.xLarge,
  },
  helpTooltip: {
    width: '300px !important',
    left: 'calc(50% - 50px)', // to offset the width
  },
  rateTooltip: {
    padding: 0,
  },
  tooltipTitle: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.small,
    marginBottom: Spacing.medium,
    textAlign: 'left',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  break: {
    margin: 0,
    background: Colors.regularLight,
  },
  bold: {
    fontWeight: FontWeights.bold,
  },
});

const HEADLINE_MIN_LEN = 20;
const HEADLINE_MAX_LEN = 500;
const AVG_WEEKS_PER_MONTH = 4.33;
const HOURS_PER_WEEK = 40;

function TeamWorkRoleInput(props: Props): ReactElement {
  const { role, disabled = false, onChange, onRemove, visible } = props;
  const { missions, auth } = useStores();
  const { withWorkingHours } = auth;
  const styles = useStyles();

  const isHeadlineValid = (headline: string | undefined) => {
    const headlineLen = headline?.length || 0;
    return headlineLen < HEADLINE_MIN_LEN || headlineLen > HEADLINE_MAX_LEN;
  };

  const [data, setData] = useState<Partial<MissionAdminRole>>(role || {});
  const [deleteText, setDeleteText] = useState<string>('Remove');
  const [showBuilderWarning, setShowBuilderWarning] = useState(false);
  const [headlineError, setHeadlineError] = useState(
    isHeadlineValid(role?.headline),
  );

  const validRateRange = useMemo(() => {
    let { builderRateMin, builderRateMax } = data;
    builderRateMin = Number(builderRateMin);
    builderRateMax = Number(builderRateMax);

    return (
      !isNaN(builderRateMin) &&
      builderRateMin >= 1 &&
      !isNaN(builderRateMax) &&
      builderRateMax >= builderRateMin
    );
  }, [data.builderRateMin, data.builderRateMax]);

  useEffect((): void => {
    setData(role || {});
  }, [role]);

  const roleOptions = useMemo(
    () => missions.getAvailableRoleStatusOptions(data.rid),
    [data.rid],
  );

  const isMonthlyRetainerGoogleDisabled = useMemo(() => {
    return (
      data.status === MissionRoleStatus.Active ||
      data.status === MissionRoleStatus.ScheduledToEnd ||
      (data.isFullTimeRetainer ? false : !validRateRange)
    );
  }, [data.status, data.isFullTimeRetainer, validRateRange]);

  const builderRate = useMemo(() => {
    let rate = data.hourlyRate;
    if (data.isFullTimeRetainer) {
      rate = data.monthlyRate;
    }

    return rate || '';
  }, [data.isFullTimeRetainer, data.hourlyRate, data.monthlyRate]);

  const hourlyRateEquivalent = useMemo(() => {
    if (!data.monthlyRate || data.monthlyRate === 0) {
      return 0;
    }

    return round(data.monthlyRate / AVG_WEEKS_PER_MONTH / HOURS_PER_WEEK, 2);
  }, [data?.monthlyRate]);

  const handleChange = (update: Partial<MissionAdminRole>): void => {
    const newRole = { ...data, ...update };
    setData(newRole);

    const headlineValid = isHeadlineValid(newRole.headline);

    setHeadlineError(headlineValid);

    if (newRole.category) {
      onChange(newRole as MissionAdminRole);
    }
  };

  const handleCustomQuestionsChange = (question: ClientRoleQuestion) => {
    const questionIndex = data.customQuestions?.findIndex(
      (q) => q.qid === question.qid,
    );
    if (questionIndex !== -1 && questionIndex !== undefined) {
      const update = [...(data.customQuestions || [])];
      update[questionIndex] = question;
      handleChange({
        customQuestions: update,
      });
    }
  };

  const handleBuilderChange = (user?: BasicMissionRole['user']): void => {
    const uid = user && (user as BasicUserObject).uid;
    if (uid && !props.roleApplicants?.includes(uid)) {
      setShowBuilderWarning(true);
    }
    handleChange({ user });
  };

  const handleAvailabilityChange = (availability: {
    weeklyHoursAvailable?: number;
    date?: Date;
    scheduledEndDate?: Date;
  }): void => {
    const { date, weeklyHoursAvailable, scheduledEndDate } = availability;

    return handleChange({
      availability: {
        date: date
          ? date.toISOString()
          : date === undefined
          ? data.availability?.date
          : undefined,
        weeklyHoursAvailable:
          weeklyHoursAvailable !== undefined
            ? weeklyHoursAvailable
            : data.availability?.weeklyHoursAvailable ?? 0,
        scheduledEndDate: scheduledEndDate
          ? scheduledEndDate.toISOString()
          : scheduledEndDate === undefined
          ? data.availability?.scheduledEndDate
          : undefined,
      },
    });
  };

  const handleSkills = (
    skillIds: TalentSkillId[],
    type: 'required' | 'preferred',
  ): void => {
    const skills = skillIds.map((id) => ({ talentSkillId: id }));

    switch (type) {
      // TODO: Defaulting rating to 1 until proper input is available
      case 'required':
        return handleChange({
          requiredSkills: skills.map((skill) => ({ ...skill, rating: 1 })),
        });

      case 'preferred':
        return handleChange({ preferredSkills: skills });
    }
  };

  const handleRoleRemove = async (): Promise<void> => {
    if (role && onRemove) {
      setDeleteText('Loading...');
      const canDelete = await missions.handleRemoveRoleVerification(role);

      if (canDelete) {
        onRemove();
      } else {
        setDeleteText('Not Removable');
      }
    }
  };

  const handleBuilderRateChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (data.isFullTimeRetainer) {
      handleChange({
        monthlyRate: Number(e.target.value),
        hourlyRate: hourlyRateEquivalent, // for now we still charge by hourly rate, so we set this as a calculated hourly rate
      });
    } else {
      handleChange({
        hourlyRate: Number(e.target.value),
        monthlyRate: undefined, // incase the role doesn't have monthly retainer, remove the monthly rate
      });
    }
  };

  const clearSelectedUser = (): void => {
    setShowBuilderWarning(false);
    handleChange({ user: null });
  };

  // When it changes, BasicUserSelector will be re-created and its state cleared,
  // otherwise setting data.user to null is not reflected in BasicUserSelector
  const selectedUserKey = data.user && (data.user as BasicUserObject).uid;

  return (
    <div className={styles.root} style={{ display: visible ? '' : 'none' }}>
      <DropdownInput
        style={{ marginTop: 0 }}
        placeholder="Select visibility status"
        value={data.visibility?.visibilityStatus}
        onChange={(e): void =>
          handleChange({
            ...data,
            visibility: {
              ...data.visibility,
              visibilityStatus: e.target.value as MissionRoleVisibilityStatus,
            },
          })
        }
      >
        <option
          key={`visibility-status-onlyadmin`}
          value={MissionRoleVisibilityStatus.OnlyAdmin}
        >
          Only Admin
        </option>
        <option
          key={`visibility-status-all`}
          value={MissionRoleVisibilityStatus.All}
        >
          All
        </option>
      </DropdownInput>
      <div className={styles.row}>
        <RoleCategorySelect
          disabled={disabled}
          cid={data.category?.cid}
          onChange={
            disabled
              ? undefined
              : (category): void => handleChange({ category })
          }
          margin="none"
        />
        <BasicUserSelector
          key={selectedUserKey}
          disabled={disabled}
          user={data.user || undefined}
          allowNonUser
          onSelect={(user): void => handleBuilderChange(user)}
          margin="none"
        />
      </div>
      <OutlinedInput
        disabled={disabled}
        value={data.headline || ''}
        multiline
        minRows={3}
        onChange={(e): void => handleChange({ headline: e.target.value })}
        placeholder="Describe this role..."
        error={headlineError}
        valid={!headlineError}
        errorTooltip={`Expect length to be between ${HEADLINE_MIN_LEN} and ${HEADLINE_MAX_LEN} characters (actual: ${
          (data.headline || '').length
        })`}
      />

      <h3>Add a question for builders</h3>
      {data.customQuestions?.map((question) => {
        return (
          <CustomQuestionEdit
            question={question}
            disabled={disabled}
            onChange={handleCustomQuestionsChange}
          />
        );
      })}
      <div className={styles.row} style={{ marginBottom: '16px' }}>
        <div className={styles.fullWidth}>
          <div className={styles.toggleWrapper}>
            <ToggleSwitch
              label={'This role is being billed as a full time retainer'}
              size={'small'}
              checkedBackgroundColor="secondary"
              onChange={(checked): void => {
                handleChange({
                  isFullTimeRetainer: checked,
                  collectMonthlyRate: checked,
                });
              }}
              checked={data.isFullTimeRetainer ?? false}
              disabled={isMonthlyRetainerGoogleDisabled}
            />
          </div>
        </div>
      </div>
      <div className={styles.row} style={{ marginBottom: '20px' }}>
        <div className={styles.fullWidth}>
          <DropdownInput
            style={{ margin: 0 }}
            label="Role Status"
            placeholder="Select Role Status"
            value={data.status}
            required
            onChange={(e): void =>
              handleChange({
                ...data,
                status: e.target.value as MissionRoleStatus,
              })
            }
            width="default"
          >
            {roleOptions.map((status, i) => (
              <option key={`status-${i}`} value={status}>
                {status}
              </option>
            ))}
          </DropdownInput>
        </div>
      </div>

      <div className={styles.row} style={{ marginBottom: '20px' }}>
        <div
          className={cx(styles.rateWrap, {
            retainer: !!data.isFullTimeRetainer,
          })}
        >
          <div className={styles.rateInput}>
            <span className={styles.prefix}>$</span>
            <label className={styles.inputLabel}>Builder rate</label>
            <TextInput
              variant="dashed"
              type="number"
              placeholder={'Builder rate...'}
              className={styles.input}
              value={builderRate}
              onChange={handleBuilderRateChange}
              onBlur={handleBuilderRateChange}
            />
            <span className={styles.suffix}>
              {data.isFullTimeRetainer ? '/month' : '/hour'}
            </span>
          </div>
          {data.isFullTimeRetainer && (
            <div className={styles.rateHint}>
              <span>{`${hourlyRateEquivalent.toLocaleString()}/h`}</span>
              <HelpTooltip className={styles.helpTooltip}>
                <div className={styles.rateTooltip}>
                  <h4 className={styles.tooltipTitle}>
                    Hourly rate equivalent
                  </h4>
                  <div className={styles.table}>
                    <div className={styles.item}>
                      <span>Monthly rate</span>
                      <span>{`$${round(
                        data.monthlyRate ?? 0,
                        2,
                      ).toLocaleString()} per month`}</span>
                    </div>
                    <div className={styles.item}>
                      <span>Hours per week</span>
                      <span>x 40</span>
                    </div>
                    <div className={styles.item}>
                      <span>Average weeks in a month</span>
                      <span>x 4.33</span>
                    </div>
                    <hr className={styles.break} />
                    <div className={styles.item}>
                      <span className={styles.bold}>Hourly rate</span>
                      <span className={styles.bold}>
                        ${hourlyRateEquivalent.toLocaleString()} per hour
                      </span>
                    </div>
                  </div>
                </div>
              </HelpTooltip>
            </div>
          )}
        </div>
        <OutlinedInput
          label={'Platform fee'}
          disabled={disabled}
          value={data.marginVAT ? data.marginVAT * 100 : ''}
          type="number"
          onChange={(e): void =>
            handleChange({
              marginVAT: Number(e.target.value) / 100 || undefined,
            })
          }
          precursor="%"
          placeholder="VAT"
        />
      </div>

      <div className={styles.row} style={{ marginBottom: '16px' }}>
        <OutlinedInput
          label="Hourly rate - Minimum"
          disabled={disabled}
          value={data.builderRateMin ? data.builderRateMin : ''}
          type="number"
          onChange={(e): void =>
            handleChange({ builderRateMin: Number(e.target.value) })
          }
          precursor="$"
          placeholder="Min builder rate"
        />
        <OutlinedInput
          label="Hourly rate - Maximum"
          disabled={disabled}
          value={data.builderRateMax ? data.builderRateMax : ''}
          type="number"
          onChange={(e): void =>
            handleChange({ builderRateMax: Number(e.target.value) })
          }
          precursor="$"
          placeholder="Max builder rate"
        />
      </div>
      <div className={styles.row} style={{ marginBottom: '20px' }}>
        <div className={styles.fullWidth}>
          <ToggleSwitch
            label={'Show rate range to builders'}
            size={'small'}
            checkedBackgroundColor="secondary"
            onChange={(checked): void => {
              handleChange({ showRateRangeToBuilders: checked });
            }}
            checked={data.showRateRangeToBuilders ?? false}
            disabled={data.showRateRangeToBuilders ? false : !validRateRange}
          />
        </div>
      </div>

      <div className={styles.row} style={{ marginBottom: '16px' }}>
        <OutlinedInput
          label="Monthly rate - Minimum"
          disabled={disabled}
          value={data.builderMonthlyRateMin ? data.builderMonthlyRateMin : ''}
          type="number"
          onChange={(e): void =>
            handleChange({ builderMonthlyRateMin: Number(e.target.value) })
          }
          precursor="$"
          placeholder="Min builder rate"
        />
        <OutlinedInput
          label="Monthly rate - Maximum"
          disabled={disabled}
          value={data.builderMonthlyRateMax ? data.builderMonthlyRateMax : ''}
          type="number"
          onChange={(e): void =>
            handleChange({ builderMonthlyRateMax: Number(e.target.value) })
          }
          precursor="$"
          placeholder="Max builder rate"
        />
      </div>
      <div className={styles.row} style={{ marginBottom: '20px' }}>
        <div className={styles.fullWidth}>
          <ToggleSwitch
            label={'Show and require monthly rate on application'}
            size={'small'}
            checkedBackgroundColor="secondary"
            onChange={(checked): void => {
              handleChange({ collectMonthlyRate: checked });
            }}
            checked={data.collectMonthlyRate ?? false}
            disabled={data.collectMonthlyRate ? false : !validRateRange}
          />
        </div>
      </div>

      <div className={styles.availabilityContainer}>
        <DateInput
          disabled={
            disabled ||
            role?.status === MissionRoleStatus.Canceled ||
            role?.status === MissionRoleStatus.Ended
          }
          popperPlacement={'right'}
          className={styles.blockDatePicker}
          wrapperClassName={styles.blockDatePickerWrapper}
          selected={
            data.availability?.date
              ? new Date(data.availability?.date)
              : undefined
          }
          placeholderText="Latest start date..."
          onChange={(date) => handleAvailabilityChange({ date: date as Date })}
          isClearable={true}
        />
        <DateInput
          disabled={disabled}
          minDate={
            data.availability?.date
              ? add(
                  new Date(data.availability?.date),

                  { days: 1 },
                )
              : undefined
          }
          popperPlacement={'right'}
          className={styles.blockDatePicker}
          wrapperClassName={styles.scheduledEndDate}
          selected={
            data.availability?.scheduledEndDate
              ? new Date(data.availability?.scheduledEndDate)
              : undefined
          }
          placeholderText="Scheduled end date..."
          onChange={(date) =>
            handleAvailabilityChange({ scheduledEndDate: date as Date })
          }
          isClearable={true}
        />
        <OutlinedInput
          disabled={disabled}
          value={data.availability?.weeklyHoursAvailable}
          onChange={(e) =>
            handleAvailabilityChange({
              weeklyHoursAvailable: Number(e.target.value),
            })
          }
          endAdornment={<span>hrs/wk</span>}
          className={styles.hourInput}
          fullWidth={false}
          placeholder={'Min. commitment'}
        />
      </div>

      {withWorkingHours && (
        <>
          <TeamWorkWorkingHours
            disabled={disabled}
            className={styles.timezoneSelectInput}
            defaultTimezone={data.utcOffsetRange?.from}
            workingHours={data.workingHours}
            onChange={(workingHours) => handleChange({ workingHours })}
          />
        </>
      )}
      <SkillSelector
        disabled={disabled}
        role={data}
        type="required"
        className={styles.skillSelectorInput}
        onChange={(skills) => handleSkills(skills, 'required')}
        placeholder="Add required skills..."
      />

      <SkillSelector
        disabled={disabled}
        role={data}
        type="preferred"
        className={styles.skillSelectorInput}
        onChange={(skills) => handleSkills(skills, 'preferred')}
        placeholder="Add preferred skills..."
      />

      <CountryList
        className={styles.skillSelectorInput}
        countries={data.locations ?? []}
        onChange={(locations) => handleChange({ locations })}
        hideTags={true}
      />

      <div className={styles.disableAutomatedStatusesCheckbox}>
        <ToggleSwitch
          label={'Disable automated statuses assignment'}
          size={'small'}
          checkedBackgroundColor="secondary"
          onChange={(checked): void => {
            handleChange({ automatedStatusesDisabled: checked });
          }}
          checked={data.automatedStatusesDisabled ?? false}
        />
      </div>

      <div className={styles.toggleWrapper}>
        <Checkbox
          margin="none"
          label={'Role is updated and ready to review'}
          onChange={(e): void => {
            handleChange({ readyForReview: e.target.checked });
          }}
          checked={!!data.readyForReview}
        />
      </div>

      {onRemove ? (
        <div className={styles.remove}>
          <TextButton
            onClick={(): Promise<void> => handleRoleRemove()}
            highlight
          >
            {deleteText}
          </TextButton>{' '}
          {deleteText === 'Not Removable' && (
            <p className={styles.deleteError}>
              You can't delete this role <br /> It has applications, it has been
              'Active', or it is 'Ended' <br /> Change the role status to
              'Canceled'
            </p>
          )}
        </div>
      ) : (
        <div style={{ height: '1.5em' }} />
      )}
      <BuilderAssignmentWarningModal
        open={showBuilderWarning}
        onClose={() => setShowBuilderWarning(false)}
        onSelectAnother={clearSelectedUser}
        assignedBuilderName={data.user?.fullName}
        roleName={role?.category.title}
      />
    </div>
  );
}

function areEqual(prevProps: Props, nextProps: Props) {
  if (!isEqual(prevProps?.role, nextProps?.role)) {
    return false;
  }

  if (!isEqual(prevProps?.roleApplicants, nextProps?.roleApplicants)) {
    return false;
  }

  if (prevProps?.disabled !== nextProps?.disabled) {
    return false;
  }

  if (prevProps?.visible !== nextProps?.visible) {
    return false;
  }

  return true;
}

export default memo(TeamWorkRoleInput, areEqual);
