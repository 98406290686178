import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Modal, { ModalBasicProps } from '..';
import { createUseStyles } from 'react-jss';
import aTeamLogo from '@src/assets/svgs/ateam-logo.svg';
import cancelIcon from '@src/assets/svgs/cancel-icon.svg';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
  TextColors,
} from '@ateams/components';
import { DateTime, IANAZone } from 'luxon';
import cx from 'classnames';
import { apiVettingProcessPreVetting } from '@ateams/api';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';

const MIN_CHARACTERS = 20;
const MAX_CHARACTERS = 240;

const useStyles = createUseStyles({
  modal: {
    width: 'auto',
    minWidth: 680,
  },
  contentOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 24px 0px 24px',
    gap: Spacing.large,
  },
  title: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.medium,
    textAlign: 'center',
    color: TextColors.regular,
    lineHeight: '26px',
    whiteSpace: 'nowrap',
  },
  subTitle: {
    fontSize: FontSizes.medium,
    textAlign: 'center',
    color: TextColors.regular,
    lineHeight: '26px',
    marginTop: Spacing.small,
  },
  button: {
    color: TextColors.regular,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
    borderRadius: BorderRadius.medium,
    padding: '8px 12px',
    border: 0,
    cursor: 'pointer',
    minWidth: '120px',
  },
  buttonPurple: {
    color: TextColors.secondaryDark,
    backgroundColor: Colors.secondaryDark,
  },
  dateAndTime: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: Spacing.small,
  },
  dateAndTimeSeparator: {
    width: '20px',
    height: '1px',
    margin: '0',
  },
  separator: {
    marginTop: Spacing.xLarge,
    marginBottom: 0,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: Spacing.medium,
    padding: '24px',
  },
  cancellationReasonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
    padding: Spacing.medium,
    width: '100%',
  },
  cancellationReasonLabel: {
    width: '100%',
    textAlign: 'left',
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  cancellationReasonWrapper: {
    width: '100%',
    borderRadius: BorderRadius.medium,
    border: `1px solid ${BorderColors.lighter}`,
  },
  cancellationReasonTextArea: {
    width: '100%',
    padding: Spacing.medium,
    fontSize: FontSizes.medium,
    border: 0,
    borderRadius: BorderRadius.medium,
    resize: 'none',
  },
  charactersLabels: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: Spacing.medium,
  },
  minCharacters: {
    fontSize: FontSizes.small,
    color: TextColors.regular,
  },
  minCharactersError: {
    color: Colors.danger,
  },
  countCharacters: {
    fontSize: FontSizes.small,
    color: TextColors.regular,
  },
  logo: {
    width: '72px',
    height: '72px',
  },
  cancelIcon: {
    position: 'relative',
    top: '3px',
    left: '-11px',
  },
});

interface Props extends ModalBasicProps {
  preVettingFormNonce: string;
  interviewStartDate: string;
  builderTimezone: string;
  onMarkAsRead?(): void;
}

export default function EvaluationInterviewInviteModal(
  props: Props,
): ReactElement {
  const { auth } = useStores();
  const [cancelling, setCancelling] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const {
    preVettingFormNonce,
    interviewStartDate,
    builderTimezone,
    onClose,
    ...left
  } = props;
  const [minCharactersError, setMinCharactersError] = useState(false);
  const [loading, setLoading] = useLoadingState();

  const styles = useStyles();

  const handleOnClose = (): void => {
    onClose();
  };

  const { date, time } = useMemo(() => {
    if (!builderTimezone) {
      return { date: 'Invalid date', time: 'Invalid time' };
    }
    const isValidTimeZone = IANAZone.isValidZone(builderTimezone);

    if (!interviewStartDate || !builderTimezone) {
      console.error('Invalid input:', {
        startDate: interviewStartDate,
        isValidTimeZone,
      });
      return { date: 'Invalid date', time: 'Invalid time' };
    }

    const startDate = DateTime.fromISO(interviewStartDate, {
      zone: builderTimezone,
    });

    if (!startDate.isValid) {
      console.error('Conversion error:', {
        startDateError: startDate.invalidReason,
      });
      return { date: 'Invalid date', time: 'Invalid time' };
    }

    const formattedDate = startDate.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    const startTime = startDate
      .toLocaleString(DateTime.TIME_SIMPLE)
      .toLowerCase();
    const timeZone = startDate.toFormat('ZZZZ'); // Get the timezone abbreviation

    const formattedTime = `${startTime} ${timeZone}`;

    return { date: formattedDate, time: formattedTime };
  }, [interviewStartDate, builderTimezone]);

  useEffect(() => {
    if (cancellationReason.length >= MIN_CHARACTERS) {
      setMinCharactersError(false);
    }
  }, [cancellationReason]);

  const onCloseClick = (): void => {
    setCancelling(false);
    onClose();
  };

  const onCancelInterviewClick = async (): Promise<void> => {
    if (cancellationReason.length < MIN_CHARACTERS) {
      setMinCharactersError(true);
      return;
    }

    onClose();
    setCancelling(false);

    setLoading(
      apiVettingProcessPreVetting
        .cancelInterview(auth, preVettingFormNonce, cancellationReason)
        .then(() => {
          props.onMarkAsRead?.();
        }),
      'Interview cancelled',
    );
  };

  return (
    <>
      <Modal
        onClose={handleOnClose}
        style={{
          transition: 'all 0.5s',
          padding: 0,
          zIndex: 100000,
        }}
        className={styles.modal}
        {...left}
      >
        {cancelling ? (
          <div className={styles.contentOuterContainer}>
            <div className={styles.content}>
              <div>
                <img src={aTeamLogo} alt="aTeam logo" className={styles.logo} />
                <img
                  src={cancelIcon}
                  alt="cancel icon"
                  className={styles.cancelIcon}
                />
              </div>
              <div>
                <div className={styles.title}>
                  Are you sure you want to cancel the evaluation call?
                </div>
                <div className={styles.subTitle}>
                  If you wish to apply and be accepted on missions you have to
                  get evaluated. <br />
                  If you cancel today, you will need to set another time for the
                  evaluation.
                </div>
              </div>
              <div className={styles.cancellationReasonContainer}>
                <div className={styles.cancellationReasonLabel}>
                  Cancellation reason
                </div>
                <div className={styles.cancellationReasonWrapper}>
                  <textarea
                    placeholder="Enter description"
                    rows={5}
                    maxLength={MAX_CHARACTERS}
                    value={cancellationReason}
                    className={styles.cancellationReasonTextArea}
                    onChange={(e) => setCancellationReason(e.target.value)}
                  />
                  <div className={styles.charactersLabels}>
                    <div
                      className={cx(
                        styles.minCharacters,
                        minCharactersError && styles.minCharactersError,
                      )}
                    >
                      {`${MIN_CHARACTERS} characters minimum`}
                    </div>
                    <div className={styles.countCharacters}>
                      {`${cancellationReason.length}/${MAX_CHARACTERS} characters`}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button className={styles.button} onClick={onCloseClick}>
                  Close
                </button>
                <button
                  className={cx(styles.button, styles.buttonPurple)}
                  onClick={onCancelInterviewClick}
                >
                  Cancel Interview
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.contentOuterContainer}>
            <div className={styles.content}>
              <img src={aTeamLogo} alt="aTeam logo" />
              <div>
                <div className={styles.title}>
                  You have an upcoming evaluation
                </div>
                <div className={styles.title}>interview with A.Team</div>
                <div className={styles.dateAndTime}>
                  {date}
                  <hr className={styles.dateAndTimeSeparator} />
                  {time}
                </div>
              </div>
            </div>
            <hr className={styles.separator} />
            <div className={styles.buttonContainer}>
              <button
                className={styles.button}
                onClick={() => setCancelling(true)}
              >
                Cancel Interview
              </button>
            </div>
          </div>
        )}
      </Modal>
      <LoadingIndicator loading={loading} />
    </>
  );
}
