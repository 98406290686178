import { createQueryKeys } from '@lukemorales/query-key-factory';

export const profileSuggestions = createQueryKeys('profileSuggestions', {
  byUserId: (userId: string) => ({
    userId,
  }),
  adminByUserId: (userId: string) => ({
    userId,
    admin: true,
  }),
  enhanceProfileByUserId: (userId: string) => ({
    userId,
  }),
});
