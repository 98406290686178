import React, { ReactElement, useMemo, useState } from 'react';
import {
  ExperienceMember,
  ExperienceMemberRole,
} from '@a_team/models/dist/ExperienceObject';
import { Select, theme, Typography } from '@a_team/ui-components';
import { BasicUserObject, UserId } from '@a_team/models/dist/UserObject';
import { getProjectMemberId } from '@src/views/Profile/helpers/experience';
import { createUseStyles } from 'react-jss';
import ProjectUserSelector from '@src/components/ProjectUserSelector';
import cx from 'classnames';

interface Props {
  memberList: ExperienceMember[];
  setMemberList(members: ExperienceMember[]): void;
  currentUserId: UserId | undefined;
  roles: { value: string; label: string }[] | undefined;
  disabled?: boolean;
}
const useStyles = createUseStyles({
  add: {
    padding: '5px 0px',
    cursor: 'pointer',
  },
  addDisabled: {
    pointerEvents: 'none',
  },
  member: {
    marginBottom: 20,
  },
  roleWrap: {
    marginTop: 8,
  },
});

export const ProjectMembersSelect = (props: Props): ReactElement => {
  const { memberList, currentUserId, setMemberList, roles, disabled } = props;
  const [showAddMember, setShowAddMember] = useState(false);

  const styles = useStyles();

  const filteredMemberList = useMemo(() => {
    return memberList.filter(
      (item) => getProjectMemberId(item) !== currentUserId,
    );
  }, [memberList]);

  const addMember = (user: BasicUserObject) => {
    if (!user) return;
    setShowAddMember(false);
    const alreadyExists = memberList.find(
      (member) => member && getProjectMemberId(member) === user.uid,
    );
    const isCurrentUser = user.uid === currentUserId;

    if (alreadyExists || isCurrentUser) return;

    const list = [...memberList];
    list.push({
      ...user,
      ...{
        memberTitle: user.title,
        experienceRole: ExperienceMemberRole.Member,
      },
    });
    setMemberList(list);
  };

  // const removeMember = (member: ExperienceMember) => {
  //   setMemberList(
  //     memberList.filter(
  //       (user) => getProjectMemberId(user) !== getProjectMemberId(member),
  //     ),
  //   );
  // };

  // eslint-disable-next-line
  const setRole = (role: any, member: ExperienceMember) => {
    if (typeof role === 'object' && 'value' in role) {
      const list = [...memberList];
      const updatedList = list.map((user) => {
        if (getProjectMemberId(user) === getProjectMemberId(member)) {
          return { ...user, memberRole: role.value };
        } else {
          return user;
        }
      });

      setMemberList(updatedList);
    }
  };

  return (
    <>
      {memberList &&
        filteredMemberList.map((member, index) => (
          <div className={styles.member} key={getProjectMemberId(member)}>
            <ProjectUserSelector
              user={member as BasicUserObject}
              placeholder={'Add collaborator...'}
              onSelect={(user): void => addMember(user as BasicUserObject)}
              disabled={disabled}
            />
            <div className={styles.roleWrap}>
              <Select
                isDisabled={disabled}
                value={roles?.find((role) => role.value === member?.memberRole)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: theme.colors.Grey['400'],
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 20000,
                    color: theme.colors.Grey['800'],
                  }),
                }}
                menuPortalTarget={document.body}
                placeholder={`Select role`}
                // eslint-disable-next-line
                onChange={(role) => setRole(role, member as ExperienceMember)}
                options={roles}
              />
            </div>
          </div>
        ))}
      {(filteredMemberList.length === 0 || showAddMember) && (
        <ProjectUserSelector
          disabled={disabled}
          placeholder={'Add collaborator...'}
          onSelect={(user): void => addMember(user as BasicUserObject)}
        />
      )}
      <div
        className={cx(styles.add, { [styles.addDisabled]: disabled })}
        onClick={() => setShowAddMember(!showAddMember)}
      >
        <Typography
          variant={'textMedium'}
          margin={'md'}
          color={disabled ? 'Grey@400' : 'Baracus@600'}
          style={{ paddingTop: 20 }}
        >
          +Add New Collaborator
        </Typography>
      </div>
    </>
  );
};
