import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { useClientOnly } from 'react-client-only/index.js';
import { CollapsibleContainer } from '@ateams/components';
import MissionRole, {
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import MainLayout from '@src/layouts/Main';
import {
  MissionControlBase,
  MissionPageLocation,
  TargeterLocation,
} from '@src/locations';
import {
  Button as CallToActionButton,
  ButtonContainer,
  CloseButton,
  Divider,
} from '@ateams/components';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { useHistory, Redirect } from 'react-router-dom';
import FormSection from '@src/components/FormSection';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import useToggle from '@src/hooks/useToggle';
import { Location } from 'history';
import {
  MissionAdminObject,
  MissionStatus,
} from '@a_team/models/dist/MissionObject';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useAlteredState from '@src/hooks/useAlteredState';
import LinksListInput from './LinksListInput';
import TeamWorkRolesInput from '@src/views/Mission/EditMission/TeamWorkRolesInput';
import {
  getFormData,
  getTeamWorkData,
} from '@src/views/Mission/EditMission/FormData';
import SectionHeading from '@src/components/SectionHeading';
import CompanyRequestDetailsBox from '@src/views/Mission/EditMission/CompanyRequestDetailsBox';
import { Breakpoints } from '@ateams/components';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import FileUploader from '@src/components/FileUploader';
import { VideoSelector } from '@src/components/VideoSelector';
import TabButtons from '@src/components/TabButtons';
import TabButton from '@src/components/TabButtons/TabButton';
import AdminSettings from '@src/views/Mission/EditMission/AdminSettings';
import type TRichTextEditor from 'react-rte';
import { SelectIndustry } from '@src/components/SelectIndustry';
import Checkbox from '@src/components/Checkbox';
import { groupBy } from 'lodash';
import AutomatedReachoutModal from './AutomatedReachoutModal';

interface Props {
  location: Location;
  match: MissionMatch;
}

const useStyles = createUseStyles({
  root: {
    width: 'calc(100vw - 32px)',
    margin: '16px',
  },
  close: {
    position: 'fixed',
    top: '50px',
    right: '35px',
  },
  titleSection: {
    marginTop: '88px',
    width: 'calc(100vw - 32px)',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      width: '840px',
      margin: '120px auto 56px',
    },
    close: {
      position: 'fixed',
      top: '35px',
      right: '35px',
    },
    titleSection: {
      marginTop: '0',
      width: 'auto',
    },
  },
});

export const editTeamWorkViewLoader = loadMission;

/**
 * The RichTextEditor component requires access to the window object,
 * which causes the production build to fail due to SSR.
 * To avoid such a crash, implement and inline import the RichTextEditor
 * inside the constructor of the parent element.
 */
function EditTeamWorkViewInternal({
  RichTextEditor,
  ...props
}: Props & { RichTextEditor: typeof TRichTextEditor }): ReactElement | null {
  const styles = useStyles();
  const {
    match: {
      params: { mid },
    },
  } = props;
  const { auth, missions } = useStores();
  const isEditMode = !!mid;
  const orgData = isEditMode ? missions.current : null;
  const [modal, toggleCloseModal] = useToggle();
  const [showAutomatedReachoutModal, setShowAutomatedReachoutModal] =
    useState(false);
  const [descriptionRTE, setDescriptionRTE] = useState(
    RichTextEditor.createEmptyValue(),
  );
  const [companyStoryRTE, setCompanyStoryRTE] = useState(
    RichTextEditor.createEmptyValue(),
  );
  const [selectedTab, setSelectedTab] = useState<'admin' | 'public'>(
    auth.isAdmin && isEditMode ? 'admin' : 'public',
  );
  const [hasVideoError, setVideoError] = useState(false);
  const [data, altered, setData] = useAlteredState(
    getFormData(orgData as MissionAdminObject | null),
  );
  const [loading, setLoading] = useLoadingState(null);
  const history = useHistory();
  const canEditMission =
    auth.isAdmin ||
    missions.current?.creator?.uid === auth.uid ||
    missions.current?.publisher?.uid === auth.uid;
  if (
    isEditMode &&
    orgData &&
    !missions.currentMission?.applications &&
    loading !== true
  ) {
    setLoading(missions.currentMission?.getMissionApplications() || null, null);
  }

  useEffect((): void => {
    if (orgData) {
      setDescriptionRTE(
        RichTextEditor.createValueFromString(orgData.description, 'html'),
      );

      setCompanyStoryRTE(
        RichTextEditor.createValueFromString(
          orgData.companyStory ?? '',
          'html',
        ),
      );

      setData(getFormData(orgData as MissionAdminObject | null), true);
    }
  }, [orgData?.mid]);

  const companyRequest = (orgData as MissionAdminObject)?.companyRequest;

  const isValid =
    data.title && descriptionRTE && data.roles.length && !hasVideoError;

  const handleSubmit = (
    e?: FormEvent<HTMLFormElement>,
    skipModal?: boolean,
    redirect?: boolean,
  ): void => {
    e && e.preventDefault();
    if (!isValid) return;

    const sendData = getTeamWorkData({
      ...data,
      description: descriptionRTE.toString('html'),
      companyStory: companyStoryRTE.toString('html'),
    });

    if (!isEditMode || !orgData) return;

    if (
      !skipModal &&
      sendData.status === MissionStatus.Published &&
      orgData?.status !== MissionStatus.Published
    ) {
      setShowAutomatedReachoutModal(true);
      return;
    }

    setLoading(
      missions.update(auth, mid, sendData).then(() => {
        redirect &&
          history.push(mid ? MissionPageLocation(mid) : MissionControlBase);
      }),
      'Success! Your mission was submitted for review.',
    );
  };

  const handleClose = (): void => {
    history.push(mid ? MissionPageLocation(mid) : MissionControlBase);
    missions.setCurrent(null);
  };

  const groupedRoles = useMemo(
    () => groupBy(data.roles, 'status'),
    [data.roles],
  );

  const handleRoleUpdate = (role: MissionRole): void => {
    const newRoles = [...data.roles];
    const index = newRoles.findIndex((r) => r.rid === role.rid);
    newRoles[index] = role;
    setData({
      ...data,
      roles: newRoles,
    });
  };

  const handleRoleDelete = (role: MissionRole): void => {
    setData({
      ...data,
      roles: data.roles.filter((r) => r.rid !== role.rid),
    });
  };

  const onSaveWithReachoutRedirect = async () => {
    await handleSubmit(undefined, true, false);
    history.push(`${TargeterLocation}?loadFromTabCache=true&mid=${mid}`);
  };

  const onReachoutRedirect = () => {
    history.push(`${TargeterLocation}?loadFromTabCache=true&mid=${mid}`);
  };

  if (isEditMode && !canEditMission) {
    return (
      <Redirect to={mid ? MissionPageLocation(mid) : MissionControlBase} />
    );
  }

  return (
    <MainLayout
      title={isEditMode ? 'Edit Mission' : 'Create Mission'}
      style={{ padding: 0 }}
    >
      <ConfirmModal
        title="Are You Sure You Want to Leave?"
        description="We didn't get all of your mission's details yet. Your progress will be lost."
        onConfirm={handleClose}
        open={modal}
        onClose={toggleCloseModal}
        actionColor={'danger'}
        actionLabel={'Leave Now'}
      />
      <AutomatedReachoutModal
        open={showAutomatedReachoutModal}
        onClose={() => setShowAutomatedReachoutModal(false)}
        onSaveWithReachoutRedirect={onSaveWithReachoutRedirect}
        onSaveWithoutReachout={() => mid && handleSubmit(undefined, true, true)}
        onReachoutRedirect={onReachoutRedirect}
        missionId={mid}
      ></AutomatedReachoutModal>
      <CloseButton
        onClick={altered ? toggleCloseModal : handleClose}
        className={styles.close}
      />

      {auth.isAdmin && isEditMode && (
        <TabButtons sticky>
          <TabButton
            onClick={() => setSelectedTab('admin')}
            active={selectedTab === 'admin'}
          >
            Admin Settings
          </TabButton>
          <TabButton
            onClick={() => setSelectedTab('public')}
            active={selectedTab === 'public'}
          >
            Public Settings
          </TabButton>
        </TabButtons>
      )}

      <div className={styles.root}>
        {auth.isAdmin && isEditMode && selectedTab === 'admin' && orgData && (
          <AdminSettings
            setData={setData}
            data={data}
            setLoading={setLoading}
          />
        )}

        <form onSubmit={(e) => handleSubmit(e, false, true)}>
          {selectedTab === 'public' && (
            <>
              <div className={styles.titleSection}>
                <SectionHeading isFirst>
                  {isEditMode ? 'Edit Mission' : 'Post or Refer a Mission'}
                </SectionHeading>
              </div>
              {companyRequest && (
                <CompanyRequestDetailsBox details={companyRequest} />
              )}
              <FormSection
                title={'Company Logo'}
                optional
                description={
                  'Upload the logo of the company. The logo will be visible to everyone.'
                }
              >
                <FileUploader
                  fileUrl={data.logoURL}
                  cropAspect={1}
                  onFileUploadSuccess={(url) =>
                    setData({ ...data, logoURL: url })
                  }
                />
              </FormSection>
              <FormSection
                title={'Video description'}
                optional
                description={
                  'Got a video that can help us understand your mission better? Share the link here.'
                }
              >
                <VideoSelector
                  url={data.videoURL}
                  onChange={(videoURL) => {
                    setVideoError(false);
                    setData({ ...data, videoURL });
                  }}
                  onError={() => setVideoError(true)}
                  showNoChangeVideoWarning={true}
                />
              </FormSection>

              <FormSection title="Let's Name this Mission">
                <OutlinedInput
                  value={data.title}
                  required
                  onChange={(e): void =>
                    setData({ ...data, title: e.target.value })
                  }
                  placeholder={'Name your mission...'}
                />
              </FormSection>

              <FormSection
                title="Short Company Description"
                optional
                description="One-line company description to be added to the mission detail page header. 100 char limit."
              >
                <OutlinedInput
                  value={data.shortCompanyDescription ?? ''}
                  maxLength={100}
                  onChange={(e): void =>
                    setData({
                      ...data,
                      shortCompanyDescription: e.target.value,
                    })
                  }
                  placeholder={'Give us some details about the company...'}
                />
              </FormSection>

              <FormSection
                title="Long Company Description"
                description="Long-form description of the company, added to the text body on the mission detail page."
              >
                <RichTextEditor
                  value={companyStoryRTE}
                  onChange={(val) => setCompanyStoryRTE(val)}
                  toolbarConfig={{
                    display: [
                      'BLOCK_TYPE_DROPDOWN',
                      'INLINE_STYLE_BUTTONS',
                      'BLOCK_TYPE_BUTTONS',
                      'HISTORY_BUTTONS',
                    ],
                    INLINE_STYLE_BUTTONS: [
                      {
                        label: 'Bold',
                        style: 'BOLD',
                      },
                      { label: 'Italic', style: 'ITALIC' },
                      { label: 'Underline', style: 'UNDERLINE' },
                      { label: 'Strikethrough', style: 'STRIKETHROUGH' },
                    ],
                    BLOCK_TYPE_DROPDOWN: [
                      { label: 'Normal', style: 'unstyled' },
                      { label: 'Heading Large', style: 'header-one' },
                      { label: 'Heading Medium', style: 'header-two' },
                      { label: 'Heading Small', style: 'header-three' },
                    ],
                    BLOCK_TYPE_BUTTONS: [
                      { label: 'UL', style: 'unordered-list-item' },
                      { label: 'OL', style: 'ordered-list-item' },
                    ],
                  }}
                />
              </FormSection>

              <FormSection
                title="What's the Mission?"
                description="Give us some details about this mission! What kind of work is needed? What type of team do you have in mind? Key objectives? The more specific, the better!"
              >
                <RichTextEditor
                  value={descriptionRTE}
                  onChange={(val) => setDescriptionRTE(val)}
                  toolbarConfig={{
                    display: [
                      'BLOCK_TYPE_DROPDOWN',
                      'INLINE_STYLE_BUTTONS',
                      'BLOCK_TYPE_BUTTONS',
                      'HISTORY_BUTTONS',
                    ],
                    INLINE_STYLE_BUTTONS: [
                      {
                        label: 'Bold',
                        style: 'BOLD',
                      },
                      { label: 'Italic', style: 'ITALIC' },
                      { label: 'Underline', style: 'UNDERLINE' },
                      { label: 'Strikethrough', style: 'STRIKETHROUGH' },
                    ],
                    BLOCK_TYPE_DROPDOWN: [
                      { label: 'Normal', style: 'unstyled' },
                      { label: 'Heading Large', style: 'header-one' },
                      { label: 'Heading Medium', style: 'header-two' },
                      { label: 'Heading Small', style: 'header-three' },
                    ],
                    BLOCK_TYPE_BUTTONS: [
                      { label: 'UL', style: 'unordered-list-item' },
                      { label: 'OL', style: 'ordered-list-item' },
                    ],
                  }}
                />
                <div style={{ marginTop: '1rem' }}>
                  <Checkbox
                    customLabel="This is an A.Team internal mission"
                    checked={data.internalMission}
                    onChange={(e): void =>
                      setData({
                        ...data,
                        internalMission: e.target.checked,
                      })
                    }
                  />
                </div>
              </FormSection>

              <FormSection title={'Mission Industry'}>
                <SelectIndustry
                  defaultValues={missions.currentTalentIndustryIds.map(
                    ({ id, name }) => ({ value: id, label: name }),
                  )}
                  onChange={(talentIndustryIds) =>
                    setData({ ...data, talentIndustryIds })
                  }
                />
              </FormSection>

              <FormSection title="Mission Duration">
                <OutlinedInput
                  placeholder="Number of months"
                  value={data.expectedDurationMonths || ''}
                  onChange={(e): void =>
                    setData({
                      ...data,
                      expectedDurationMonths:
                        Number(e.target.value) || undefined,
                    })
                  }
                  width="fixed"
                />
              </FormSection>

              <FormSection
                title="Mission Resources"
                optional
                description="Can you think of anything that could help us understand the mission in more context? The company's website? A similar project?"
              >
                <LinksListInput
                  links={data.attachedLinks || []}
                  onChange={(attachedLinks): void =>
                    setData({ ...data, attachedLinks })
                  }
                />
              </FormSection>

              <Divider />

              <FormSection
                title="Now, Let's Make a Team"
                description="Your mission needs a dream team. Let's go!"
              >
                {!!groupedRoles[MissionRoleStatus.Open]?.length && (
                  <CollapsibleContainer
                    openDefault={true}
                    title={`Open roles (${
                      groupedRoles[MissionRoleStatus.Open].length
                    })`}
                    maxContentHeight={'unset'}
                    overflow="unset"
                  >
                    <TeamWorkRolesInput
                      roles={groupedRoles[MissionRoleStatus.Open]}
                      onDelete={(role) => handleRoleDelete(role)}
                      onChange={(role) => handleRoleUpdate(role)}
                    />
                  </CollapsibleContainer>
                )}
                {!!groupedRoles[MissionRoleStatus.Active]?.length && (
                  <CollapsibleContainer
                    title={`Active roles (${
                      groupedRoles[MissionRoleStatus.Active].length
                    })`}
                    maxContentHeight={'unset'}
                    overflow="unset"
                  >
                    <TeamWorkRolesInput
                      roles={groupedRoles[MissionRoleStatus.Active]}
                      onDelete={(role) => handleRoleDelete(role)}
                      onChange={(role) => handleRoleUpdate(role)}
                    />
                  </CollapsibleContainer>
                )}

                {!!groupedRoles[MissionRoleStatus.ScheduledToEnd]?.length && (
                  <CollapsibleContainer
                    title={`Scheduled to end roles (${
                      groupedRoles[MissionRoleStatus.ScheduledToEnd].length
                    })`}
                    maxContentHeight={'unset'}
                    overflow="unset"
                  >
                    <TeamWorkRolesInput
                      disabled
                      roles={groupedRoles[MissionRoleStatus.ScheduledToEnd]}
                      onChange={(role) => handleRoleUpdate(role)}
                      onDelete={(role) => handleRoleDelete(role)}
                    />
                  </CollapsibleContainer>
                )}

                {!!groupedRoles[MissionRoleStatus.Ended]?.length && (
                  <CollapsibleContainer
                    title={`Ended roles (${
                      groupedRoles[MissionRoleStatus.Ended].length
                    })`}
                    maxContentHeight={'unset'}
                    overflow="unset"
                  >
                    <TeamWorkRolesInput
                      disabled
                      roles={groupedRoles[MissionRoleStatus.Ended]}
                      onChange={(role) => handleRoleUpdate(role)}
                      onDelete={(role) => handleRoleDelete(role)}
                    />
                  </CollapsibleContainer>
                )}

                {!!groupedRoles[MissionRoleStatus.Canceled]?.length && (
                  <CollapsibleContainer
                    title={`Canceled roles (${
                      groupedRoles[MissionRoleStatus.Canceled].length
                    })`}
                    maxContentHeight={'unset'}
                    overflow="unset"
                  >
                    <TeamWorkRolesInput
                      disabled
                      roles={groupedRoles[MissionRoleStatus.Canceled]}
                      onChange={(role) => handleRoleUpdate(role)}
                      onDelete={(role) => handleRoleDelete(role)}
                    />
                  </CollapsibleContainer>
                )}
              </FormSection>

              <Divider />

              <LabeledCheckboxInput
                checked={!!data.hidden}
                onChange={(e): void =>
                  setData({ ...data, hidden: e.target.checked })
                }
                label="This mission is under wraps! Only people with the direct link can see this mission."
              />
            </>
          )}

          <ButtonContainer>
            <CallToActionButton disabled={!isValid}>
              Submit for Review
            </CallToActionButton>
          </ButtonContainer>
          <LoadingIndicator loading={loading} />
        </form>
      </div>
    </MainLayout>
  );
}

const EditTeamWorkView = (props: Props): ReactElement | null => {
  const isOnClient = useClientOnly();
  if (!isOnClient) {
    return null;
  }
  // prettier-ignore
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const RichTextEditor: typeof TRichTextEditor =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('react-rte-17').default;
  return (
    <EditTeamWorkViewInternal RichTextEditor={RichTextEditor} {...props} />
  );
};

export default observer(EditTeamWorkView);
