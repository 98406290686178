import { theme, Typography, Avatar, Icon, Tag } from '@a_team/ui-components';
import {
  ExistingProject,
  Expertise,
  NewProject,
} from '@src/stores/Profile/models';
import Modal from '@src/components/Modal';
import React, { ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useStores } from '@src/stores';
import { getProjectId, isProjectMemberUser } from '../../../helpers/experience';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import formatDate from '@src/helpers/formatDate';
import Member from './Member';
import { Breakpoints } from '@ateams/components';
import useMultipleImages from '@src/views/Profile/Main/Projects/MultipleImages/hooks/useMultipleImages';
import MultipleImagesArrows from '@src/views/Profile/Main/Projects/MultipleImages/MultipleImagesArrows';
import { useHistory } from 'react-router-dom';
import { ProfileLocation } from '@src/locations';
import { UserUsername } from '@a_team/models/dist/UserObject';
import { ExperienceId } from '@a_team/models/dist/ExperienceObject';

interface Props {
  allProfileSkills?: Expertise[];
  setViewModalOpen(val: boolean): void;
  viewModalOpen: boolean;
  handleEditClicked(id: string): void;
  selectedProject: NewProject | ExistingProject;
  onClose(): void;
  onDelete?: (id: ExperienceId) => Promise<void>;
  roles: { value: string; label: string }[] | undefined;
  isOwner: boolean;
  isCurrentUser: boolean;
  industries?: Expertise[];
  withNewExperiences?: boolean;
}

interface StyleProps {
  bgImage: string;
}

const useStyles = createUseStyles<StyleProps>({
  modal: {
    maxHeight: '100% !important',
    padding: 0,
    border: 'none',
    borderRadius: '0px !important',
    width: '100% !important',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexBasis: 450,
    padding: 0,
    overflow: 'auto',
    order: 3,
    zIndex: 99,
  },
  formInner: {
    padding: 24,
  },
  uploader: {
    flex: '1 0 0',
    flexBasis: '50%',
    backgroundColor: theme.colors.Grey['200'],
    margin: '1, 1, 0, 0',
    zIndex: 1,
    order: 2,
    position: 'relative',
  },
  image: ({ bgImage }: StyleProps) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    background: `url(${bgImage}) center center / cover no-repeat`,
    minHeight: 300,
    transition: 'all 0.5s',
  }),
  skills: {
    marginTop: 25,
  },
  members: {
    marginTop: 25,
  },
  emptyAvatar: {
    width: 45,
    height: 45,
    backgroundColor: theme.colors.Grey['200'],
    borderRadius: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 12,
    position: 'sticky',
    bottom: -1,
    marginTop: 15,
    padding: '10px 15px',
    backgroundColor: theme.colors.Grey['100'],
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    modal: ({ bgImage }: StyleProps) => ({
      width: `${bgImage ? '950px' : '525px'} !important`,
      height: '600px !important',
      borderRadius: '16px !important',
    }),
    main: {
      flexDirection: 'row',
    },
    form: {
      order: 1,
      flex: '1 0 0',
      flexBasis: 450,
    },
    image: {
      height: '100%',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '70% !important',
      maxHeight: '600px !important',
      borderRadius: '16px !important',
    },
  },
  [`@media (max-width: ${Breakpoints.sm}px)`]: {
    modal: {
      top: '0px !important',
      bottom: '0px !important',
      inset: 'unset !important',
      transform: 'unset !important',
      height: '100% !important',
    },
    form: {
      flexBasis: '70%',
    },
    uploader: {
      flex: '0 0 30%',
      height: '30%',
    },
    image: {
      height: '100%',
    },
  },
});

const ViewProjectModal = (props: Props): ReactElement => {
  const {
    viewModalOpen: editModalOpen,
    setViewModalOpen: setEditModalOpen,
    handleEditClicked,
    selectedProject,
    onClose,
    onDelete,
    roles,
    isOwner,
    industries,
    isCurrentUser,
    allProfileSkills,
  } = props;

  const { next, images, prev, currentImage } = useMultipleImages({
    imageUrls:
      selectedProject.imagesUrls && selectedProject.imagesUrls.length > 0
        ? selectedProject.imagesUrls
        : selectedProject.imageURL
        ? [selectedProject.imageURL]
        : [],
  });

  const styles = useStyles({
    bgImage: currentImage,
  });
  const { auth } = useStores();
  const history = useHistory();
  const [loading, setLoading] = useLoadingState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const profileSkills = useMemo(() => {
    return allProfileSkills?.map((skill) => {
      return { value: skill.id, label: skill.name };
    });
  }, [allProfileSkills]);

  const startDate = useMemo(() => {
    const { startDate } = selectedProject;
    return startDate ? formatDate(startDate, 'MMM yyyy') : undefined;
  }, [selectedProject.startDate]);

  const endDate = useMemo(() => {
    const { endDate } = selectedProject;
    return endDate ? formatDate(endDate, 'MMM yyyy') : undefined;
  }, [selectedProject.endDate]);

  const industry = useMemo(
    () =>
      industries?.find((item) => item.id === selectedProject.industry)?.name,
    [selectedProject.industry, industries],
  );

  const filledSkills = useMemo(() => {
    const { skills } = selectedProject;
    if (!skills || skills.length === 0 || !profileSkills) {
      return [];
    }
    const newSkills: { value: string; label: string }[] = [];
    skills.forEach((skill) => {
      const foundSkill = profileSkills.find((pSkill) => pSkill.value === skill);
      if (foundSkill) {
        newSkills.push(foundSkill);
      }
    });
    return newSkills;
  }, [selectedProject.skills]);

  const handleEdit = () => {
    handleEditClicked(getProjectId(selectedProject));
    setEditModalOpen(false);
  };

  const handleDeleteProject = () => {
    onDelete &&
      setLoading(
        onDelete(getProjectId(selectedProject)).then(() =>
          setEditModalOpen(false),
        ),
      );
  };

  const onProjectMemberClick = (username: UserUsername) => {
    history.push(ProfileLocation(username));
  };

  return (
    <>
      <Modal
        open={editModalOpen}
        onClose={() => {
          onClose();
          setEditModalOpen(false);
        }}
        className={styles.modal}
        style={{
          padding: 0,
          overflow: 'clip',
        }}
        closeButtonStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          borderRadius: '100%',
          width: 32,
          height: 32,
          lineHeight: '12px',
        }}
      >
        <div className={styles.main}>
          <div className={styles.form}>
            <div className={styles.formInner}>
              {selectedProject?.companyData?.logoUrl ? (
                <Avatar
                  styles={{ borderRadius: 100 }}
                  size="lg"
                  src={selectedProject?.companyData?.logoUrl || undefined}
                />
              ) : (
                <div className={styles.emptyAvatar}>
                  <Icon
                    name={'projects'}
                    color={'Grey@700'}
                    size={'md'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      lineHeight: '52px',
                    }}
                  />
                </div>
              )}
              <div style={{ marginTop: 8 }}>
                <Typography variant={'textMedium'} weight={'light'}>
                  {selectedProject?.companyData?.name || 'No Company Selected'}
                </Typography>
              </div>
              <div style={{ marginTop: 16 }}>
                <Typography
                  variant={'textMedium'}
                  weight={'light'}
                  style={{ marginBottom: 4, marginTop: 30 }}
                >
                  <Icon
                    name={'suitcase'}
                    color={'Grey@700'}
                    size={'md'}
                    style={{ marginRight: 4, verticalAlign: 'middle' }}
                  />
                  {industry || 'No Industry Selected'}
                </Typography>
              </div>
              <div style={{ marginTop: 2 }}>
                <Typography
                  variant={'textMedium'}
                  weight={'light'}
                  style={{
                    marginBottom: 4,
                    marginTop: 30,
                  }}
                >
                  <Icon
                    name={'calendarFull'}
                    color={'Grey@700'}
                    size={'md'}
                    style={{
                      marginRight: 4,
                      marginLeft: 0,
                      verticalAlign: 'middle',
                    }}
                  />
                  {startDate
                    ? endDate
                      ? `${startDate} - ${endDate}`
                      : `${startDate} - Present`
                    : 'No Dates Selected'}{' '}
                  {selectedProject.projectUrl && (
                    <>
                      <span>|</span>
                      <a
                        href={selectedProject.projectUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        <Icon
                          name={'link'}
                          color={'Grey@700'}
                          size={'md'}
                          style={{
                            marginLeft: 2,
                            verticalAlign: 'middle',
                          }}
                        />{' '}
                        <Typography
                          variant={'textMedium'}
                          margin={'none'}
                          color={'Baracus@600'}
                          weight={'light'}
                          style={{ cursor: 'pointer' }}
                        >
                          Project page
                        </Typography>
                      </a>
                    </>
                  )}
                </Typography>
              </div>
              <Typography
                variant={'h3'}
                margin={'xxs'}
                style={{ marginBottom: 2, marginTop: 20 }}
              >
                {selectedProject.title}
              </Typography>
              <Typography
                variant={'textMedium'}
                weight={'light'}
                style={{ whiteSpace: 'pre-line' }}
              >
                {selectedProject.description}
              </Typography>
              <div className={styles.skills}>
                <Typography
                  variant={'textMedium'}
                  weight={'bold'}
                  uppercase={true}
                  color={'Grey@400'}
                >
                  Skills
                </Typography>
                {filledSkills && filledSkills.length > 0 ? (
                  <div style={{ paddingTop: 8 }}>
                    {' '}
                    {filledSkills?.map((skill) => (
                      <Tag
                        label={skill.label}
                        key={skill.value}
                        color={'Grey@200'}
                        style={{
                          marginRight: 10,
                          marginBottom: 10,
                        }}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    <Typography
                      variant={'textMedium'}
                      weight={'light'}
                      color={'Grey@600'}
                    >
                      No Skills Selected
                    </Typography>
                  </div>
                )}
              </div>
              <div className={styles.members}>
                <Typography
                  variant={'textMedium'}
                  weight={'bold'}
                  uppercase={true}
                  color={'Grey@400'}
                >
                  Team Members
                </Typography>
                {selectedProject.members.length > 0 &&
                  selectedProject.members.map(
                    (member, i) =>
                      isProjectMemberUser(member) && (
                        <Member
                          ownerJobRoleId={member.memberRole}
                          ownerJobRole={member.memberTitle}
                          member={member}
                          roles={roles}
                          onClick={
                            auth.basicAccess ? onProjectMemberClick : undefined
                          }
                          key={member.uid}
                        />
                      ),
                  )}
              </div>
            </div>
            {isOwner && isCurrentUser && (
              <div className={styles.buttons}>
                <div>
                  <Typography
                    variant={'textMedium'}
                    margin={'none'}
                    color={'Baracus@600'}
                    weight={'light'}
                    onClick={(e) => {
                      e.preventDefault();
                      handleEdit();
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Edit Project
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant={'textMedium'}
                    margin={'none'}
                    color={'Red@600'}
                    weight={'light'}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowDeleteModal(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Remove Project
                  </Typography>
                </div>
              </div>
            )}
          </div>
          {images && images.length > 0 && (
            <div className={styles.uploader}>
              {images.length > 1 && (
                <MultipleImagesArrows next={next} prev={prev} />
              )}
              <div className={styles.image} />
            </div>
          )}
        </div>
      </Modal>
      <LoadingIndicator loading={loading} />
      <ConfirmModal
        title={'Delete Project?'}
        actionLabel={'Delete'}
        description={'Are you sure you want to delete this project?'}
        onConfirm={() => handleDeleteProject()}
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
      />
    </>
  );
};

export default ViewProjectModal;
