import {
  BuilderSuggestionId,
  BuilderSuggestionType,
  EditedSuggestion,
  ProfileEnhancementSuggestions,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { apiProfileSuggestions } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './keys';
import {
  JobExperienceData,
  ProjectExperienceData,
} from '@a_team/models/dist/ExperienceObject';
import { isNil } from 'lodash/fp';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { refreshProfile } from './utils';

interface PartialSuggestion {
  sid: BuilderSuggestionId;
  type: BuilderSuggestionType;
  partialSuggestionId: string;
}

interface RoleSuggestion {
  sid: BuilderSuggestionId;
  roleId: string;
}

interface IndustrySuggestion {
  sid: BuilderSuggestionId;
  industryId: string;
}

export const useGetAdminProfileSuggestions = ({
  onSuccess,
}: {
  onSuccess?: (data: ProfileEnhancementSuggestions) => void;
} = {}) => {
  const { users, auth, missions } = useStores();
  const { profile } = users;
  const isCurrentUser =
    profile?.isCurrentUser ??
    missions.currentApplication?.isCurrentUser ??
    false;
  const isAdminRequest = auth.isAdmin && !isCurrentUser;
  const userId =
    profile?.data.uid ?? missions.currentApplication?.data.uid ?? undefined;

  return useQuery({
    enabled: isAdminRequest && !isNil(userId),
    queryKey: queryKeys.profileSuggestions.adminByUserId(userId ?? ''),
    queryFn: () => apiProfileSuggestions.userSuggestions(auth, userId ?? ''),
    onSuccess,
  });
};

export const useGetProfileSuggestions = ({
  onSuccess,
}: {
  onSuccess?: (data: ProfileEnhancementSuggestions) => void;
} = {}) => {
  const { users, auth, missions } = useStores();
  const { profile } = users;

  return useQuery({
    enabled:
      auth.isActive &&
      !!profile?.isCurrentUser &&
      !!profile?.data.uid &&
      isNil(missions.currentApplication?.application?.aid),
    queryKey: queryKeys.profileSuggestions.byUserId(profile?.data.uid ?? ''),
    queryFn: () => apiProfileSuggestions.suggestions(auth),
    onSuccess,
  });
};

export const useApplyProjectSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      sid,
      eid,
      editedExperience,
    }: {
      sid: BuilderSuggestionId;
      eid: string;
      editedExperience: ProjectExperienceData;
    }) =>
      apiProfileSuggestions.applySuggestedProject(
        stores.auth,
        sid,
        eid,
        editedExperience,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useRejectProfileSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (sid: BuilderSuggestionId) =>
      apiProfileSuggestions.rejectSuggestion(stores.auth, sid),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useApplyProfileSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      sid,
      editedSuggestion,
    }: {
      sid: BuilderSuggestionId;
      editedSuggestion?: EditedSuggestion;
    }) =>
      apiProfileSuggestions.applySuggestion(stores.auth, sid, {
        editedSuggestion,
      }),
    onSuccess: async () => {
      await refreshProfile(stores);

      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useApplySuggestedRole = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (suggestedRole: RoleSuggestion) =>
      apiProfileSuggestions.applySuggestedRole(
        stores.auth,
        suggestedRole.sid,
        suggestedRole.roleId,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useApplyJobSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async ({
      sid,
      eid,
      editedExperience,
    }: {
      sid: BuilderSuggestionId;
      eid: string;
      editedExperience: JobExperienceData;
    }) =>
      apiProfileSuggestions.applySuggestedJob(
        stores.auth,
        sid,
        eid,
        editedExperience,
      ),
    onSuccess: (_, vars) => {
      analytics.trackProfileSuggestionInteraction(
        stores.auth.uid || '',
        vars.sid,
        SuggestionActionType.PARTIAL_ACCEPT,
        BuilderSuggestionType.JOBS,
        [vars.editedExperience.jobRole || '', vars.editedExperience.name || ''],
      );
      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const usePartiallyRejectSuggestion = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async (rejectedSuggestion: PartialSuggestion) =>
      apiProfileSuggestions.rejectSuggestionPartially(
        stores.auth,
        rejectedSuggestion.sid,
        rejectedSuggestion.type,
        rejectedSuggestion.partialSuggestionId,
      ),
    onSuccess: (_, vars) => {
      analytics.trackProfileSuggestionInteraction(
        stores.auth.uid || '',
        vars.sid,
        SuggestionActionType.REJECT,
        vars.type,
      );

      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useEnhanceProfile = () => {
  const { auth } = useStores();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation({
    mutationKey: queryKeys.profileSuggestions.enhanceProfileByUserId(
      auth.uid || '',
    ),
    mutationFn: async () =>
      apiProfileSuggestions.generateProfileSuggestions(auth),
    onSuccess: () => {
      analytics.trackProfileSuggestionsGenerated(auth.uid || '');

      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useApplySuggestedIndustry = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (suggestedIndustry: IndustrySuggestion) =>
      apiProfileSuggestions.applySuggestedIndustry(
        stores.auth,
        suggestedIndustry.sid,
        suggestedIndustry.industryId,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.profileSuggestions.byUserId(
          stores.auth.currentUser?.uid ?? '',
        ),
      });
    },
  });
};

export const useGenerateBioSuggestionOnDemand = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async () => {
      return apiProfileSuggestions.generateBioSuggestionOnDemand(auth);
    },
  });
};
