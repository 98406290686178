import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import { EmailTemplateGenerator } from '..';

export const createInternalNonTechnicalInitialEmailTemplate: EmailTemplateGenerator =
  () => {
    return {
      subject: '{{builderFirstName}}, schedule your A.Team evaluation call',
      body: `<p>Hi {{builderFirstName}},</p>
<p>{{contactOwnerFirstName}} here from A.Team. I’m excited to invite you to an <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> — a mandatory next step before we can consider you for missions.</p>
<p>The <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> is your chance to tell us about all the great things we can use to pitch you for missions, and it's our opportunity to confirm that you're a good fit for our network and the top-tier companies we partner with.</p>
<p>Come prepared with:</p>
<ul>
  <li>1-2 minute "elevator pitch" about your skills, experience, and achievements</li>
  <li>2-3 project examples - focus on projects you had a significant contribution to and be ready to answer questions about them</li>
  <li>Your portfolio and case studies (for designers) OR sample code (for developers), if provided </li>
  <li>Be ready to walk the interviewer through your thought process and success metrics </li>
</ul>
<p>Please check in advance that you have a stable internet connection, as well as high-quality audio and video setup.</p>
<div>{{requiredCallParticipationNote}}</div>
<h1><a href="{{calendarUrl}}">Schedule my evaluation call</a></h1>
<p><i>If something comes up and you can’t make it, let us know in advance!</i></p>
<p>Looking forward to get to know you!</p>
<p>Best,<br>
{{contactOwnerFirstName}}</p>
<p>P.S. We’ll be recording this call for internal learning purposes. If you don’t want to be recorded, let us know by replying to this email.</p>`,
      templateType: VettingProcessEmailTemplates.InternalNonTechnicalInitial,
      sendInThread: false,
    };
  };

export const createInternalNonTechnicalFirstFollowupEmailTemplate: EmailTemplateGenerator =
  () => {
    return {
      subject: '{{builderFirstName}}, schedule your A.Team evaluation call',
      body: `<p>Hi {{builderFirstName}},</p>
<p>{{contactOwnerFirstName}} here from A.Team. We sent you an email about scheduling your <a href="https://guide.a.team/getting-started/evaluation-call">evaluation call</a> — to assess your skills and ensure they’re a fit for our open missions. Would love to get something on the calendar with you!</p>
<div>{{requiredCallParticipationWarning}}</div>
<h1><a href="{{calendarUrl}}">Schedule my evaluation call</a></h1>
<p>Looking forward to meeting you,<br>
{{contactOwnerFirstName}}</p>`,
      templateType: VettingProcessEmailTemplates.InternalNonTechnicalFollowUp,
      sendInThread: true,
    };
  };
