import React, { ReactElement, ReactNode } from 'react';
import AuthStore, { CookieManager } from './Auth';
import ErrorStore from './Error';
import DocumentStore from './Document';
import UsersStore, { UsersStoreData } from './Users';
import ExperienceStore from './Experience';
import ProfileStore from './Profile/Profile';
import SearchStore from '@src/stores/Search';
import MissionsStore from '@src/stores/Missions/Missions';
import TargeterTabManager from '@src/stores/TeamGraph/TargeterTabManager';
import RoleCategoriesStore from '@src/stores/RoleCategories';
import { StoreData } from '@src/stores/Store';
import StatisticsStore from '@src/stores/Statistics';
import InvoicesStore from '@src/stores/Invoices';
import VettingProcessesStore from '@src/stores/VettingProcesses';
import { PlatformServiceAnalytics } from '@ateams/analytics/dist/platform';
import RegistrationStore from '@src/stores/Registration/Registration';
import MissionControlStore from '@src/stores/MissionControl/MissionControl';
import { configure } from 'mobx';
import NotificationPreferencesUserStore from '@src/stores/NotificationPreferencesUser';
import VettersStore from './Vetters';
import SelectionTeamOnboardingStore from './SelectionTeamOnboarding';
import RewardsStore from '@src/stores/Rewards';
import NotificationPreferencesAdminStore from './NotificationPreferencesAdmin';
import PaymentsStore from './Payments';
import DiscountsStore from './Discounts';
import UIStore from './UIStore';
import EvaluationSettingsUserStore from './EvaluationSettingsUserStore';

configure({ isolateGlobalState: true });

export interface Stores {
  document: DocumentStore;
  error: ErrorStore;
  auth: AuthStore;
  users: UsersStore;
  profile: ProfileStore;
  experience: ExperienceStore;
  search: SearchStore;
  missions: MissionsStore;
  roleCategories: RoleCategoriesStore;
  statistics: StatisticsStore;
  missionControl: MissionControlStore;
  invoices: InvoicesStore;
  notificationPreferencesUser: NotificationPreferencesUserStore;
  notificationPreferencesAdmin: NotificationPreferencesAdminStore;
  vetters: VettersStore;
  selectionTeamOnboarding: SelectionTeamOnboardingStore;
  vettingProcesses: VettingProcessesStore;
  registration: RegistrationStore;
  targeterTabManager: TargeterTabManager;
  rewards: RewardsStore;
  payments: PaymentsStore;
  discounts: DiscountsStore;
  uiStore: UIStore;
  evaluationSettingsUser: EvaluationSettingsUserStore;
}

export type StoresData = {
  [K in keyof Stores]?: StoreData<Stores[K]>;
};

export const StoresContext: React.Context<Stores | null> =
  React.createContext<Stores | null>(null);

// exported functions

export function Provider(props: {
  stores: Stores;
  children: ReactNode;
}): ReactElement {
  return (
    <StoresContext.Provider value={props.stores}>
      {props.children}
    </StoresContext.Provider>
  );
}

export function createStores(
  cm: CookieManager,
  analytics: PlatformServiceAnalytics,
  initStores: StoresData = {},
): Stores {
  const stores: Stores = {} as Stores;

  stores.error = new ErrorStore(stores, initStores.error);
  stores.auth = new AuthStore(stores, cm, analytics, initStores.auth);
  stores.document = new DocumentStore();
  stores.payments = new PaymentsStore(stores, initStores.payments);
  stores.missions = new MissionsStore(
    stores,
    analytics,
    initStores.missions as MissionsStore,
  );
  stores.missionControl = new MissionControlStore(
    stores,
    stores.auth,
    initStores.missionControl as MissionControlStore,
  );
  stores.users = new UsersStore(
    stores,
    analytics,
    initStores.users as UsersStoreData,
  );
  stores.experience = new ExperienceStore(stores, initStores.experience);
  stores.rewards = new RewardsStore(stores, initStores.rewards);
  stores.search = new SearchStore(stores, analytics, initStores.search);
  stores.roleCategories = new RoleCategoriesStore(
    stores,
    initStores.roleCategories,
  );
  stores.statistics = new StatisticsStore(stores);
  stores.invoices = new InvoicesStore(stores, initStores.invoices);
  stores.vetters = new VettersStore(stores, initStores.vetters);
  stores.selectionTeamOnboarding = new SelectionTeamOnboardingStore(
    stores,
    initStores.selectionTeamOnboarding,
  );
  stores.vettingProcesses = new VettingProcessesStore(
    stores,
    initStores.vettingProcesses,
  );
  stores.notificationPreferencesUser = new NotificationPreferencesUserStore(
    stores,
    analytics,
    initStores.notificationPreferencesUser,
  );
  stores.notificationPreferencesAdmin = new NotificationPreferencesAdminStore(
    stores,
    analytics,
    initStores.notificationPreferencesAdmin,
  );
  stores.registration = new RegistrationStore(
    stores,
    analytics,
    initStores.registration,
  );
  stores.targeterTabManager = new TargeterTabManager();
  stores.discounts = new DiscountsStore(stores, initStores.discounts);
  stores.uiStore = new UIStore(stores, initStores.uiStore);
  stores.evaluationSettingsUser = new EvaluationSettingsUserStore(stores);

  return stores;
}

export function fetchStoreData(stores: Stores): Promise<void> {
  return stores.auth.fetchData();
}

export function serializeStores(stores: Stores): StoresData {
  const data: StoresData = {};

  for (const name in stores) {
    data[name as never] = stores[name as keyof Stores].serialize() as never;
  }

  return data;
}

export function useStores(): Stores {
  const store = React.useContext(StoresContext);

  if (!store) {
    throw new Error('useStores must be used within a Context Provider.');
  }

  return store;
}
