import { theme, Typography, Avatar } from '@a_team/ui-components';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import {
  ExperienceMemberRole,
  ExperienceUserMember,
} from '@a_team/models/dist/ExperienceObject';
import { TalentSpecializationId } from '@a_team/models/dist/TalentCategories';
import cx from 'classnames';
import { UserUsername } from '@a_team/models/dist/UserObject';

interface Props {
  member: ExperienceUserMember;
  ownerJobRoleId?: TalentSpecializationId;
  ownerJobRole?: string;
  roles: { value: string; label: string }[] | undefined;
  onClick?: (username: UserUsername) => void;
}

const useStyles = createUseStyles({
  member: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 12,
    pointerEvents: 'none',
  },
  clickable: {
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
  hello: {
    height: 200,
  },
  avatarWrap: {
    border: `2px solid ${theme.colors.Green['500']}`,
    borderRadius: '100%',
    padding: 2,
    marginRight: 10,
  },
  memberInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
});

const Member = (props: Props): ReactElement => {
  const { member, ownerJobRoleId, ownerJobRole, roles, onClick } = props;
  const {
    uid,
    profilePictureURL,
    firstName,
    experienceRole,
    title,
    memberRole,
    memberTitle,
  } = member;
  const styles = useStyles();

  const isOwner = experienceRole === ExperienceMemberRole.Owner;
  const showMemberTitle = (isOwner && ownerJobRole) || memberTitle;

  const onMemberClick = () => {
    onClick && onClick(member.username);
  };

  if (!member || !firstName) {
    return <></>;
  }

  return (
    <div
      className={cx(
        styles.member,
        { [styles.clickable]: !!onClick },
        styles.hello,
      )}
      key={uid}
      onClick={onMemberClick}
    >
      <div className={styles.avatarWrap}>
        <Avatar
          size={'md'}
          styles={{
            borderRadius: 100,
          }}
          src={profilePictureURL}
        />
      </div>
      <div className={styles.memberInfo}>
        <Typography
          variant={'h4'}
          margin={'none'}
          weight={'light'}
          color={'Grey@900'}
        >
          {firstName}
        </Typography>
        <Typography
          variant={'textMedium'}
          weight={'light'}
          margin={'none'}
          color={'Grey@500'}
        >
          {isOwner
            ? (ownerJobRoleId &&
                roles?.find((role) => role.value === ownerJobRoleId)?.label) ||
              title
            : roles?.find((role) => role.value === memberRole)?.label}
        </Typography>
        {showMemberTitle && (
          <Typography
            variant={'textSmall'}
            weight={'light'}
            margin={'none'}
            color={'Grey@500'}
            style={{ textTransform: 'capitalize' }}
          >
            {isOwner && ownerJobRole ? ownerJobRole : member.memberTitle}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Member;
