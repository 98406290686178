import { differenceInDays } from 'date-fns';
import { DateISOString } from '@a_team/models/dist/misc';

export const DAYS_TO_EDIT_FEEDBACK_FORM_ANSWERS = 30;

enum CannotSubmitOrEditFeedbackFormReasons {
  FormSubmissionTimeout = 'formSubmissionTimeout',
  NotTheInterviewer = 'notTheInterviewer',
}

/**
 * Checks if the user can submit/edit the feedback form
 * He can't if he is not an admin and more than 2 weeks has passed since initially submitting the form
 */
export function canSubmitOrEditVettingFeedbackForm(
  isCurrentUserAdmin: boolean,
  isCurrentUserTheInterviewer?: boolean,
  submitDate?: DateISOString,
): CannotSubmitOrEditFeedbackFormReasons | null {
  if (isCurrentUserAdmin) {
    return null;
  }

  if (!isCurrentUserTheInterviewer) {
    return CannotSubmitOrEditFeedbackFormReasons.NotTheInterviewer;
  }

  if (
    submitDate &&
    differenceInDays(new Date(), new Date(submitDate)) >=
      DAYS_TO_EDIT_FEEDBACK_FORM_ANSWERS
  ) {
    return CannotSubmitOrEditFeedbackFormReasons.FormSubmissionTimeout;
  }

  return null;
}
