import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import MissionRole, {
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import { TimesheetInitiativeId } from '@a_team/models/dist/TimesheetInitiativeObject';
import { TimesheetRecordType } from '@a_team/models/dist/TimesheetObject';
import {
  useMutationDeleteRecord,
  useMutationSetRecord,
} from '@src/rq/timesheets';
import Mission, { RoleRecord } from '@src/stores/Missions/Mission';
import { DataGridNav } from '@table-nav/core';
import {
  ColumnDef,
  RowData,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import GetPaidBanner from '../GetPaidBanner';
import ClearRowDropdown from './ClearRowDropdown';
import ErrorBar from './ErrorBar';
import InitiativeSelect from './InitiativeSelect';
import { checkIfRowIsOOO } from './InitiativeSelect/utils';
import TextInput from './TextInput';
import TimeInput, { minutesToTime } from './TimeInput';
import TypeSelect from './TypeSelect';
import EmptyCell from './common/EmptyCell';
import { useCommonStyles } from './common/commonStyles';
import { outOfOfficeTypes } from './data';
import {
  TIMESHEET_TASK_MIN_LENGTH,
  checkIfRowIsValid,
  formatDate,
} from './utils';
import useGetMonthlyRetainerDataForCurrentUserRole from '../hooks/useGetMonthlyRetainerDataForCurrentUserRole';
import { MissionBillingPeriod } from '@a_team/models/dist/MissionObject';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import useGetPaymentAndHoursForGivenPeriod from '../hooks/useGetPaymentAndHoursForGivenPeriod';
import AddRecord from './AddRecord';
import TableSkeleton from './TableSkeleton';
import SectionHeader from './common/SectionHeader';
import useGetGroupedTimeByDay, {
  GroupedTimeByDayMapValue,
} from '../hooks/useGetGroupedTimeByDay';

const REQUIRED_FILLED_DAYS_MONTHLY_PERIOD = 20;
const REQUIRED_FILLED_DAYS_BI_WEEKLY_PERIOD = 10;
const REQUIRED_FILLED_DAYS_WEEKLY_PERIOD = 5;

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData: (rowKey: string, columnId: string, value: unknown) => void;
    removeRow: (rowKey: string) => void;
    onUpdateMutation: ({
      key,
      type,
      initiativeIds,
    }: {
      key: string;
      type?: TimesheetRecordType;
      initiativeIds?: TimesheetInitiativeId[];
    }) => void;
    hourlyRate?: number;
    sid: string;
    isFullTimeRetainer?: boolean;
    fixedHoursAmount?: string;
    fixedPaymentAmount?: string;
    timeEntriesMap?: Map<number, GroupedTimeByDayMapValue>;
  }
}

const useStyles = createUseStyles({
  cellWithErrors: {
    borderTop: '1px solid #FE630C !important',
    borderRight: '1px solid #DADADC',
    borderBottom: '1px solid #FE630C !important',
    borderLeft: '1px solid #DADADC',
    borderCollapse: 'collapse',
  },
  firstCellWithErrors: {
    borderTop: '1px solid #FE630C',
    borderRight: '1px solid #DADADC',
    borderBottom: '1px solid #FE630C',
    borderLeft: '1px solid #FE630C',
    borderCollapse: 'collapse',
  },
  lastCellWithErrors: {
    borderTop: '1px solid #FE630C',
    borderRight: '1px solid #FE630C',
    borderBottom: '1px solid #FE630C',
    borderLeft: '1px solid #DADADC',
    borderCollapse: 'collapse',
  },
  cell: {
    position: 'relative',
  },
});

export type TimeEntry = {
  key: string;
  // original key is used to keep track of the key before it is updated by the server
  // that way we don't lose focus when the server updates the key
  date: number;
  time: number | null;
  type: TimesheetRecordType | null | undefined;
  task: string;
  role?: MissionRole;
  initiativeIds: TimesheetInitiativeId[];
  hasErrors?: boolean;
  // isLocal determines if a row is not being persisted in the database yet
  isLocal?: boolean;
};

const columns: ColumnDef<TimeEntry>[] = [
  {
    accessorKey: 'date',
    footer: 'Total',
    size: 140,
    maxSize: 140,
    minSize: 140,
    cell: (info) => {
      const rowIndex = info.table.getRowModel().rows.indexOf(info.row);
      const isFirstEntryOfDay =
        info.table.getRowModel().rows.length === 0 ||
        info.table.getRowModel().rows[rowIndex - 1]?.original.date !==
          info.row.original.date;

      const date = formatDate(info.row.original.date);

      if (isFirstEntryOfDay) {
        const timeEntry = info.table.options.meta?.timeEntriesMap?.get(
          info.row.original.date,
        );

        return (
          <div>
            <div>{date}</div>
            {timeEntry && timeEntry.totalRecordsAssociated > 1 && (
              <div style={{ marginTop: 4, color: '#818388' }}>
                {timeEntry.totalFormattedTime}
              </div>
            )}
          </div>
        );
      }

      return <div />;
    },
  },
  {
    accessorKey: 'time',
    footer: (info) => {
      if (info.table.options.meta?.isFullTimeRetainer) {
        return `${info.table.options.meta?.fixedHoursAmount}h`;
      } else {
        const total = info.table
          .getRowModel()
          .rows.reduce((acc, row) => acc + Number(row.original.time), 0);

        return minutesToTime(total);
      }
    },
    size: 100,
    maxSize: 100,
    minSize: 100,
    cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
      const cellValue = getValue() as number;

      const onUpdateMutation = () => {
        table.options.meta?.onUpdateMutation({
          key: rowKey,
        });
      };

      const onChange = (value: number) => {
        table.options.meta?.updateData(rowKey, id, value);
      };

      if (
        checkIfRowIsOOO(table, rowKey) ||
        table.options.meta?.isFullTimeRetainer
      ) {
        return <EmptyCell padding={16} />;
      }

      return (
        <TimeInput
          onUpdateMutation={onUpdateMutation}
          onChange={onChange}
          initialValue={cellValue}
        />
      );
    },
  },
  {
    accessorKey: 'type',
    footer: ({ table }) => {
      if (table.options.meta?.isFullTimeRetainer) {
        return table.options.meta?.fixedPaymentAmount;
      } else {
        const hourlyRate = table.options.meta?.hourlyRate;

        const totalTime = table
          .getRowModel()
          .rows.reduce((acc, row) => acc + Number(row.original.time), 0);

        if (!hourlyRate) {
          return null;
        }

        const total = Math.round(hourlyRate * (totalTime / 60) * 100) / 100;

        return (
          <span data-testing-id="timesheets-footer-table-total">
            ${total.toLocaleString()}
          </span>
        );
      }
    },
    size: 200,
    maxSize: 200,
    minSize: 200,
    cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
      const cellValue = getValue() as TimesheetRecordType;

      const onChange = (value: TimesheetRecordType | undefined) => {
        table.options.meta?.updateData(rowKey, id, value);
      };

      const onUpdateMutation = (newType: TimesheetRecordType) => {
        table.options.meta?.onUpdateMutation({
          key: rowKey,
          type: newType,
        });
      };

      return (
        <TypeSelect
          onUpdateMutation={onUpdateMutation}
          onChange={onChange}
          cellValue={cellValue}
        />
      );
    },
  },
  {
    accessorKey: 'task',
    size: 450,
    maxSize: 450,
    minSize: 450,
    cell: ({ getValue, row, column: { id }, table }) => {
      const cellValue = getValue() as string;

      const onUpdateMutation = () => {
        table.options.meta?.onUpdateMutation({
          key: row.id,
        });
      };

      const onChange = (value: string) => {
        table.options.meta?.updateData(row.id, id, value);
      };

      if (checkIfRowIsOOO(table, row.id)) {
        return <EmptyCell padding={16} />;
      }

      return (
        <TextInput
          onChange={onChange}
          placeholder="Enter task"
          onUpdateMutation={onUpdateMutation}
          cellValue={cellValue}
          minChars={TIMESHEET_TASK_MIN_LENGTH}
          needsValidation={!!row.original.hasErrors}
        />
      );
    },
  },
  {
    accessorKey: 'initiativeIds',
    size: 300,
    maxSize: 300,
    minSize: 300,
    cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
      const cellValue = getValue() as TimesheetInitiativeId[];

      const onUpdateMutation = (initiativeIds: TimesheetInitiativeId[]) => {
        table.options.meta?.onUpdateMutation({
          key: rowKey,
          initiativeIds: initiativeIds,
        });
      };

      const onChange = (value: TimesheetInitiativeId[]) => {
        table.options.meta?.updateData(rowKey, id, value);
      };

      if (checkIfRowIsOOO(table, rowKey)) {
        return <EmptyCell padding={16} />;
      }

      return (
        <InitiativeSelect
          sid={table.options.meta?.sid ?? ''}
          onUpdateMutation={onUpdateMutation}
          cellValue={cellValue}
          onChange={onChange}
        />
      );
    },
  },
  {
    accessorKey: 'actions',
    size: 48,
    maxSize: 48,
    minSize: 48,
    enableHiding: false,
    cell: ({ row: { id: rowKey }, table }) => {
      const handleRemoveRow = () => {
        table.options.meta?.removeRow(rowKey);
      };

      return <ClearRowDropdown onClearRow={handleRemoveRow} />;
    },
  },
];

interface TanstackTableProps {
  records?: RoleRecord[];
  currentMission?: Mission;
  sid: string;
  adminView: boolean;
  submitAfterLoading?: boolean;
  setPaymentCycleToSubmit?: (id?: MissionPaymentCycleId) => void;
  onPaymentNotificationClick: () => void;
  updateSelectedCycle?: (id: MissionPaymentCycleId) => void;
  isLoading?: boolean;
}

function TanstackTable({
  records,
  currentMission,
  sid,
  adminView,
  submitAfterLoading,
  setPaymentCycleToSubmit,
  onPaymentNotificationClick,
  updateSelectedCycle,
  isLoading,
}: TanstackTableProps) {
  const { missions } = useStores();
  const commonStyles = useCommonStyles({ cellPadding: 0 });

  const styles = useStyles();

  const [data, setData] = useState<TimeEntry[]>([]);
  const timeEntriesMap = useGetGroupedTimeByDay(data);

  const [error, setError] = useState('');
  const { isFullTimeRetainer, billingPeriod, roleStartDate, roleEndDate } =
    useGetMonthlyRetainerDataForCurrentUserRole();

  const { startDate: paymentCycleStartDate, endDate: paymentCycleEndDate } =
    missions.currentMission?.selectedPaymentCycle?.data ?? {};
  const { fixedPaymentAmount, fixedHoursAmount } =
    useGetPaymentAndHoursForGivenPeriod(
      paymentCycleStartDate,
      paymentCycleEndDate,
    );

  useEffect(() => {
    setData(() => {
      return (
        records?.map((record) => {
          const entry: TimeEntry = {
            key: record.key,
            date: new Date(record.date).getTime(),
            time: record.minutes,
            type: record.type || null,
            task: record.details,
            hasErrors: false,
            initiativeIds: record.initiativeIds ?? [],
          };

          return entry;
        }) ?? []
      );
    });
  }, [records]);

  const { mutate: deleteRecord } = useMutationDeleteRecord();
  const { mutate: setRecord } = useMutationSetRecord();

  const dataGridNav = new DataGridNav();

  const listeners = {
    onKeyDown: (e: React.KeyboardEvent<HTMLTableElement>) => {
      const target = e.target as HTMLElement;
      if (
        (target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA') &&
        (e.key === 'ArrowUp' ||
          e.key === 'ArrowRight' ||
          e.key === 'ArrowDown' ||
          e.key === 'ArrowLeft')
      ) {
        return;
      }

      dataGridNav.tableKeyDown(e as unknown as KeyboardEvent);
    },
    onKeyUp: () => dataGridNav.tableKeyUp(),
  };

  const table = useReactTable({
    data,
    columns,
    getRowId: (row) => row.key,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      onUpdateMutation: ({
        key,
        type,
        initiativeIds,
      }: {
        key: string;
        type?: TimesheetRecordType;
        initiativeIds?: TimesheetInitiativeId[];
      }) => {
        const row = data.find((row) => row.key === key);
        if (!row) {
          return;
        }

        let isOutOfOfficeType = false;

        if (type) {
          isOutOfOfficeType = outOfOfficeTypes.has(type);
        }

        setRecord(
          {
            data: {
              date: new Date(row.date).toISOString(),
              minutes: isOutOfOfficeType ? 0 : row.time || 0,
              details: isOutOfOfficeType ? '' : row.task,
              type: type !== undefined ? type : row.type || undefined,
              initiativeIds:
                initiativeIds !== undefined
                  ? initiativeIds
                  : row.initiativeIds || undefined,
            },
            key: row.key,
            sid,
          },
          {
            onSuccess: (data) => {
              setData((old) => {
                const newData = old.map((row) => {
                  if (row.key === key) {
                    const updatedRow = {
                      ...row,
                      key: data.key,
                      date: new Date(data.date).getTime(),
                      initiativeIds: data.initiativeIds ?? [],
                      task: data.details,
                      time: data.minutes,
                      type: data.type || null,
                      isLocal: false,
                    };

                    let hasErrors = updatedRow.hasErrors;

                    if (hasErrors) {
                      if (checkIfRowIsValid(row, isFullTimeRetainer)) {
                        hasErrors = false;
                      }
                    }

                    return {
                      ...updatedRow,
                      hasErrors,
                    };
                  }
                  return row;
                });

                return newData;
              });

              updateSelectedCycle &&
                currentMission?.selectedPaymentCycle?.data.yid &&
                updateSelectedCycle(
                  currentMission.selectedPaymentCycle.data.yid,
                );
            },
          },
        );
      },
      updateData: (rowKey: string, columnId: string, value: unknown) => {
        setData((old) => {
          return old.map((row: TimeEntry) => {
            if (row.key === rowKey) {
              return {
                ...row,
                [columnId]: value,
              };
            }

            return row;
          });
        });
      },

      removeRow: (rowKey: string) => {
        setData((old) => {
          return old.filter((row) => row.key !== rowKey);
        });

        const row = data.find((row) => row.key === rowKey);

        if (row?.isLocal) {
          return;
        }

        if (row) {
          deleteRecord(
            {
              sid,
              key: row?.key,
            },
            {
              onSuccess: () => {
                updateSelectedCycle &&
                  currentMission?.selectedPaymentCycle?.data.yid &&
                  updateSelectedCycle(
                    currentMission.selectedPaymentCycle.data.yid,
                  );
              },
            },
          );
        }
      },
      hourlyRate: currentMission?.currentUserRole?.hourlyRate,
      sid,
      isFullTimeRetainer,
      fixedHoursAmount,
      fixedPaymentAmount,
      timeEntriesMap,
    },
  });

  const checkForErrors = () => {
    setError('');
    let tableHasAnError = false;
    const filledDaysForMonthlyRetainer = new Set();
    const newData = data.map((row) => {
      if (row.type && row.task && row.initiativeIds.length > 0) {
        filledDaysForMonthlyRetainer.add(row.date);
      }

      if (checkIfRowIsValid(row, isFullTimeRetainer)) {
        const { hasErrors, ...newRow } = row;

        return newRow;
      }

      tableHasAnError = true;

      // otherwise it has errors
      return { ...row, hasErrors: true };
    });
    setData(newData);

    if (tableHasAnError) {
      setError(
        'Please fill out the missing fields in highlighted rows. All fields are required in filled rows.',
      );
      return tableHasAnError;
    }

    if (isFullTimeRetainer) {
      const hasFullTimeRetainerError = checkErrorsForFullTimeRetainer(
        filledDaysForMonthlyRetainer.size,
      );
      if (hasFullTimeRetainerError) {
        return true;
      }
    }

    return false;
  };

  const checkErrorsForFullTimeRetainer = (
    totalFilledDaysForMonthlyRetainer: number,
  ) => {
    let requiredFilledDaysForMonthlyRetainer = 1;
    if (paymentCycleStartDate && paymentCycleEndDate) {
      // This condition checks if the role is active during the whole payment cycle.
      // It ensures that either:
      // - The role has no start date (i.e., it has always been active), or
      //   the role's start date is on or before the payment cycle's start date.
      // AND
      // - The role has no end date (i.e., it is still active), or
      //   the payment cycle's end date is on or after the role's end date.
      // If both conditions are met, the role is considered active during the whole payment cycle.
      if (
        (!roleStartDate || roleStartDate <= paymentCycleStartDate) &&
        (!roleEndDate || paymentCycleEndDate >= roleEndDate)
      ) {
        switch (billingPeriod) {
          case MissionBillingPeriod.Weekly:
            requiredFilledDaysForMonthlyRetainer =
              REQUIRED_FILLED_DAYS_WEEKLY_PERIOD;
            break;
          case MissionBillingPeriod.BiWeekly:
            requiredFilledDaysForMonthlyRetainer =
              REQUIRED_FILLED_DAYS_BI_WEEKLY_PERIOD;
            break;
          case MissionBillingPeriod.Monthly:
            requiredFilledDaysForMonthlyRetainer =
              REQUIRED_FILLED_DAYS_MONTHLY_PERIOD;
            break;
        }
      }
    }

    if (
      totalFilledDaysForMonthlyRetainer < requiredFilledDaysForMonthlyRetainer
    ) {
      setError(
        `Please fill out at least ${requiredFilledDaysForMonthlyRetainer} days.`,
      );
      return true;
    }

    return false;
  };

  const isActiveRole =
    currentMission?.currentUserRole?.status === MissionRoleStatus.Active ||
    currentMission?.currentUserRole?.status ===
      MissionRoleStatus.ScheduledToEnd;

  useEffect(() => {
    if (
      submitAfterLoading &&
      setPaymentCycleToSubmit &&
      data.length > 0 &&
      checkForErrors &&
      onPaymentNotificationClick
    ) {
      setPaymentCycleToSubmit(undefined);
      if (!checkForErrors()) {
        onPaymentNotificationClick();
      }
    }
  }, [
    submitAfterLoading,
    setPaymentCycleToSubmit,
    data.length,
    checkForErrors,
    setPaymentCycleToSubmit,
  ]);

  return (
    <div>
      <SectionHeader sid={sid} />

      {!adminView &&
        !currentMission?.isMissionManager &&
        currentMission?.selectedPaymentCycle?.paymentNotificationShown &&
        !currentMission?.isPaymentSetupNoticeShown &&
        isActiveRole && (
          <GetPaidBanner
            onPaymentNotificationClick={() => {
              if (!checkForErrors()) {
                onPaymentNotificationClick();
              }
            }}
          />
        )}

      <AddRecord sid={sid} updateSelectedCycle={updateSelectedCycle} />

      {!!error && <ErrorBar error={error} />}

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <div className={commonStyles.container}>
          <table {...listeners} data-testing-id="timesheets-table">
            <tbody>
              {table.getRowModel().rows.map((row, rowIndex) => {
                let isOutOfOfficeType = false;

                if (row.original.type) {
                  isOutOfOfficeType = outOfOfficeTypes.has(row.original.type);
                }

                const hasErrors = row.original.hasErrors;
                return (
                  <tr
                    className={cx({
                      [commonStyles.disabledBg]: isOutOfOfficeType,
                    })}
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const isDateCell = cell.column.id === 'date';
                      const isTimeCell = cell.column.id === 'time';
                      const isTypeCell = cell.column.id === 'type';
                      const isInitiativesCell =
                        cell.column.id === 'initiativeIds';
                      const isActionsCell = cell.column.id === 'actions';

                      // Check if it's not the first entry of the day
                      const isSubsequentEntryOfDay =
                        rowIndex > 0 &&
                        table.getRowModel().rows[rowIndex - 1].original.date ===
                          row.original.date;

                      // Choose the appropriate class name
                      const cellClassName =
                        isDateCell && isSubsequentEntryOfDay
                          ? commonStyles.emptyCell
                          : isDateCell
                          ? commonStyles.dateCell
                          : commonStyles.cell;

                      return (
                        <td
                          tabIndex={!isDateCell ? -1 : undefined}
                          key={cell.id}
                          className={cx(styles.cell, {
                            [commonStyles.dateColumn]: isDateCell,
                            [commonStyles.timeColumn]: isTimeCell,
                            [commonStyles.typeColumn]: isTypeCell,
                            [commonStyles.initiativesColumn]: isInitiativesCell,
                            [commonStyles.actionsColumn]: isActionsCell,
                            [cellClassName]: !hasErrors,
                            [styles.cellWithErrors]: hasErrors,
                            [styles.firstCellWithErrors]:
                              hasErrors && isDateCell,
                            [styles.lastCellWithErrors]:
                              hasErrors && isActionsCell,
                          })}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => {
                    if (header.column.id === 'task') {
                      return <th colSpan={3}></th>;
                    }

                    if (header.column.columnDef.footer === undefined) {
                      return null;
                    }

                    const isDateCell = header.column.id === 'date';
                    const isTimeCell = header.column.id === 'time';
                    const isTypeCell = header.column.id === 'type';
                    const isInitiativesCell =
                      header.column.id === 'initiativeIds';
                    const isActionsCell = header.column.id === 'actions';

                    return (
                      <th
                        key={header.id}
                        className={cx(styles.cell, {
                          [commonStyles.dateColumn]: isDateCell,
                          [commonStyles.timeColumn]: isTimeCell,
                          [commonStyles.typeColumn]: isTypeCell,
                          [commonStyles.initiativesColumn]: isInitiativesCell,
                          [commonStyles.actionsColumn]: isActionsCell,
                        })}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.footer,
                              header.getContext(),
                            )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
}

export default observer(TanstackTable);
