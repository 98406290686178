import { Company } from '@a_team/models/dist/Company';
import { ExperienceMemberRole } from '@a_team/models/dist/ExperienceObject';
import { UserId } from '@a_team/models/dist/UserObject';
import { DateISOString } from '@a_team/models/dist/misc';
import { BuilderSuggestionType } from '@a_team/models/dist/ProfileEnhancements';
import {
  Checkbox,
  DatePicker,
  Input,
  OnChangeVal,
  Select,
  SelectOption,
  Textarea,
  Typography,
  theme,
} from '@a_team/ui-components';
import AsyncSelect from '@a_team/ui-components/lib/Select/AsyncSelect';
import { Breakpoints, Button } from '@ateams/components';
import { CompanyPicker } from '@src/components/CompanyPicker';
import FileUploader from '@src/components/FileUploader';
import LoadingIndicator from '@src/components/LoadingIndicator';
import Modal from '@src/components/Modal';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import { ProjectMembersSelect } from '@src/components/ProjectMembersSelect';
import SuggestionsBanner from '@src/components/SuggestionsBanner';
import { getIndustrySelectOptions } from '@src/helpers/talent-industry-api';
import { isValidUrl, withHttps } from '@src/helpers/urls';
import { LoadingState } from '@src/hooks/useLoadingState';
import {
  useGetProfileSuggestions,
  usePartiallyRejectSuggestion,
} from '@src/rq/profileSuggestions';
import {
  ExistingProject,
  Expertise,
  NewProject,
} from '@src/stores/Profile/models';
import useMultipleImages from '@src/views/Profile/Main/Projects/MultipleImages/hooks/useMultipleImages';
import { isNewExperienceItem } from '@src/views/Profile/helpers/experience';
import { format, isEqual, parseISO } from 'date-fns';
import { isEqual as isEqualLodash } from 'lodash';
import React, { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  setEditModalOpen(val: boolean): void;
  editModalOpen: boolean;
  selectedProjectId: string | undefined;
  selectedProject: NewProject | ExistingProject;
  handleRemove?(project: ExistingProject | NewProject): void;
  onClose?(): void;
  roles: { value: string; label: string }[] | undefined;
  allIndustries: Expertise[] | undefined;
  experienceMemberRole: ExperienceMemberRole;
  userId: UserId;
  allSkills: Expertise[] | undefined;
  onSubmit: (project: NewProject | ExistingProject) => void;
  loading: boolean | LoadingState;
}

const useStyles = createUseStyles({
  modal: {
    maxHeight: '100% !important',
    padding: 0,
    border: 'none',
    borderRadius: '0px !important',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexBasis: 450,
    padding: 0,
    overflow: 'auto',
    order: 3,
    '& input': {
      maxHeight: 40,
    },
  },
  formInner: {
    padding: '24px 24px 0 24px',
  },
  uploader: {
    flex: '1 0 0',
    flexBasis: '50%',
    backgroundColor: theme.colors.Grey['200'],
    margin: '1, 1, 0, 0',
    zIndex: 1,
    order: 2,
  },
  selectContainer: {
    marginTop: 12,
  },
  dates: {
    display: 'flex',
    flexFlow: 'wrap',
    alignItems: 'center',
    marginTop: 16,
    position: 'relative',
  },
  calendar: {
    position: 'absolute',
    zIndex: 10,
  },
  calendarRight: {
    position: 'absolute',
    zIndex: 10,
    right: 0,
  },
  optional: {
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.Grey['500'],
  },
  clientInfo: {
    marginTop: 30,
  },
  skills: {
    marginTop: 30,
  },
  collaborators: {
    marginTop: 30,
  },
  publishButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    bottom: 0,
    background: 'white',
    padding: '10px 0',
  },
  discardProjectButton: {
    background: '#F7F7F7',
    color: '#222222',
    padding: '11px 16px',
    width: 'auto',
    height: 40,
    fontWeight: 400,
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    modal: {
      height: '600px !important',
      borderRadius: '16px !important',
    },
    main: {
      flexDirection: 'row',
    },
    form: {
      order: 1,
      flex: '1 0 0',
      flexBasis: 450,
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)`]:
    {
      modal: {
        width: '70% !important',
        maxHeight: '600px !important',
        borderRadius: '16px !important',
      },
    },
  [`@media (max-width: ${Breakpoints.sm}px)`]: {
    modal: {
      top: '0px !important',
      bottom: '0px !important',
      inset: 'unset !important',
      transform: 'unset !important',
      height: '100% !important',
      width: '100% !important',
    },
    form: {
      flexBasis: '70%',
    },
    uploader: {
      flex: '0 0 30%',
      height: '30%',
    },
  },
});

const compareDates = (first?: Date, second?: Date): boolean => {
  if (first && second) {
    return isEqual(first, second);
  }
  return first === undefined && second === undefined;
};

const EditProjectModal = (props: Props): ReactElement => {
  const {
    editModalOpen,
    setEditModalOpen,
    selectedProject,
    handleRemove,
    onClose,
    roles,
    allIndustries,
    allSkills,
    userId,
    experienceMemberRole,
    onSubmit,
    loading,
  } = props;
  const styles = useStyles({ theme: theme });

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const {
    mutateAsync: rejectSuggestedProject,
    isLoading: isRejectingSuggestion,
  } = usePartiallyRejectSuggestion();
  const { data: profileSuggestions } = useGetProfileSuggestions();

  const profileSkills = useMemo(() => {
    return allSkills?.map((skill) => {
      return { value: skill.id, label: skill.name };
    });
  }, [allSkills]);

  const [errors, setErrors] = useState<{ [key in string]: undefined | string }>(
    {
      title: undefined,
      description: undefined,
      role: undefined,
    },
  );
  const [touched, setTouched] = useState<{ [key in string]: boolean }>({
    title: false,
    description: false,
    role: false,
  });
  const [visited, setVisited] = useState<{ [key in string]: boolean }>({
    title: false,
    description: false,
    role: false,
  });
  const setInitialRole = (jobRoleId: string | undefined) => {
    return roles?.find((role) => role.value === jobRoleId);
  };
  const setInitialDate = (dateString: DateISOString | undefined) => {
    return dateString
      ? typeof dateString === 'string'
        ? parseISO(dateString)
        : undefined
      : undefined;
  };
  const setInitialSkills = (skills: string[] | undefined) => {
    if (!skills || skills.length === 0 || !profileSkills) {
      return [];
    }
    const newSkills: { value: string; label: string }[] = [];
    skills.forEach((skill) => {
      const foundSkill = profileSkills.find((pSkill) => pSkill.value === skill);
      if (foundSkill) {
        newSkills.push(foundSkill);
      }
    });
    return newSkills;
  };
  const setInitialCompany = (companyId: string | undefined): Company => {
    if (companyId && companyId !== 'undefined') {
      return { id: companyId } as Company;
    }
    return {} as Company;
  };

  const setInitialIndustry = (industryId?: string) => {
    const found = allIndustries?.find((option) => option.id === industryId);
    return found ? { value: found?.id, label: found?.name } : undefined;
  };

  const loadOptionsAsync = (a: string): Promise<Array<SelectOption>> => {
    return getIndustrySelectOptions({
      filter: { query: a },
    });
  };

  const initialValues = {
    title: selectedProject?.title,
    description: selectedProject?.description || '',
    role: setInitialRole(selectedProject.jobRoleId),
    roleDetails: selectedProject.jobRole,
    startDate: setInitialDate(selectedProject?.startDate),
    endDate: setInitialDate(selectedProject?.endDate),
    isOngoing: false,
    company: setInitialCompany(selectedProject?.companyId),
    industry: setInitialIndustry(selectedProject.industry),
    skills: setInitialSkills(selectedProject?.skills),
    memberList: selectedProject.members,
    imageURL: selectedProject?.imageURL,
    imagesUrls:
      selectedProject?.imagesUrls && selectedProject?.imagesUrls?.length > 0
        ? selectedProject.imagesUrls
        : selectedProject?.imageURL
        ? [selectedProject?.imageURL]
        : [],
    projectUrl: selectedProject?.projectUrl,
  };

  const [title, setTitle] = useState(initialValues.title);
  const [description, setDescription] = useState(initialValues.description);

  const [role, setRole] = useState<
    { value: string; label: string } | undefined
  >(initialValues.role);
  const [roleDetails, setRoleDetails] = useState(initialValues.roleDetails);
  const [startDate, setStartDate] = useState<Date | undefined>(
    initialValues.startDate,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    initialValues.endDate,
  );
  const [isOngoing, setIsOngoing] = useState(initialValues.isOngoing);
  const [company, setCompany] = useState<Company | null>(initialValues.company);
  const [industry, setIndustry] = useState(initialValues.industry);
  const [skills, setSkills] = useState(initialValues.skills);
  const [memberList, setMemberList] = useState(initialValues.memberList);
  const [projectUrl, setProjectUrl] = useState(initialValues.projectUrl);

  const {
    currentImage,
    next,
    prev,
    onRemoveImage,
    onAddImage,
    images,
    onSetCoverImage,
    getIsCoverImage,
  } = useMultipleImages({
    imageUrls:
      initialValues.imagesUrls && initialValues.imagesUrls.length > 0
        ? initialValues.imagesUrls
        : initialValues.imageURL
        ? [initialValues.imageURL]
        : [],
  });

  const isOwner = useMemo(() => {
    return (
      experienceMemberRole === ExperienceMemberRole.Owner ||
      Boolean(selectedProject?.isSuggested)
    );
  }, [experienceMemberRole]);

  const assembleNewProject = (): NewProject | ExistingProject => {
    const newRole = role?.value;
    const newSkills = skills.map((skill) => skill.value);
    const newCompany = company?.id;
    const newIndustry = industry?.value;
    return {
      ...selectedProject,
      title,
      description,
      jobRoleId: newRole,
      jobRole: roleDetails,
      startDate,
      endDate,
      skills: newSkills,
      members: memberList,
      companyId: newCompany,
      imagesUrls: images,
      projectUrl,
      industry: newIndustry,
    };
  };

  const handleSubmit = () => {
    const invalidInputExists = validate();

    if (invalidInputExists) {
      return;
    }

    const newProject = assembleNewProject();

    onSubmit(newProject);
  };

  const handleDiscard = async () => {
    if (!profileSuggestions?.projects || !props.selectedProjectId) {
      return;
    }

    await rejectSuggestedProject({
      sid: profileSuggestions.projects.sid,
      type: BuilderSuggestionType.PROJECTS,
      partialSuggestionId: props.selectedProjectId,
    });

    handleClose();
  };

  const onRoleDetailsUpdate = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setRoleDetails(e.target.value);
  };

  const addHttps = (value: string) => {
    return value !== '' ? withHttps(value) : value;
  };

  // no input means validate all
  const validate = (input?: string) => {
    let invalidInputExists = false;

    setErrors((prev) => {
      const tempErrors: { [key in string]: undefined | string } = { ...prev };
      if (!input || input === 'title') {
        const isTitleInvalid = !title || title.length === 0;
        tempErrors['title'] = isTitleInvalid ? 'Title is required' : undefined;
        invalidInputExists = isTitleInvalid ? true : invalidInputExists;
      }
      if (!input || input === 'description') {
        const isDescriptionInvalid = !description || description.length === 0;
        tempErrors['description'] = isDescriptionInvalid
          ? 'Description is required'
          : undefined;
        invalidInputExists = isDescriptionInvalid ? true : invalidInputExists;
      }
      if (!input || input === 'projectUrl') {
        const isProjectUrlInvalid = projectUrl && !isValidUrl(projectUrl);
        tempErrors['projectUrl'] = isProjectUrlInvalid
          ? 'Invalid URL'
          : undefined;
        invalidInputExists = isProjectUrlInvalid ? true : invalidInputExists;
      }
      return tempErrors;
    });
    if (!input) {
      // triggers validation for all fields
      setVisited({
        title: true,
        description: true,
        projectUrl: true,
      });
    }
    return invalidInputExists;
  };

  const isValid = useMemo(
    () =>
      Object.values(errors).find((error) => error && error.length > 0) ===
      undefined,
    [errors],
  );

  const unsavedData = useMemo(() => {
    return Object.values({
      title: initialValues.title !== title,
      description: initialValues.description !== description,
      role: initialValues.role !== role,
      roleDetails: initialValues.roleDetails !== roleDetails,
      startDate: !compareDates(initialValues.startDate, startDate),
      endDate: !compareDates(initialValues.endDate, endDate),
      isOngoing: initialValues.isOngoing !== isOngoing,
      company: initialValues.company.id !== company?.id,
      industry: initialValues?.industry?.value !== industry?.value,
      skills: !isEqualLodash(initialValues.skills, skills),
      memberList: initialValues.memberList !== memberList,
      imagesUrls: !isEqualLodash(initialValues.imagesUrls, images),
      projectUrl: initialValues.projectUrl !== projectUrl,
    });
  }, [
    title,
    description,
    role,
    roleDetails,
    startDate,
    endDate,
    isOngoing,
    company,
    industry,
    skills,
    memberList,
    images,
    projectUrl,
  ]);

  const hasUnsavedData = (): boolean => {
    return unsavedData.includes(true);
  };

  const handleClose = () => {
    const newProject = assembleNewProject();

    if (newProject.title.length < 1 && isNewExperienceItem(newProject)) {
      // If new project is clicked and no data is entered, remove it from the projects array
      handleRemove && handleRemove(newProject);
    }

    onClose && onClose();
    setEditModalOpen(false);
  };

  const handleSkillsChange = (options: unknown) => {
    setSkills(
      (options as SelectOption[]).filter(
        (option) => option.value && option.label,
      ),
    );
  };

  const handleModalClose = () => {
    if (hasUnsavedData()) {
      setShowConfirmModal(true);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={editModalOpen}
        onClose={handleModalClose}
        className={styles.modal}
        style={{
          width: 950,
          padding: 0,
        }}
        closeButtonStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          borderRadius: '100%',
          width: 32,
          height: 32,
          lineHeight: '12px',
        }}
      >
        <div
          className={styles.main}
          data-testing-id={'profile-project-edit-modal'}
        >
          <div className={styles.form}>
            <div className={styles.formInner}>
              {selectedProject?.isSuggested && (
                <SuggestionsBanner
                  title="Editing suggested project"
                  description="This project is suggested based on your LinkedIn profile."
                />
              )}
              <Typography
                variant={'h2'}
                margin={'xxs'}
                style={{ marginBottom: 4 }}
              >
                {`${
                  !selectedProject?.isSuggested &&
                  isNewExperienceItem(selectedProject)
                    ? 'Add New'
                    : 'Edit'
                }`}{' '}
                Project
              </Typography>
              <Typography variant={'textMedium'} weight={'light'}>
                Please describe the project in as much detail as possible.
              </Typography>
              <Typography
                variant={'h3'}
                margin={'xs'}
                style={{ marginTop: 20 }}
              >
                Project Details
              </Typography>
              <Input
                disabled={!isOwner}
                placeholder={'Project title...'}
                style={{ borderColor: theme.colors.Grey['400'] }}
                size="stretch"
                value={title}
                error={
                  errors.title
                    ? visited.title
                      ? true
                      : undefined
                    : touched.title
                    ? false
                    : undefined
                }
                errorText={errors.title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  validate('title');
                }}
                onBlur={() => {
                  setVisited({ ...visited, title: true });
                  validate('title');
                }}
                onFocus={() => setTouched({ ...touched, title: true })}
                data-testing-id={'profile-project-card-title-text-input'}
              />
              <Textarea
                style={{
                  marginTop: 12,
                  borderColor: theme.colors.Grey['400'],
                  minHeight: '150px',
                }}
                placeholder="Project description..."
                size="stretch"
                value={description}
                error={
                  errors.description
                    ? visited.description
                      ? true
                      : undefined
                    : touched.description
                    ? false
                    : undefined
                }
                errorText={errors.description}
                height={'150px'}
                onChange={(e) => {
                  setDescription(e.target.value);
                  validate('description');
                }}
                onBlur={() => {
                  setVisited({ ...visited, description: true });
                  validate('description');
                }}
                onFocus={() => setTouched({ ...touched, description: true })}
                data-testing-id={'profile-project-card-description-text-input'}
              />
              <div className={styles.selectContainer}>
                <Select
                  value={role}
                  placeholder={`Select role`}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: theme.colors.Grey['400'],
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 20000,
                      color: theme.colors.Grey['800'],
                    }),
                  }}
                  menuPortalTarget={document.body}
                  // eslint-disable-next-line
                  onChange={(role: any) => setRole(role)}
                  options={roles}
                />
              </div>
              <Input
                placeholder={'Role details...'}
                value={roleDetails}
                size={'stretch'}
                style={{
                  marginTop: 6,
                  borderColor: theme.colors.Grey['400'],
                }}
                maxLength={200}
                onChange={onRoleDetailsUpdate}
                data-testing-id={'profile-project-card-role-text-input'}
              />
              <div className={styles.dates}>
                <Typography
                  variant={'textMedium'}
                  weight={'light'}
                  style={{ marginRight: 8 }}
                >
                  From
                </Typography>
                <DatePicker
                  defaultDate={startDate}
                  placeholder={'Start date'}
                  style={{
                    maxWidth: '171px',
                    borderColor: theme.colors.Grey['400'],
                  }}
                  onChange={setStartDate}
                  calendarOptions={{
                    maxDetail: 'year',
                    className: styles.calendar,
                  }}
                  dateFormatter={(date) => format(date, 'MMM, yyyy')}
                />
                <Typography
                  variant={'textMedium'}
                  weight={'light'}
                  style={{ marginLeft: 8, marginRight: 8 }}
                >
                  to
                </Typography>
                <DatePicker
                  defaultDate={endDate}
                  disabled={isOngoing}
                  placeholder={'End date'}
                  style={{
                    maxWidth: '171px',
                    position: 'relative',
                    borderColor: theme.colors.Grey['400'],
                  }}
                  onChange={setEndDate}
                  calendarOptions={{
                    maxDetail: 'year',
                    className: styles.calendarRight,
                  }}
                  dateFormatter={(date) => format(date, 'MMM, yyyy')}
                />
              </div>
              <Checkbox
                onChange={(e) => {
                  setIsOngoing(e.target.checked);
                }}
                style={{ marginTop: 13 }}
                checked={isOngoing}
                label={
                  <Typography
                    variant={'textMedium'}
                    weight={'light'}
                    style={{ marginLeft: 8 }}
                  >
                    I'm still working on this project
                  </Typography>
                }
              />
              <Input
                disabled={!isOwner}
                style={{ marginTop: 12, borderColor: theme.colors.Grey['400'] }}
                placeholder={'Project URL...'}
                size="stretch"
                value={projectUrl}
                error={
                  errors.projectUrl
                    ? visited.projectUrl
                      ? true
                      : undefined
                    : touched.projectUrl
                    ? false
                    : undefined
                }
                errorText={errors.projectUrl}
                onBlur={(e) => {
                  setVisited({ ...visited, projectUrl: true });
                  validate('projectUrl');
                }}
                onChange={(e) => {
                  setProjectUrl(addHttps(e.target.value));
                  validate('projectUrl');
                }}
                onFocus={() => setTouched({ ...touched, projectUrl: true })}
                data-testing-id={'profile-project-card-url-text-input'}
              />
              <div className={styles.clientInfo}>
                <Typography
                  variant={'h3'}
                  margin={'xs'}
                  style={{ marginTop: 20 }}
                >
                  Client Information
                </Typography>
                <CompanyPicker
                  onSelectCompanies={(companies) =>
                    setCompany(companies[0] || null)
                  }
                  canCreateNewCompanies={true}
                  initiallySelectedCompanyIds={company?.id ? [company.id] : []}
                  error=""
                  disabled={!isOwner}
                />
                <div className={styles.selectContainer}>
                  <AsyncSelect
                    alwaysShowCounter={false}
                    isMulti={false}
                    value={industry}
                    isDisabled={!isOwner}
                    loadOptions={loadOptionsAsync}
                    placeholder={'Select industry'}
                    onChange={(val: OnChangeVal) => {
                      if (Array.isArray(val)) return;
                      val &&
                        setIndustry({ value: val?.value, label: val?.label });
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: theme.colors.Grey['400'],
                      }),
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 20000,
                        color: theme.colors.Grey['800'],
                      }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </div>
              <div className={styles.skills}>
                <Typography
                  variant={'h3'}
                  margin={'xs'}
                  style={{ marginTop: 20 }}
                >
                  Skills Used{' '}
                  <span className={styles.optional}>(Optional)</span>
                </Typography>
                <Select
                  alwaysShowCounter={false}
                  isMulti={true}
                  value={skills}
                  placeholder={
                    skills.length > 0
                      ? skills.map((skill) => skill.label).join(', ')
                      : `Add skills...`
                  }
                  onChange={handleSkillsChange}
                  options={profileSkills}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: theme.colors.Grey['400'],
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 20000,
                      color: theme.colors.Grey['800'],
                    }),
                  }}
                  menuPortalTarget={document.body}
                />
              </div>
              <div className={styles.collaborators}>
                <Typography
                  variant={'h3'}
                  margin={'xs'}
                  style={{ marginTop: 20, marginBottom: 12 }}
                >
                  Collaborators{' '}
                  <span className={styles.optional}>(Optional)</span>
                </Typography>
                <ProjectMembersSelect
                  memberList={memberList}
                  setMemberList={setMemberList}
                  currentUserId={userId}
                  roles={roles}
                  disabled={!isOwner}
                />
              </div>
              <div className={styles.publishButtonContainer}>
                {selectedProject?.isSuggested && (
                  <Button
                    className={styles.discardProjectButton}
                    onClick={handleDiscard}
                    loading={isRejectingSuggestion}
                  >
                    Discard project
                  </Button>
                )}
                <Button
                  onClick={handleSubmit}
                  disabled={
                    !isValid ||
                    (!hasUnsavedData() && !selectedProject.isSuggested)
                  }
                  style={{
                    marginLeft: 'auto',
                    width: 120,
                    height: 40,
                    padding: 10,
                  }}
                  data-testing-id={'profile-project-edit-modal-submit-button'}
                >
                  Publish
                </Button>
              </div>
            </div>
          </div>

          {isOwner && (
            <div className={styles.uploader}>
              <FileUploader
                onFileUploadSuccess={onAddImage}
                cropAspect={4 / 3}
                withOptionBox={false}
                withCrop={true}
                circularCrop={false}
                buttonsPosition={'bottom'}
                type={'cover'}
                backgroundSize={'contain'}
                downscale={{ width: 500, height: 600 }}
                currentImage={currentImage}
                next={next}
                prev={prev}
                onRemoveImage={onRemoveImage}
                onAddImage={onAddImage}
                images={images}
                hints={[
                  'An image 1600x1200 or larger recommended, up to 10Mb.',
                ]}
                onSetCoverImage={onSetCoverImage}
                getIsCoverImage={getIsCoverImage}
              />
            </div>
          )}
        </div>
      </Modal>
      <LoadingIndicator loading={loading ? true : null} />

      <ConfirmModal
        title={'Leave without saving?'}
        actionLabel={'Confirm'}
        description={
          'You have unsaved changes. Are you sure you want to close the modal without saving?'
        }
        onConfirm={handleClose}
        onClose={() => setShowConfirmModal(false)}
        open={showConfirmModal}
      />
    </>
  );
};

export default EditProjectModal;
