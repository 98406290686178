import React, { useEffect, useState } from 'react';
import { Spacing } from '@ateams/components';
import { Card } from '../../components/card';
import { VettingFormVariant } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingFeedbackFormDefaults } from '@ateams/api/dist/endpoints/vetting-process-feedback';
import { Separator } from '../../components/separator';
import { CompanySizesInput, OnCompanySizesChange } from './company-sizes';
import {
  AcquiredOrProgressedInput,
  OnAcquiredOrProgressedChange,
} from './acquired-or-progressed';
import { OnScaledProductsChange, ScaledProductsInput } from './scaled-products';
import {
  ManagementExperienceInput,
  OnManagementExperienceChange,
} from './management-experience';
import {
  BuiltProductsFromScratchInput,
  OnBuiltProductsFromScratchChange,
} from './products-from-scratch';
import {
  OnTechnicalBackgroundChange,
  TechnicalBackgroundInput,
} from './technical-background';
import { OnTeamSizeChange, TeamSizeInput } from './team-size';

export type VettingFeedbackFormExperience =
  VettingFeedbackFormDefaults['answers']['experience'];

export type OnVettingFeedbackFormExperienceChange = (
  data: VettingFeedbackFormExperience,
) => void;

export interface VettingFeedbackFormExperienceProps {
  vettingFormVariant: VettingFormVariant;
  defaultValues: VettingFeedbackFormExperience;
  onChange?: OnVettingFeedbackFormExperienceChange;
  isReadOnly?: boolean;
  className?: string;
}

export const VettingFeedbackFormExperienceForm: React.FC<VettingFeedbackFormExperienceProps> =
  React.memo((props) => {
    const { isReadOnly } = props;
    const [companySizes, setCompanySizes] = useState(
      props.defaultValues.companySizes,
    );
    const [acquiredOrProgressed, setAcquiredOrProgressed] = useState(
      props.defaultValues.acquiredOrProgressed,
    );
    const [builtProductsFromScratch, setBuiltProductsFromScratch] = useState(
      props.defaultValues.builtProductsFromScratch,
    );
    const [workedOnProductThatScaled, setWorkedOnProductThatScaled] = useState(
      props.defaultValues.workedOnProductThatScaled,
    );
    const [peopleManaged, setPeopleManaged] = useState(
      props.defaultValues.peopleManaged,
    );
    const [teamSize, setTeamSize] = useState(props.defaultValues.teamSize);
    const [technicalBackground, setTechnicalBackground] = useState(
      props.defaultValues.technicalBackground,
    );

    const onChange = (data: Partial<VettingFeedbackFormExperience>) => {
      props.onChange?.({
        companySizes: data.companySizes || companySizes,
        acquiredOrProgressed: data.acquiredOrProgressed || acquiredOrProgressed,
        builtProductsFromScratch:
          data.builtProductsFromScratch || builtProductsFromScratch,
        workedOnProductThatScaled:
          data.workedOnProductThatScaled || workedOnProductThatScaled,
        peopleManaged: data.peopleManaged || peopleManaged,
        teamSize: 'teamSize' in data ? data.teamSize : teamSize,
        technicalBackground:
          'technicalBackground' in data
            ? data.technicalBackground
            : technicalBackground,
      });
    };

    const onCompanySizesChange: OnCompanySizesChange = (companySizes) => {
      setCompanySizes(companySizes);
      onChange({ companySizes });
    };

    const onAcquiredOrProgressedChange: OnAcquiredOrProgressedChange = (
      acquiredOrProgressed,
    ) => {
      setAcquiredOrProgressed(acquiredOrProgressed);
      onChange({ acquiredOrProgressed });
    };

    const onBuiltProductsFromScratchChange: OnBuiltProductsFromScratchChange = (
      builtProductsFromScratch,
    ) => {
      setBuiltProductsFromScratch(builtProductsFromScratch);
      onChange({ builtProductsFromScratch });
    };

    const onWorkedOnProductThatScaledChange: OnScaledProductsChange = (
      workedOnProductThatScaled,
    ) => {
      setWorkedOnProductThatScaled(workedOnProductThatScaled);
      onChange({ workedOnProductThatScaled });
    };

    const onPeopleManagedChange: OnManagementExperienceChange = (
      managementExperience,
    ) => {
      setPeopleManaged(managementExperience);
      onChange({ peopleManaged: managementExperience });
    };

    const onTeamSizeChange: OnTeamSizeChange = (teamSize) => {
      setTeamSize(teamSize);
      onChange({ teamSize });
    };

    const onTechnicalBackgroundChange: OnTechnicalBackgroundChange = (
      technicalBackground,
    ) => {
      setTechnicalBackground(technicalBackground);
      onChange({ technicalBackground });
    };

    const showManagementExperienceQuestion =
      props.vettingFormVariant === VettingFormVariant.SoftwareEngineering;
    const showTeamSizeQuestion = [
      VettingFormVariant.ProductManagement,
      VettingFormVariant.ProjectManagement,
    ].includes(props.vettingFormVariant);
    const showTechnicalBackgroundQuestion = [
      VettingFormVariant.ProductManagement,
      VettingFormVariant.ProjectManagement,
    ].includes(props.vettingFormVariant);

    /**
     * We want to clear questions that are not shown if we change the form variant
     */
    useEffect(() => {
      if (!showManagementExperienceQuestion) {
        setPeopleManaged(undefined);
      }
      if (!showTeamSizeQuestion) {
        setTeamSize(undefined);
      }
      if (!showTechnicalBackgroundQuestion) {
        setTechnicalBackground(undefined);
      }
    }, [props.vettingFormVariant]);

    return (
      <Card title={'Experience'} className={props.className}>
        <CompanySizesInput
          value={companySizes}
          onChange={onCompanySizesChange}
          isReadOnly={isReadOnly}
        />

        <Separator direction={'horizontal'} space={Spacing.medium} />

        <AcquiredOrProgressedInput
          value={acquiredOrProgressed}
          onChange={onAcquiredOrProgressedChange}
          isReadOnly={isReadOnly}
        />

        <Separator direction={'horizontal'} space={Spacing.medium} />

        <BuiltProductsFromScratchInput
          value={builtProductsFromScratch}
          onChange={onBuiltProductsFromScratchChange}
          isReadOnly={isReadOnly}
        />

        <Separator direction={'horizontal'} space={Spacing.medium} />

        <ScaledProductsInput
          value={workedOnProductThatScaled}
          onChange={onWorkedOnProductThatScaledChange}
          isReadOnly={isReadOnly}
        />

        {showManagementExperienceQuestion && (
          <>
            <Separator direction={'horizontal'} space={Spacing.medium} />

            <ManagementExperienceInput
              value={peopleManaged}
              onChange={onPeopleManagedChange}
              isReadOnly={isReadOnly}
            />
          </>
        )}

        {showTeamSizeQuestion && (
          <>
            <Separator direction={'horizontal'} space={Spacing.medium} />

            <TeamSizeInput
              value={teamSize}
              onChange={onTeamSizeChange}
              isReadOnly={isReadOnly}
            />
          </>
        )}

        {showTechnicalBackgroundQuestion && (
          <>
            <Separator direction={'horizontal'} space={Spacing.medium} />

            <TechnicalBackgroundInput
              value={technicalBackground}
              onChange={onTechnicalBackgroundChange}
              isReadOnly={isReadOnly}
            />
          </>
        )}
      </Card>
    );
  });
