import { Colors, TextColors } from '@ateams/components';
import useDebounceState from '@src/hooks/useDebounceState';
import React, { useRef, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface TextInputProps {
  cellValue: string;
  onUpdateMutation?: () => void;
  placeholder?: string;
  onChange?: (value: string) => void;
  minChars?: number;
  needsValidation?: boolean;
  showOutlineError?: boolean;
}

const useStyles = createUseStyles({
  input: {
    border: 'none',
    backgroundColor: Colors.light,
    fontSize: 'inherit',
    padding: 16,
    color: Colors.dark,
    borderRadius: 8,
    position: 'absolute',
    zIndex: 1,
    top: 2,
    left: 2,
    right: 2,
    bottom: 2,
    height: 'calc(100% - 2px)',
    resize: 'none',
    overflow: 'hidden',
  },
  minCharError: {
    fontSize: 14,
    lineHeight: '19px',
    position: 'absolute',
    top: 20,
    right: 16,
    zIndex: 9999,
  },
});

function TextInput({
  cellValue,
  onUpdateMutation,
  placeholder,
  onChange,
  minChars,
  needsValidation,
  showOutlineError,
}: TextInputProps) {
  const styles = useStyles();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useDebounceState<string>(
    cellValue,
    () => onUpdateMutation && onUpdateMutation(),
    400,
  );
  const [isFocused, setIsFocused] = useState(false);

  const displayHint = useMemo(() => {
    return isFocused && !!minChars && value.length < minChars;
  }, [isFocused, value, minChars]);

  const displayError = useMemo(() => {
    const displayError =
      !!needsValidation && !!minChars && value.length < minChars;

    if (displayError) {
      textareaRef?.current?.focus();
    }
    return displayError;
  }, [needsValidation, value, minChars]);

  useEffect(() => {
    if (
      value !== cellValue &&
      textareaRef.current &&
      textareaRef.current !== document.activeElement
    ) {
      setValue(cellValue);
    }
  }, [cellValue]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = event.target.value;
    setValue(val);
    onChange && onChange(val);
    adjustTextareaHeight();
  };

  const handleFocus = () => {
    adjustTextareaHeight();
    setIsFocused(true);
    if (textareaRef.current) {
      textareaRef.current.style.zIndex = '999';
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'calc(100% - 2px)';
      textareaRef.current.style.zIndex = '1';
    }
  };

  return (
    <div>
      {(displayHint || displayError) && (
        <div
          className={styles.minCharError}
          style={{
            color: displayError ? Colors.primary : TextColors.lighter,
          }}
        >
          20 characters min.
        </div>
      )}

      <textarea
        data-testing-id="timesheets-task-input"
        ref={textareaRef}
        className={styles.input}
        value={value}
        rows={1}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        style={{
          outline:
            displayError && (showOutlineError || isFocused)
              ? `2px solid ${Colors.primary}`
              : isFocused
              ? `2px solid ${Colors.secondaryDark}`
              : 'none',
        }}
      />
    </div>
  );
}

export default TextInput;
