import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import {
  AvailabilitySummaryObject,
  AvailableType,
} from '@a_team/models/dist/AvailabilityObject';
import { TalentSkillRating } from '@a_team/models/dist/TalentCategories';
import {
  AdminNotesScoreQueryParameters,
  BadgeQueryParameters,
  BuilderMissionStatus,
  ScrubQueryParameters,
} from '@ateams/api/dist/endpoints/TeamGraph';
import { UserId } from '@a_team/models/dist/UserObject';
import SpecializationsSelect from './SpecializationsSelect';
import AvailabilityInput from './AvailabilityQueryInput';
import MixedIndustryList from './MixedIndustryList';
import MixedSkillList from './MixedSkillList';
import HourlyRateRange from './HourlyRateRange';
import ScrubbingChecklist from './ScrubbingChecklist';
import CountryList from './CountryList';
import RankingParamsList from './RankingParamsList';
import VettingChecklist from './VettingChecklist';
import BadgeChecklist from '@src/components/TeamGraphBuilderQuery/BadgeChecklist';
import FilterGroupContainer from '@src/components/TeamGraphBuilderQuery/FilterGroupContainer';
import AppliedSinceInput from '@src/components/TeamGraphBuilderQuery/AppliedSinceInput';
import MissionStatusInput from '@src/components/TeamGraphBuilderQuery/MissionStatusInput';
import NotificationPreferences from '@src/components/TeamGraphBuilderQuery/NotificationPreferences';
import MissionSearch from '@src/components/TeamGraphBuilderQuery/MissionContext/MissionSearch';
import RoleSearch from '@src/components/TeamGraphBuilderQuery/MissionContext/RoleSearch';
import CityList from './CityList';
import { SelectableBuilder } from '@src/stores/TeamGraph/TargeterTabManager';
import {
  BorderRadius,
  Checkbox,
  Spacing,
  TextColors,
  ToggleSwitch,
} from '@ateams/components';
import UserStatusSelect from '@src/components/TeamGraphBuilderQuery/UserStatusSelect';
import RadioGroup from '@src/components/RadioGroup';
import CustomTagsSelect from './CustomTagsSelect';
import YearsExperienceFilter from './YearsExperienceFilter';
import CreatedAtRangeFilter from './CreatedAtRangeFilter';
import ScrubbedAtRangeFilter from './ScrubbedAtRangeFilter';
import { CompanyPicker } from '@src/components/CompanyPicker';
import { CompanyId } from '@a_team/models/dist/Company';
import { add, formatISO } from 'date-fns';
import { DateInput } from '../DateInput';
import { ColorNames } from '@a_team/ui-components';
import { useStores } from '@src/stores';
import { AccountId } from '@a_team/models/dist/Account';
import AccountSearch from './AccountSearch';
import IncludeExcludeIgnore from './IncludeExcludeIgnore';
import AccountUserSearch from './AccountUserSearch';
import IndustryYearsExperienceFilter from './IndustryYearsExperienceFilter';
import ApplicationStatusSelect from '@src/components/TeamGraphBuilderQuery/ApplicationStatusSelect';
import TileRadioButtonGroup from '@src/components/TeamGraphBuilderQuery/TileRadioButtonGroup';
import ManagementExperienceFilters from '@src/components/TeamGraphBuilderQuery/ManagementExperienceFilters';
import ZeroToOneFilters from '@src/components/TeamGraphBuilderQuery/ZeroToOneFilters';

export interface GenericTalent {
  id: string;
  label: string;
  rating?: TalentSkillRating;
}

export interface RoleFilter extends BadgeQueryParameters {
  keyword?: string;
  useKeywordForSorting?: boolean;
  semanticSearchQuery?: string;
  searchType?: 'semanticRaw' | 'hybrid';
  semanticResults?: number;
  countries?: string[];
  cities?: string[];
  requireCity?: boolean;
  requireCountry?: boolean;
  includeMissingLocation?: boolean;
  useTrustedTalentProfile?: boolean;
  specializations?: string[];
  adjacentSpecializations?: string[];
  requireMainSpecialization?: boolean;
  companies?: CompanyId[];
  includeAdjacentSpecializations?: boolean;
  useEstimatedAvailability?: boolean;
  availabilityDate?: string;
  weeklyHoursAvailable?: number;
  includeUnknownAvailability?: boolean;
  includeNotAvailable?: boolean;
  industriesExperienceIndustries?: string[];
  industriesExperienceMinYears?: number[];
  preferredIndustries?: string[];
  requiredIndustries?: string[];
  preferredSkills?: string[];
  requiredSkills?: string[];
  requireHourlyRate?: boolean;
  hourlyRateMin?: number;
  hourlyRateMax?: number;
  whFrom?: number;
  whTo?: number;
  whOl?: number;
  whTz?: string;
  teammates?: UserId[];
  includeApplicationDate?: boolean;
  latestApplicationDate?: string;
  requireMissionStatus?: boolean;
  contextMission?: string;
  appliedRoles?: string[];
  excludeAppliedRoles?: boolean;
  customTags?: string[];
  excludedCustomTags?: string[];
  preferredCustomTags?: string[];
  excludeOnlyIfHasAllTags?: boolean;
  missionStatus?: BuilderMissionStatus;
  missionStatusAvailabilityDate?: string;
  includeApplicationSkills?: boolean;
  includePausedNotifications?: boolean;
  requireAllNotificationCategories?: boolean;
  requiredNotificationCategories?: string[];
  isAdmin?: boolean;
  userStatus?: string[];
  notifiedForRoles?: string[];
  accountId?: AccountId;
  accountUserIds?: UserId[];
  requireAssignedSpecialization?: boolean;
  atLeastOneSkillShouldMatch?: boolean;
  atLeastOneCustomTagShouldMatch?: boolean;
  excludeBuildersWithPerformanceIssue?: boolean;
  includeSuggestedSkills?: boolean;
  includeSuggestedSpecializations?: boolean;
  requireHasScheduledEndDate?: boolean;
  requireVerifiedSkills?: boolean;
  page?: number;
  minYearsExperience?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  scrubbedDateFrom?: string;
  scrubbedDateTo?: string;
  lastLoginAt?: string;
  lastLoginAtExclude?: boolean;
  appliedSinceExclude?: boolean;
  includePlainReachoutEmailType?: boolean;
  includeRichReachoutEmailType?: boolean;
  includeStarredBuilders?: boolean;
  includeHiddenBuilders?: boolean;
  optedOutOfClientDiscovery?: boolean;
  requireCurrentlyInterviewing?: boolean;
  requireCurrentlyProposed?: boolean;
  profileCompletenessOperator?: '=' | '<';
  declaredIndustriesAsOneYear?: boolean;
  declaredIndustryYearsOfExperience?: number;
  requireAnyIndustry?: boolean;
  roleApplicationStatus?: string[];
  excludeOnHoldApplicants?: boolean;
  excludeAppliedStatus?: boolean;
  // vetting data
  mgmtExp?: string;
  mgmtPreferred?: boolean;
  zeroToOne?: boolean;
  zeroToOnePreferred?: boolean;
  requireProposedSpecializations?: boolean;
  requireProposedSkills?: boolean;
  // boosting
  scoreStrictnessFactor?: number;
  cityBoosting?: number;
  countryBoosting?: number;
  additionalSpecializationBoosting?: number;
  companyBoosting?: number;
  emailBoosting?: number;
  exactManagementExperienceBoosting?: number;
  hoursAvailableBoosting?: number;
  immediateAvailabilityBoosting?: number;
  managementExperienceBoosting?: number;
  industryExperienceBoosting?: number;
  keywordBoosting?: number;
  mainSpecializationBoosting?: number;
  midTermAvailabilityBoosting?: number;
  midTermUpdateBoosting?: number;
  preferredIndustriesBoosting?: number;
  preferredSkillsBoosting?: number;
  preferredTagsBoosting?: number;
  profileCompletenessBoosting?: number;
  recentUpdateBoosting?: number;
  requiredIndustriesBoosting?: number;
  requiredSkillsBoosting?: number;
  requiredTagsBoosting?: number;
  shortTermAvailabilityBoosting?: number;
  zeroToOneBoosting?: number;
  hourlyRateDecayRateOutside?: number;
  hourlyRateDefaultBoostingFactorIfMissing?: number;
  hourlyRateMinBoostingFactor?: number;
  hourlyRateWithinRangeBoostingFactor?: number;
}

interface Props {
  builders?: SelectableBuilder[];
  filter: RoleFilter;
  setFilterValue: (filter: Partial<RoleFilter>) => void;
  sidebar?: boolean;
  hideSpecializations?: boolean;
  hideBadges?: boolean;
  hideScrubbing?: boolean;
  hideVettingScores?: boolean;
  hideRankingParams?: boolean;
}

const useStyles = createUseStyles({
  container: {
    marginBottom: 16,
  },
  hourlyRateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  hourlyRateSidebar: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  skillMatchingRadioGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    '& > :not(:last-child)': {
      marginRight: Spacing.small,
    },
  },
  blockDatePickerWrapper: {
    borderRadius: BorderRadius.default,
    height: 'fit-content',
    width: '100%',
  },
  blockDatePicker: {
    padding: '0.6em 0.5em',
    marginBottom: 12,
    width: '100%',
    lineHeight: 'initial',
    borderRadius: BorderRadius.default,
    border: `1px solid ${TextColors.regularDark}`,
    '&:focus': {
      border: `1px solid ${ColorNames.Grey[1000]}`,
    },
  },
  tagsRadioButtons: {
    marginBottom: 22,
  },
});

function talentToString(
  talents: GenericTalent[],
  appendRating?: boolean,
): string[] {
  return (
    talents.map((talent) => {
      const { id, rating } = talent;
      return `${id.split('-')[0]}${appendRating && rating ? `-${rating}` : ''}`;
    }) ?? []
  );
}

const RoleFilters = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const { filter, setFilterValue, sidebar } = props;
  const { auth } = useStores();
  const [hideRankingParams, setHideRankingParams] = useState<boolean>(true);

  const {
    // profile filters
    useTrustedTalentProfile = false,
    specializations = [],
    adjacentSpecializations = [],
    userStatus,
    isAdmin,
    requireMainSpecialization,
    requireAssignedSpecialization,
    excludeBuildersWithPerformanceIssue,
    includeSuggestedSkills,
    includeSuggestedSpecializations,
    requireHasScheduledEndDate,
    includeAdjacentSpecializations,
    countries = [],
    includeMissingLocation,
    preferredSkills = [],
    requiredSkills = [],
    industriesExperienceIndustries = [],
    industriesExperienceMinYears = [],
    declaredIndustryYearsOfExperience,
    requiredIndustries = [],
    preferredIndustries = [],
    companies = [],
    availabilityDate,
    useEstimatedAvailability,
    weeklyHoursAvailable,
    includeUnknownAvailability,
    includeNotAvailable,
    whFrom,
    whTo,
    whOl,
    whTz,
    customTags,
    excludedCustomTags,
    excludeOnlyIfHasAllTags,
    preferredCustomTags,
    requiredNotificationCategories,
    requireAllNotificationCategories,
    includePausedNotifications,
    // applications
    includeApplicationSkills,
    includeApplicationDate,
    latestApplicationDate,
    // mission status
    contextMission,
    requireMissionStatus,
    missionStatus,
    missionStatusAvailabilityDate,
    appliedRoles,
    excludeAppliedRoles,
    // requireHourlyRate,
    hourlyRateMin,
    hourlyRateMax,
    // badges
    selectionTeam,
    beenOnMission,
    exceptionalATeamer,
    residentATeamer,
    vettedATeamer,
    vettingScheduled,
    vettingInterviewDate,
    unvetted,
    unqualified,
    includeUnscrubbed,
    limitedAccess,
    // vetting
    onlyExactScores,
    expertiseScore,
    interactionScore,
    englishScore,
    accentScore,
    // ace
    includeExceptional,
    includeVerified,
    includeLegacyUnknown,
    includeUnknown,
    includeInsufficient,
    notifiedForRoles,
    accountId,
    accountUserIds,
    requireVerifiedSkills,
    minYearsExperience,
    createdAtFrom,
    createdAtTo,
    scrubbedDateFrom,
    scrubbedDateTo,
    lastLoginAt,
    lastLoginAtExclude,
    appliedSinceExclude,
    includePlainReachoutEmailType,
    includeRichReachoutEmailType,
    includeStarredBuilders,
    includeHiddenBuilders,
    optedOutOfClientDiscovery,
    requireCurrentlyInterviewing,
    requireCurrentlyProposed,
    profileCompletenessOperator,
    roleApplicationStatus,
    excludeAppliedStatus,
    excludeOnHoldApplicants,
    // vetting
    mgmtExp,
    mgmtPreferred,
    zeroToOne,
    zeroToOnePreferred,
    requireProposedSkills,
    requireProposedSpecializations,
    cities,
    requireCity,
    requireCountry,
    // boosting
    scoreStrictnessFactor,
    cityBoosting,
    countryBoosting,
    additionalSpecializationBoosting,
    companyBoosting,
    emailBoosting,
    exactManagementExperienceBoosting,
    hoursAvailableBoosting,
    immediateAvailabilityBoosting,
    managementExperienceBoosting,
    industryExperienceBoosting,
    keywordBoosting,
    mainSpecializationBoosting,
    midTermAvailabilityBoosting,
    midTermUpdateBoosting,
    preferredIndustriesBoosting,
    preferredSkillsBoosting,
    preferredTagsBoosting,
    profileCompletenessBoosting,
    recentUpdateBoosting,
    requiredIndustriesBoosting,
    requiredSkillsBoosting,
    requiredTagsBoosting,
    shortTermAvailabilityBoosting,
    zeroToOneBoosting,
    hourlyRateDecayRateOutside,
    hourlyRateDefaultBoostingFactorIfMissing,
    hourlyRateMinBoostingFactor,
    hourlyRateWithinRangeBoostingFactor,
  } = filter;

  const setAvailability = (data: AvailabilitySummaryObject | null): void => {
    setFilterValue({
      availabilityDate: data?.availableFrom,
      weeklyHoursAvailable: data?.weeklyHoursAvailable
        ? Number(data.weeklyHoursAvailable)
        : undefined,
    });
  };

  const toggleRankingParams = () => {
    setHideRankingParams(!hideRankingParams);
  };

  const setNotificationPreferences = (data: Partial<RoleFilter>): void => {
    setFilterValue(data);
  };

  const setWorkingHours = (
    data: Pick<RoleFilter, 'whTz' | 'whFrom' | 'whTo' | 'whOl'> | null,
  ): void => {
    setFilterValue({
      whTo: data?.whTo ? Number(data.whTo) : undefined,
      whFrom: data?.whFrom ? Number(data.whFrom) : undefined,
      whTz: data?.whTz,
      whOl: data?.whOl,
    });
  };

  const setAvailabilityTypes = (
    data: Partial<{
      useEstimatedAvailability?: boolean;
      includeUnknownAvailability?: boolean;
      includeNotAvailable?: boolean;
    }>,
  ): void => {
    setFilterValue(data);
  };

  const setApplicationDate = (
    includeApplicationDate?: boolean,
    latestApplicationDate?: string,
    exclude?: boolean,
  ) => {
    setFilterValue({
      includeApplicationDate,
      latestApplicationDate,
      appliedSinceExclude: exclude,
    });
  };

  const setHourlyRate = (rates: (number | undefined)[]): void => {
    const [min, max] = rates;
    setFilterValue({ hourlyRateMin: min, hourlyRateMax: max });
  };

  const setIndustries = (
    requiredIndustries: GenericTalent[],
    preferredIndustries: GenericTalent[],
  ): void => {
    setFilterValue({
      requiredIndustries: talentToString(requiredIndustries),
      preferredIndustries: talentToString(preferredIndustries),
    });
  };

  const setCountries = (
    countries: string[],
    includeMissingLocation?: boolean,
    requireCountry?: boolean,
  ): void => {
    setFilterValue({
      countries,
      cities: [],
      includeMissingLocation,
      requireCountry,
    });
  };

  const setBadges = (
    badgeCriteria: Record<string, boolean | undefined>,
  ): void => {
    setFilterValue(badgeCriteria);
  };

  const setScrubbingChecklist = (
    scrubbingChecklist: ScrubQueryParameters,
  ): void => {
    setFilterValue(scrubbingChecklist);
  };

  const setVettingScores = (
    vettingCriteria: AdminNotesScoreQueryParameters,
  ): void => {
    setFilterValue(vettingCriteria);
  };

  const setMissionContext = (contextMission: string | undefined) => {
    setFilterValue({ contextMission, appliedRoles: undefined });
  };

  const setAppliedRoles = (appliedRoles: string[]) => {
    setFilterValue({ appliedRoles });
  };

  return (
    <div className={styles.container}>
      {!props.hideSpecializations && (
        <FilterGroupContainer
          sectionTitle={'Specializations'}
          clearCallBack={() => {
            setFilterValue({
              specializations: undefined,
              includeAdjacentSpecializations: undefined,
              adjacentSpecializations: undefined,
              requireMainSpecialization: undefined,
              includeSuggestedSpecializations: undefined,
              requireProposedSpecializations: undefined,
            });
          }}
        >
          <SpecializationsSelect
            mainSpecializationCheckBox
            includeAdjacentSpecializationsCheckbox
            includeBeenOnARoleCheckbox
            includeSuggestedSpecializations={includeSuggestedSpecializations}
            requireAssignedSpecialization={requireAssignedSpecialization}
            requireMainSpecialization={requireMainSpecialization}
            includeAdjacentSpecializations={includeAdjacentSpecializations}
            specializations={specializations}
            adjacentSpecializations={adjacentSpecializations}
            onChange={setFilterValue}
            sidebar={sidebar}
            requireProposedSpecializations={requireProposedSpecializations}
          />
        </FilterGroupContainer>
      )}
      <FilterGroupContainer
        sectionTitle={'Trusted Talent Profile'}
        clearCallBack={() => {
          setFilterValue({
            useTrustedTalentProfile: undefined,
          });
        }}
      >
        <Checkbox
          onChange={(e) => {
            setFilterValue({
              useTrustedTalentProfile: e.target.checked,
            });
          }}
          checked={useTrustedTalentProfile ?? false}
          label="Use Trusted Talent Profile"
          margin="none"
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Mission Context'}
        clearCallBack={() => {
          setFilterValue({
            contextMission: undefined,
            appliedRoles: undefined,
            notifiedForRoles: undefined,
            roleApplicationStatus: undefined,
            excludeAppliedStatus: undefined,
            excludeOnHoldApplicants: undefined,
          });
        }}
      >
        <MissionSearch
          selectedMission={contextMission}
          onChange={setMissionContext}
        />
        {contextMission && (
          <>
            <RoleSearch
              selectedMission={contextMission}
              itemCountPrefix="Applied Roles"
              roleSelection={appliedRoles}
              onChange={setAppliedRoles}
            />
            <ToggleSwitch
              label={excludeAppliedRoles ? 'Excluded' : 'Included'}
              onChange={() =>
                setFilterValue({
                  excludeAppliedRoles: !excludeAppliedRoles,
                })
              }
              disabled={!contextMission}
              checked={!excludeAppliedRoles}
              size="small"
              justify="right"
              checkedBackgroundColor="secondary"
            />
          </>
        )}
      </FilterGroupContainer>
      {contextMission && !!appliedRoles?.length && (
        <FilterGroupContainer
          sectionTitle={'Application Status'}
          clearCallBack={() => {
            setFilterValue({
              roleApplicationStatus: undefined,
              excludeAppliedStatus: undefined,
              excludeOnHoldApplicants: undefined,
            });
          }}
        >
          <ApplicationStatusSelect
            setFilterValue={setFilterValue}
            roleApplicationStatus={roleApplicationStatus}
            excludeAppliedStatus={excludeAppliedStatus}
            excludeOnHoldApplicants={excludeOnHoldApplicants}
          />
        </FilterGroupContainer>
      )}
      {contextMission && (
        <FilterGroupContainer
          sectionTitle={'Reach Out Roles'}
          clearCallBack={() => {
            setFilterValue({
              notifiedForRoles: undefined,
              includePlainReachoutEmailType: undefined,
              includeRichReachoutEmailType: undefined,
            });
          }}
        >
          <RoleSearch
            margin="bottom"
            selectedMission={contextMission}
            itemCountPrefix="Selected Roles"
            roleSelection={notifiedForRoles}
            onChange={(notifiedForRoles) =>
              setFilterValue({ notifiedForRoles })
            }
          />
          <IncludeExcludeIgnore
            label="Plain reach out"
            include={includePlainReachoutEmailType}
            onChange={(include) =>
              setFilterValue({
                includePlainReachoutEmailType: include,
              })
            }
            disabled={!filter.notifiedForRoles?.length}
          />
          <IncludeExcludeIgnore
            label="Rich reach out"
            include={includeRichReachoutEmailType}
            onChange={(include) =>
              setFilterValue({
                includeRichReachoutEmailType: include,
              })
            }
            disabled={!filter.notifiedForRoles?.length}
          />
        </FilterGroupContainer>
      )}
      <FilterGroupContainer
        sectionTitle={'Management Experience'}
        clearCallBack={() => {
          setFilterValue({
            mgmtExp: undefined,
            mgmtPreferred: undefined,
          });
        }}
      >
        <ManagementExperienceFilters
          setFilterValue={setFilterValue}
          mgmtExp={mgmtExp}
          mgmtPreferred={mgmtPreferred}
        />
      </FilterGroupContainer>{' '}
      <FilterGroupContainer
        sectionTitle={'Zero to One Experience'}
        clearCallBack={() => {
          setFilterValue({
            zeroToOne: undefined,
            zeroToOnePreferred: undefined,
          });
        }}
      >
        <ZeroToOneFilters
          setFilterValue={setFilterValue}
          zeroToOne={zeroToOne}
          zeroToOnePreferred={zeroToOnePreferred}
        />
      </FilterGroupContainer>
      {auth.withCompaniesInTargeter && (
        <FilterGroupContainer
          sectionTitle={'Companies'}
          clearCallBack={() => {
            setFilterValue({
              companies: undefined,
            });
          }}
        >
          <CompanyPicker
            isMulti
            useEmployeesCollection
            key={(companies || []).length === 0 ? 1 : 2}
            initiallySelectedCompanyIds={companies}
            onSelectCompanies={(companies) =>
              setFilterValue({
                companies: companies.map(({ id }) => id),
              })
            }
          />
        </FilterGroupContainer>
      )}
      <FilterGroupContainer
        sectionTitle={'Starred Builders'}
        clearCallBack={() => {
          setFilterValue({
            accountId: undefined,
            includeStarredBuilders: undefined,
            includeHiddenBuilders: undefined,
          });
        }}
      >
        <AccountSearch
          margin="bottom"
          selectedAccount={accountId}
          onChange={(accountIds) =>
            setFilterValue({ accountId: accountIds[0] })
          }
        />

        {accountId && (
          <>
            <AccountUserSearch
              margin="bottom"
              selectedAccount={accountId}
              itemCountPrefix="Selected Users"
              userSelection={accountUserIds}
              onChange={(accountUserIds) => setFilterValue({ accountUserIds })}
            />
            <IncludeExcludeIgnore
              label="Starred"
              include={includeStarredBuilders}
              onChange={(include) =>
                setFilterValue({
                  includeStarredBuilders: include,
                })
              }
            />
            <IncludeExcludeIgnore
              label="Hidden"
              include={includeHiddenBuilders}
              onChange={(include) =>
                setFilterValue({
                  includeHiddenBuilders: include,
                })
              }
            />
          </>
        )}
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle="Availability"
        clearCallBack={() => {
          setFilterValue({
            availabilityDate: undefined,
            requireHasScheduledEndDate: undefined,
            weeklyHoursAvailable: undefined,
            includeUnknownAvailability: undefined,
            useEstimatedAvailability: undefined,
            includeNotAvailable: undefined,
            includeApplicationDate: undefined,
            latestApplicationDate: undefined,
            requireMissionStatus: undefined,
            missionStatus: undefined,
            whTo: undefined,
            whFrom: undefined,
            whTz: undefined,
            whOl: undefined,
            appliedSinceExclude: undefined,
          });
        }}
      >
        <AvailabilityInput
          availability={{
            type: AvailableType.FutureDate,
            availableFrom: availabilityDate,
            weeklyHoursAvailable,
          }}
          availabilityDate={availabilityDate}
          weeklyHoursAvailable={weeklyHoursAvailable}
          whTz={whTz}
          whFrom={whFrom}
          whTo={whTo}
          whOl={whOl}
          onChangeWorkingHours={setWorkingHours}
          onChange={setAvailability}
          setTypes={setAvailabilityTypes}
          useEstimatedAvailability={useEstimatedAvailability}
          includeUnknownAvailability={includeUnknownAvailability}
          includeNotAvailable={includeNotAvailable}
          sidebar={sidebar}
        />
        <AppliedSinceInput
          includeApplicationDate={includeApplicationDate}
          applicationDate={latestApplicationDate}
          exclude={appliedSinceExclude}
          onChange={setApplicationDate}
        />
        <MissionStatusInput
          requireMissionStatus={requireMissionStatus || false}
          missionStatus={missionStatus}
          availabilityDate={missionStatusAvailabilityDate}
          setFilterValue={setFilterValue}
          requireHasScheduledEndDate={requireHasScheduledEndDate}
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Skills'}
        clearCallBack={() => {
          setFilterValue({
            requiredSkills: undefined,
            preferredSkills: undefined,
            includeApplicationSkills: undefined,
            excludeBuildersWithPerformanceIssue: undefined,
            requireVerifiedSkills: undefined,
            includeSuggestedSkills: undefined,
            requireProposedSkills: undefined,
          });
        }}
      >
        <MixedSkillList
          setFilterValue={setFilterValue}
          talentToString={talentToString}
          preferredSkills={preferredSkills}
          includeApplicationSkills={includeApplicationSkills}
          requireVerifiedSkills={requireVerifiedSkills}
          requiredSkills={requiredSkills}
          sidebar={sidebar}
          excludeBuildersWithPerformanceIssue={
            excludeBuildersWithPerformanceIssue
          }
          includeSuggestedSkills={includeSuggestedSkills}
          requireProposedSkills={requireProposedSkills}
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle="Skill Matching"
        clearCallBack={() => {
          setFilterValue({
            atLeastOneSkillShouldMatch: undefined,
          });
        }}
      >
        <RadioGroup
          className={styles.skillMatchingRadioGroup}
          items={[
            { label: 'At least one skill', value: true },
            { label: 'All skills', value: undefined },
          ]}
          buttonsStyle={{
            marginBottom: 10,
            border: '1px solid #C0C0C0',
            padding: '10px 12px',
            borderRadius: 4,
            width: 198,
          }}
          selectedValue={filter.atLeastOneSkillShouldMatch}
          onChange={(item) =>
            setFilterValue({
              atLeastOneSkillShouldMatch: item.value,
            })
          }
        />
      </FilterGroupContainer>
      <>
        <FilterGroupContainer
          sectionTitle={'User Tags'}
          clearCallBack={() => {
            setFilterValue({
              customTags: undefined,
              excludedCustomTags: undefined,
              preferredCustomTags: undefined,
            });
          }}
        >
          <CustomTagsSelect
            label={'Included tags'}
            customTags={customTags || []}
            onChange={(tags) => setFilterValue({ customTags: tags })}
            sidebar={sidebar}
          />
          <TileRadioButtonGroup
            label={'Tags matching'}
            options={[
              {
                label: 'Any tag',
                value: true,
                selected: filter.atLeastOneCustomTagShouldMatch === true,
              },
              {
                label: 'All tags',
                value: undefined,
                selected: filter.atLeastOneCustomTagShouldMatch !== true,
              },
            ]}
            onChange={(value) =>
              setFilterValue({ atLeastOneCustomTagShouldMatch: value })
            }
            className={styles.tagsRadioButtons}
          />

          <CustomTagsSelect
            label={'Excluded tags'}
            customTags={excludedCustomTags || []}
            onChange={(tags) => setFilterValue({ excludedCustomTags: tags })}
            sidebar={sidebar}
          />
          <TileRadioButtonGroup
            label={'Tags matching'}
            options={[
              {
                label: 'Any tag',
                value: undefined,
                selected: excludeOnlyIfHasAllTags !== true,
              },
              {
                label: 'All tags',
                value: true,
                selected: excludeOnlyIfHasAllTags === true,
              },
            ]}
            onChange={(value) =>
              setFilterValue({ excludeOnlyIfHasAllTags: value })
            }
            className={styles.tagsRadioButtons}
          />

          <CustomTagsSelect
            label={'Preferred tags'}
            customTags={preferredCustomTags || []}
            onChange={(tags) => setFilterValue({ preferredCustomTags: tags })}
            sidebar={sidebar}
          />
        </FilterGroupContainer>

        <FilterGroupContainer
          sectionTitle="Minimum Viable Profile"
          clearCallBack={() => {
            setFilterValue({
              profileCompletenessOperator: undefined,
            });
          }}
        >
          <RadioGroup
            className={styles.skillMatchingRadioGroup}
            items={[
              { label: 'Include', value: '=' },
              { label: 'Exclude', value: '<' },
              { label: 'Ignore', value: undefined },
            ]}
            buttonsStyle={{
              marginBottom: 10,
              border: '1px solid #C0C0C0',
              padding: '10px 12px',
              borderRadius: 4,
              width: 198,
            }}
            selectedValue={profileCompletenessOperator}
            onChange={(item) =>
              setFilterValue({
                profileCompletenessOperator: item.value,
              })
            }
          />
        </FilterGroupContainer>
      </>
      <FilterGroupContainer
        sectionTitle={'Hourly Rate'}
        clearCallBack={() => {
          setFilterValue({
            hourlyRateMax: undefined,
            hourlyRateMin: undefined,
          });
        }}
      >
        <div
          className={cx(
            styles.hourlyRateContainer,
            sidebar && styles.hourlyRateSidebar,
          )}
        >
          <HourlyRateRange
            key={`${hourlyRateMin}-${hourlyRateMax}`}
            hourlyRateRangeMin={hourlyRateMin}
            hourlyRateRangeMax={hourlyRateMax}
            onChange={setHourlyRate}
          />
        </div>
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Location'}
        clearCallBack={() => {
          setFilterValue({
            countries: undefined,
            cities: undefined,
            includeMissingLocation: undefined,
            requireCountry: undefined,
            requireCity: undefined,
          });
        }}
      >
        <CountryList
          countries={countries}
          onChange={setCountries}
          includeMissingLocation={includeMissingLocation}
          showMissingLocationCheckbox
          isClearable={false}
          requireCountry={requireCountry}
        />
        {countries.length > 0 ? (
          <CityList
            requireCity={requireCity}
            countries={countries}
            cities={cities || []}
            onChange={(filters) => setFilterValue(filters)}
          />
        ) : null}
      </FilterGroupContainer>
      {!props.hideBadges && (
        <FilterGroupContainer
          sectionTitle={'Badges'}
          clearCallBack={() => {
            setFilterValue({
              selectionTeam: undefined,
              beenOnMission: undefined,
              exceptionalATeamer: undefined,
              residentATeamer: undefined,
              vettedATeamer: undefined,
              vettingScheduled: undefined,
              unvetted: undefined,
              includeUnscrubbed: undefined,
              unqualified: undefined,
              limitedAccess: undefined,
              vettingInterviewDate: undefined,
            });
          }}
        >
          <BadgeChecklist
            badgeCriteria={{
              selectionTeam,
              beenOnMission,
              exceptionalATeamer,
              residentATeamer,
              vettedATeamer,
              vettingScheduled,
              unvetted,
              includeUnscrubbed,
              unqualified,
              limitedAccess,
              vettingInterviewDate,
            }}
            onChange={setBadges}
          />
        </FilterGroupContainer>
      )}
      {!props.hideScrubbing && (
        <FilterGroupContainer
          sectionTitle={'Ace Score'}
          clearCallBack={() => {
            setFilterValue({
              includeExceptional: undefined,
              includeVerified: undefined,
              includeLegacyUnknown: undefined,
              includeUnknown: undefined,
              includeInsufficient: undefined,
            });
          }}
        >
          <ScrubbingChecklist
            disabled={!unvetted}
            scrubCriteria={{
              includeExceptional,
              includeVerified,
              includeLegacyUnknown,
              includeUnknown,
              includeInsufficient,
            }}
            onChange={setScrubbingChecklist}
          />
        </FilterGroupContainer>
      )}
      <FilterGroupContainer
        sectionTitle={'User Status'}
        clearCallBack={() => {
          setFilterValue({
            isAdmin: false,
            userStatus: undefined,
            optedOutOfClientDiscovery: undefined,
            requireCurrentlyInterviewing: undefined,
            requireCurrentlyProposed: undefined,
          });
        }}
      >
        <UserStatusSelect
          setFilterValue={setFilterValue}
          selectedStatuses={userStatus}
          optedOutOfClientDiscovery={optedOutOfClientDiscovery}
          isAdmin={isAdmin}
          requireCurrentlyInterviewing={requireCurrentlyInterviewing}
          requireCurrentlyProposed={requireCurrentlyProposed}
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Notification Preferences'}
        clearCallBack={() => {
          setFilterValue({
            requiredNotificationCategories: undefined,
            requireAllNotificationCategories: undefined,
            includePausedNotifications: undefined,
          });
        }}
      >
        <NotificationPreferences
          requiredNotificationCategories={requiredNotificationCategories}
          requireAllNotificationCategories={requireAllNotificationCategories}
          includePausedNotifications={includePausedNotifications}
          onChange={setNotificationPreferences}
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Minimum years of experience'}
        clearCallBack={() => {
          setFilterValue({
            minYearsExperience: undefined,
          });
        }}
      >
        <YearsExperienceFilter
          minYearsExperience={minYearsExperience}
          onChange={(minYearsExperience) =>
            setFilterValue({ minYearsExperience })
          }
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Created at'}
        clearCallBack={() => {
          setFilterValue({
            createdAtFrom: undefined,
            createdAtTo: undefined,
          });
        }}
      >
        <CreatedAtRangeFilter
          createdAtFrom={createdAtFrom}
          createdAtTo={createdAtTo}
          onChange={(data) => setFilterValue(data)}
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Scrubbed at'}
        clearCallBack={() => {
          setFilterValue({
            scrubbedDateFrom: undefined,
            scrubbedDateTo: undefined,
          });
        }}
      >
        <ScrubbedAtRangeFilter
          scrubbedDateFrom={scrubbedDateFrom}
          scrubbedDateTo={scrubbedDateTo}
          onChange={(data) => setFilterValue(data)}
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Last login from'}
        clearCallBack={() => {
          setFilterValue({
            lastLoginAt: undefined,
            lastLoginAtExclude: undefined,
          });
        }}
      >
        <DateInput
          dateFormat={'dd/MM/yyyy'}
          maxDate={add(new Date(), { days: 0 })}
          minDate={new Date(2023, 2, 29)} // Dates are not stored in the database before 2023-03-29 (NEXUS-1084)
          popperPlacement={'right'}
          selected={lastLoginAt ? new Date(lastLoginAt) : null}
          className={styles.blockDatePicker}
          wrapperClassName={styles.blockDatePickerWrapper}
          placeholderText="Last login from..."
          onChange={(input) => {
            setFilterValue({
              lastLoginAt: formatISO(input as Date, { representation: 'date' }),
            });
          }}
        />
        <ToggleSwitch
          label={lastLoginAtExclude ? 'Excluded' : 'Included'}
          onChange={() => {
            setFilterValue({
              lastLoginAtExclude: !lastLoginAtExclude,
            });
          }}
          checked={!lastLoginAtExclude}
          size="small"
          justify="right"
          checkedBackgroundColor="secondary"
        />
      </FilterGroupContainer>
      <FilterGroupContainer
        sectionTitle={'Industries'}
        clearCallBack={() => {
          setFilterValue({
            requiredIndustries: undefined,
            preferredIndustries: undefined,
            industriesExperienceIndustries: [],
            industriesExperienceMinYears: [],
          });
        }}
      >
        <RadioGroup
          className={styles.skillMatchingRadioGroup}
          items={[
            { label: 'Any industry', value: true },
            { label: 'All industries', value: undefined },
          ]}
          buttonsStyle={{
            marginBottom: 10,
            border: '1px solid #C0C0C0',
            padding: '10px 12px',
            borderRadius: 4,
            width: 198,
          }}
          selectedValue={filter.requireAnyIndustry}
          onChange={(item) =>
            setFilterValue({
              requireAnyIndustry: item.value,
            })
          }
        />
        <IndustryYearsExperienceFilter
          industryYearsExperience={industriesExperienceIndustries.map(
            (industry, idx) => ({
              industryId: industry,
              minYearsOfExperience: industriesExperienceMinYears[idx],
            }),
          )}
          onChange={(newIndustiesExperience) =>
            setFilterValue({
              industriesExperienceIndustries: newIndustiesExperience.map(
                (i) => i.industryId,
              ),
              industriesExperienceMinYears: newIndustiesExperience.map(
                (i) => i.minYearsOfExperience,
              ),
            })
          }
        />
        <Checkbox
          onChange={(e) => {
            setFilterValue({
              declaredIndustryYearsOfExperience: e.target.checked
                ? 1
                : undefined,
            });
          }}
          checked={declaredIndustryYearsOfExperience !== undefined}
          label="Include declared industries as 1 YOE"
        />
        <MixedIndustryList
          onChange={setIndustries}
          preferredIndustries={preferredIndustries}
          requiredIndustries={requiredIndustries}
          sidebar={sidebar}
        />
      </FilterGroupContainer>
      {!props.hideVettingScores && (
        <FilterGroupContainer
          sectionTitle={'Evaluation Scores'}
          clearCallBack={() => {
            setFilterValue({
              expertiseScore: undefined,
              interactionScore: undefined,
              englishScore: undefined,
              accentScore: undefined,
            });
          }}
        >
          <Checkbox
            onChange={(e) =>
              setFilterValue({
                onlyExactScores: e.target.checked || undefined,
              })
            }
            checked={onlyExactScores}
            label="Only exact scores"
          />
          <VettingChecklist
            vettingCriteria={{
              expertiseScore,
              interactionScore,
              englishScore,
              accentScore,
            }}
            onChange={setVettingScores}
          />
        </FilterGroupContainer>
      )}
      <ToggleSwitch
        label={
          !hideRankingParams ? 'Hide Ranking Params' : 'Show Ranking Params'
        }
        onChange={toggleRankingParams}
        checked={!hideRankingParams}
        size="small"
        justify="left"
        checkedBackgroundColor="secondary"
      />
      {!hideRankingParams && (
        <FilterGroupContainer
          sectionTitle={'Ranking params'}
          buttonName={'Reset'}
          clearCallBack={() => {
            setFilterValue({
              scoreStrictnessFactor: undefined,
              cityBoosting: undefined,
              countryBoosting: undefined,
              additionalSpecializationBoosting: undefined,
              companyBoosting: undefined,
              emailBoosting: undefined,
              exactManagementExperienceBoosting: undefined,
              managementExperienceBoosting: undefined,
              hoursAvailableBoosting: undefined,
              immediateAvailabilityBoosting: undefined,
              industryExperienceBoosting: undefined,
              keywordBoosting: undefined,
              mainSpecializationBoosting: undefined,
              midTermAvailabilityBoosting: undefined,
              midTermUpdateBoosting: undefined,
              preferredIndustriesBoosting: undefined,
              preferredSkillsBoosting: undefined,
              preferredTagsBoosting: undefined,
              profileCompletenessBoosting: undefined,
              recentUpdateBoosting: undefined,
              requiredIndustriesBoosting: undefined,
              requiredSkillsBoosting: undefined,
              requiredTagsBoosting: undefined,
              shortTermAvailabilityBoosting: undefined,
              zeroToOneBoosting: undefined,
              hourlyRateDecayRateOutside: undefined,
              hourlyRateDefaultBoostingFactorIfMissing: undefined,
              hourlyRateMinBoostingFactor: undefined,
              hourlyRateWithinRangeBoostingFactor: undefined,
            });
          }}
        >
          <RankingParamsList
            setFilterValue={setFilterValue}
            params={{
              scoreStrictnessFactor,
              cityBoosting,
              countryBoosting,
              additionalSpecializationBoosting,
              companyBoosting,
              emailBoosting,
              exactManagementExperienceBoosting,
              managementExperienceBoosting,
              hoursAvailableBoosting,
              immediateAvailabilityBoosting,
              industryExperienceBoosting,
              keywordBoosting,
              mainSpecializationBoosting,
              midTermAvailabilityBoosting,
              midTermUpdateBoosting,
              preferredIndustriesBoosting,
              preferredSkillsBoosting,
              preferredTagsBoosting,
              profileCompletenessBoosting,
              recentUpdateBoosting,
              requiredIndustriesBoosting,
              requiredSkillsBoosting,
              requiredTagsBoosting,
              shortTermAvailabilityBoosting,
              zeroToOneBoosting,
              hourlyRateDecayRateOutside,
              hourlyRateDefaultBoostingFactorIfMissing,
              hourlyRateMinBoostingFactor,
              hourlyRateWithinRangeBoostingFactor,
            }}
          />
        </FilterGroupContainer>
      )}
    </div>
  );
};

export default RoleFilters;
