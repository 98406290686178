import { CommunityBadgeType } from '@a_team/models/dist/UserObject';
import communityLeader from './communityLeader.svg';
import communityLeaderV2 from './communityLeaderV2.svg';
import guildAi from './guildAi.svg';
import guildAiV2 from './guildAiV2.svg';
import guildFintech from './guildFintech.svg';
import guildFintechV2 from './guildFintechV2.svg';
import guildFrontend from './guildFrontend.svg';
import guildFrontendV2 from './guildFrontendV2.svg';
import guildHealthcare from './guildHealthcare.svg';
import guildHealthcareV2 from './guildHealthcareV2.svg';
import powerUser from './powerUser.svg';
import powerUserV2 from './powerUserV2.svg';
import valuableFeedbackGiver from './valuableFeedbackGiver.svg';
import valuableFeedbackGiverV2 from './valuableFeedbackGiverV2.svg';
import AIAugmentedCourseGraduates from './AIAugmentedCourseGraduates.svg';

export const availableCommunityBadges: Record<
  CommunityBadgeType,
  { title: string; desc: string; badge: string; badgeV2?: string }
> = {
  [CommunityBadgeType.PowerUser]: {
    title: 'Power user',
    desc: 'Builders who actively participate in out beta community initiatives.',
    badge: powerUser,
    badgeV2: powerUserV2,
  },
  [CommunityBadgeType.CommunityLeader]: {
    title: 'Community leader',
    desc: 'Builders leading a micro community.',
    badge: communityLeader,
    badgeV2: communityLeaderV2,
  },
  [CommunityBadgeType.GuildFintech]: {
    title: 'Fintech guild',
    desc: 'Members of Fintech guild.',
    badge: guildFintech,
    badgeV2: guildFintechV2,
  },
  [CommunityBadgeType.GuildHealthcare]: {
    title: 'Healthtech guild',
    desc: 'Members of Healthtech guild.',
    badge: guildHealthcare,
    badgeV2: guildHealthcareV2,
  },
  [CommunityBadgeType.GuildAI]: {
    title: 'A.I. guild',
    desc: 'Members of A.I. guild.',
    badge: guildAi,
    badgeV2: guildAiV2,
  },
  [CommunityBadgeType.GuildFrontend]: {
    title: 'Front-end guild',
    desc: 'Members of Front-end guild.',
    badge: guildFrontend,
    badgeV2: guildFrontendV2,
  },
  [CommunityBadgeType.ValuableFeedbackGiver]: {
    title: 'Valuable feedback giver',
    desc: 'Builders who provide feedback to help improve A.Team platform.',
    badge: valuableFeedbackGiver,
    badgeV2: valuableFeedbackGiverV2,
  },
  [CommunityBadgeType.AIAugmentedCourseGraduates]: {
    title: 'AI Course Graduate',
    desc: 'Completed the AI augmented training.',
    badge: AIAugmentedCourseGraduates,
    badgeV2: AIAugmentedCourseGraduates,
  },
};
