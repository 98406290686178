import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { theme } from '@a_team/ui-components';
import { TalentCategoryId } from '@a_team/models/dist/TalentCategories';
import {
  VettingType,
  vettingTypeToStatusMap,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingProcessEmailTemplates } from '@a_team/models/dist/vetting-processes/emails';
import { CalendarUrlTypes } from '@ateams/api/dist/endpoints/vetting-process';
import { Colors, SelectOption, SelectProps, Spacing } from '@ateams/components';
import { Card } from '@src/views/VettingFeedbackForm/components/card';
import { InputContainer } from '@src/views/VettingFeedbackForm/components/input-container';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import { TextInput } from '@src/views/VettingFeedbackForm/components/text-input';
import { Separator } from '@src/views/VettingFeedbackForm/components/separator';
import { Size } from '@src/views/VettingFeedbackForm/components/typography';
import {
  Select,
  TalentCategorySelector,
  UserSelector,
} from '@src/views/VettingFeedbackForm/components/select';
import { Select as SelectComponent } from '@ateams/components';
import { OnTalentCategorySelectorChange } from '@src/components/TalentCategorySelector';
import {
  EvaluationInviteModalFormTitle,
  OnSendEvaluationEmailChange,
} from './title';
import {
  EmailTemplateOptionType,
  EmailTemplateSelector,
  emailTemplateToOption,
  OnEmailTemplateSelect,
} from '../templates/email-template-selector';
import { EmailBodyEditor, OnEmailBodyChange } from './email-body-editor';
import { createEmailTemplate, getDefaultEmailTemplate } from '../templates';
import { UserId } from '@a_team/models/dist/UserObject';
import { OnUserSelect } from '@src/components/UserSelector';
import { useStores } from '@src/stores';
import { Vetter } from '@a_team/models/dist/vetter';
import { apiVetter } from '@ateams/api';
import useDebounceState from '@src/hooks/useDebounceState';

const EVALUATION_TYPE_OPTIONS = Object.entries(vettingTypeToStatusMap).map(
  ([key, label]) => ({ value: key, label }),
);

const calendarUrlTypesLabelMap: Record<CalendarUrlTypes, string> = {
  [CalendarUrlTypes.OwnCalendar]: 'Use your own calendar URL',
  [CalendarUrlTypes.Random]: 'Random interviewer calendar',
};

const CALENDAR_URL_OPTIONS = Object.entries(calendarUrlTypesLabelMap).map(
  ([key, label]) => ({ value: key, label }),
);

export interface EvaluationInvitePayload {
  sendEvaluationEmail: boolean;
  contactOwner: UserId;
  vettingType: VettingType;
  category: TalentCategoryId;
  calendarUrlType: CalendarUrlTypes;
  emailSubject: string;
  emailBody: string;
  templateType: VettingProcessEmailTemplates;
  sendInThread: boolean;
  selectedVetterIds?: string[];
}

export type OnEvaluationInviteModalFormChange = (
  payload: Partial<EvaluationInvitePayload>,
) => void;

export interface EvaluationInviteModalFormProps {
  isNewProcess: boolean;
  emailsSentCount: number;
  hasSubmittedPreVettingForm?: boolean;
  defaultContactOwner?: UserId;
  defaultEvaluationType?: VettingType;
  defaultCategory?: TalentCategoryId;
  onChange?: OnEvaluationInviteModalFormChange;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    padding: 0,
    paddingTop: Spacing.large,
  },
  title: {
    background: Colors.backgroundLight,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    borderBottom: `1px solid ${theme.colors.Grey[300]}`,
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  section: {
    display: 'flex',
    flex: 1,
    width: 'fit-content',
    padding: '16px 24px',

    '&:last-child': {
      paddingBottom: Spacing.large,
    },
  },
  textInputSection: {
    width: '100%',
  },
  textInputContainer: {
    flexDirection: 'column',
  },
  contactOwnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactOwnerLabel: {
    marginRight: Spacing.medium,
    width: '180px',
  },
  contactOwnerSelect: {
    width: '350px',
  },
  interviewersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  interviewersLabel: {
    marginRight: Spacing.medium,
    width: '180px',
  },
  interviewersSelect: {
    width: '350px',
  },
});

const selectOverrideStyles: SelectProps<false>['styles'] = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none !important',
    background: 'none',
    fontSize: '14px',
    height: '20px',
    minHeight: 'unset',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

/** {@link https://buildateam.atlassian.net/browse/NEXUS-461} */
function getDefaultCalendarUrlType(vettingType: VettingType) {
  if (vettingType === VettingType.InternalTechnical) {
    return CalendarUrlTypes.Random;
  }

  return CalendarUrlTypes.OwnCalendar;
}

interface VetterOption {
  key: string;
  value: string;
  label: string;
  vetter: Vetter;
}

export const EvaluationInviteModalForm: React.FC<
  EvaluationInviteModalFormProps
> = (props) => {
  const { auth } = useStores();
  const { emailsSentCount, hasSubmittedPreVettingForm, className } = props;
  const styles = useStyles();

  const initialTemplateType = useMemo(() => {
    return getDefaultEmailTemplate(
      props.defaultEvaluationType || VettingType.SelectionTeam,
      emailsSentCount,
      hasSubmittedPreVettingForm,
    );
  }, []);
  const initialTemplate = useMemo(() => {
    return createEmailTemplate(
      initialTemplateType,
      auth.withNewEvaluation || false,
    );
  }, []);

  const [evaluationTypeOption, setEvaluationTypeOption] = useState(
    () =>
      EVALUATION_TYPE_OPTIONS.find(
        (option) =>
          option.value ===
          (props.defaultEvaluationType || VettingType.SelectionTeam),
      ) as SelectOption,
  );
  const [evaluationCategory, setEvaluationCategory] = useState(
    props.defaultCategory,
  );
  const [sendEvaluationEmail, setSendEvaluationEmail] = useState(true);
  const [calendarUrlType, setCalendarUrlType] = useState(() =>
    getDefaultCalendarUrlType(evaluationTypeOption.value as VettingType),
  );
  const [template, setTemplate] = useState(initialTemplate);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplateOptionType>(
    () => emailTemplateToOption(initialTemplateType),
  );
  const [emailSubject, setEmailSubject] = useState(() =>
    template.sendInThread ? `Re: ${template.subject}` : template.subject,
  );
  const [emailTemplateBody, setEmailTemplateBody] = useState(
    initialTemplate.body,
  );
  const [contactOwner, setContactOwner] = useState(props.defaultContactOwner);
  const [vetters, setVetters] = useState<VetterOption[]>([]);
  const [selectedVetters, setSelectedVetters] = useState<VetterOption[]>([]);
  const [, setSearchQuery] = useDebounceState<string>(
    '',
    (query) => loadVettersOptions(query),
    700,
  );

  const vetterToOption = (vetter: Vetter): VetterOption => {
    return {
      key: vetter.id,
      value: vetter.id,
      label: vetter.user.fullName,
      vetter,
    };
  };

  const loadVettersOptions = async (expression: string) => {
    if (!expression || expression.length === 0) {
      return;
    }

    const vetters = await apiVetter.adminSearchVettersByExpression(auth, {
      expression,
    });

    setVetters(vetters.items.map(vetterToOption));
  };

  useEffect(() => {
    props.onChange?.({
      sendEvaluationEmail,
      contactOwner,
      vettingType: evaluationTypeOption.value as VettingType,
      category: evaluationCategory,
      calendarUrlType,
      emailSubject: initialTemplate.subject,
      emailBody: initialTemplate.body,
      templateType: initialTemplate.templateType,
      sendInThread: initialTemplate.sendInThread,
    });
  }, []);

  const onSendEvaluationEmailChange: OnSendEvaluationEmailChange = (
    sendEvaluationEmail,
  ) => {
    setSendEvaluationEmail(sendEvaluationEmail);
    props.onChange?.({ sendEvaluationEmail });
  };

  const onEvaluationTypeChange: SelectProps['onChange'] = (option) => {
    if (option) {
      const vettingType = option.value as VettingType;
      const templateType = getDefaultEmailTemplate(
        vettingType,
        emailsSentCount,
        hasSubmittedPreVettingForm,
      );
      const newTemplate = createEmailTemplate(
        templateType,
        auth.withNewEvaluation || false,
      );
      const { body, subject, sendInThread } = newTemplate;
      const calendarUrlType = getDefaultCalendarUrlType(vettingType);

      setEvaluationTypeOption(option);
      setTemplate(newTemplate);
      setEmailTemplate(emailTemplateToOption(templateType));
      setEmailTemplateBody(body);
      setEmailSubject(sendInThread ? `Re: ${subject}` : subject);
      setCalendarUrlType(calendarUrlType);
      props.onChange?.({
        vettingType,
        emailSubject: subject,
        emailBody: body,
        templateType,
        sendInThread,
        calendarUrlType,
      });
    }
  };

  const onEvaluationCategoryChange: OnTalentCategorySelectorChange<false> = (
    option,
  ) => {
    if (option) {
      setEvaluationCategory(option.value);
      props.onChange?.({ category: option.value });
    }
  };

  const onCalendarUrlChange: SelectProps['onChange'] = (option) => {
    if (option) {
      const calendarUrlType = option.value as CalendarUrlTypes;

      setCalendarUrlType(calendarUrlType);
      props.onChange?.({ calendarUrlType });
    }
  };

  const onEmailTemplateChange: OnEmailTemplateSelect = (option) => {
    if (option) {
      const { template: templateType } = option;
      const template = createEmailTemplate(
        templateType,
        auth.withNewEvaluation || false,
      );
      const { body, subject, sendInThread } = template;

      setTemplate(template);
      setEmailTemplate(option);
      setEmailTemplateBody(body);
      setEmailSubject(sendInThread ? `Re: ${subject}` : subject);
      props.onChange?.({
        emailSubject: subject,
        emailBody: body,
        templateType,
        sendInThread,
      });
    }
  };

  const onEmailSubjectchange = (subject: string) => {
    setEmailSubject(subject);
    props.onChange?.({ emailSubject: subject });
  };

  const onEmailBodyChange: OnEmailBodyChange = (emailBody) => {
    props.onChange?.({ emailBody });
  };

  const onContactOwnerSelect: OnUserSelect = (userOption) => {
    if (userOption) {
      const contactOwner = userOption.user?.uid;
      setContactOwner(contactOwner);
      props.onChange?.({ contactOwner });
    }
  };

  const onSelectedInterviewersChange = (vetters: VetterOption[]) => {
    setSelectedVetters(vetters);
    props.onChange?.({ selectedVetterIds: vetters.map((v) => v.vetter.id) });
  };

  const showCalendarUrl = [
    VettingType.InternalTechnical,
    VettingType.InternalNonTechnical,
  ].includes(evaluationTypeOption.value as VettingType);

  const calendarUrlOption = useMemo(
    () =>
      CALENDAR_URL_OPTIONS.find((option) => option.value === calendarUrlType),
    [calendarUrlType],
  );

  const title = useMemo(
    () => (
      <EvaluationInviteModalFormTitle
        isNewProcess={props.isNewProcess}
        emailsSentCount={props.emailsSentCount}
        sendEvaluationEmail={sendEvaluationEmail}
        onSendEvaluationEmailChange={onSendEvaluationEmailChange}
      />
    ),
    [
      props.isNewProcess,
      props.emailsSentCount,
      sendEvaluationEmail,
      onSendEvaluationEmailChange,
    ],
  );

  return (
    <>
      <div className={styles.contactOwnerContainer}>
        <Text className={styles.contactOwnerLabel}>Select an admin</Text>
        <UserSelector
          defaultValue={contactOwner}
          onUserSelect={onContactOwnerSelect}
          isClearable={false}
          className={styles.contactOwnerSelect}
        />
      </div>

      <div className={styles.interviewersContainer}>
        <Text className={styles.interviewersLabel}>Select interviewers</Text>
        <SelectComponent
          isMulti
          hideTags
          showItemCount
          isSearchable
          itemCountPrefix="Interviewers"
          placeholder=""
          value={selectedVetters}
          onInputChange={(value) => setSearchQuery(value)}
          onChange={(options) =>
            onSelectedInterviewersChange(options.map((option) => option))
          }
          isOptionSelected={(option) =>
            selectedVetters.some(
              (selectedOption) => selectedOption.value === option.value,
            )
          }
          options={vetters}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          className={styles.interviewersSelect}
        />
      </div>
      <Card
        title={title}
        titleClassName={styles.title}
        className={cx(styles.container, className)}
      >
        <div className={styles.inputsRow}>
          <div className={styles.section}>
            <div>
              <Select
                label={'Evaluation Type'}
                placeholder={'Select a type'}
                value={evaluationTypeOption}
                options={EVALUATION_TYPE_OPTIONS}
                onChange={onEvaluationTypeChange}
                isClearable={false}
                hideSelectedOptions={false}
                labelSize={Size.Large}
                overrideStyles={selectOverrideStyles}
              />
            </div>
          </div>

          <Separator direction={'vertical'} />

          <div className={styles.section}>
            <div>
              <TalentCategorySelector
                label={'Specialization'}
                placeholder={'Select a specialization'}
                onChange={onEvaluationCategoryChange}
                defaultValue={evaluationCategory}
                isVettingEligible
                isClearable={false}
                hideSelectedOptions={false}
                labelSize={Size.Large}
                overrideStyles={selectOverrideStyles}
              />
            </div>
          </div>
        </div>

        {sendEvaluationEmail && (
          <>
            {showCalendarUrl && (
              <>
                <Separator direction={'horizontal'} />

                <div className={styles.section}>
                  <Select
                    label={'Calendar URL Type'}
                    value={calendarUrlOption}
                    onChange={onCalendarUrlChange}
                    options={CALENDAR_URL_OPTIONS}
                    isClearable={false}
                    hideSelectedOptions={false}
                    labelSize={Size.Large}
                    overrideStyles={selectOverrideStyles}
                  />
                </div>
              </>
            )}

            <Separator direction={'horizontal'} />

            <div className={styles.section}>
              <EmailTemplateSelector
                label={'Template'}
                value={emailTemplate}
                onChange={onEmailTemplateChange}
                evaluationType={evaluationTypeOption.value as VettingType}
                isClearable={false}
                hideSelectedOptions={false}
                labelSize={Size.Large}
                overrideStyles={selectOverrideStyles}
              />
            </div>

            <Separator direction={'horizontal'} />

            <div className={cx(styles.section, styles.textInputSection)}>
              <TextInput
                label={'Subject'}
                placeholder={'Enter email subject'}
                value={emailSubject}
                onChange={(e) => onEmailSubjectchange(e.target.value)}
                isReadOnly={template.sendInThread}
                withLine={false}
                inputContainerClassName={styles.textInputContainer}
                labelSize={Size.Large}
              />
            </div>

            <Separator direction={'horizontal'} />

            <div className={styles.section}>
              <InputContainer label={'Body'} labelSize={Size.Large}>
                <EmailBodyEditor
                  template={emailTemplateBody}
                  onChange={onEmailBodyChange}
                />
              </InputContainer>
            </div>
          </>
        )}
      </Card>
    </>
  );
};
