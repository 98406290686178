import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import MissionFilters from '../MissionFilters';
import { toJS } from 'mobx';
import CardList from '../CardList';
import AllMissionCard from '../MissionControlCard/AllMissionCard';
import NoResults from '@src/components/NoResults';
import TextButton from '@src/components/TextButton';
import {
  AppliedMissionCardObject,
  CurrentUserMissionApplicationObject,
} from '@a_team/models/dist/MissionApplicationObject';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import useWithdrawApplication from '../MissionControlCard/useWithdrawApplication';
import WithdrawApplicationModal from '../WithdrawApplicationModal';
import { LoadingCards } from '@src/views/MissionControl/LoadingCards';
import MissionControlCardV2 from '../MissionControlCard/MissionControlCardV2/MissionControlCardV2';
import { useGridMissionControlCardStyles } from '../MissionControlCard/common/styles';
import { useQueryClientSignalMissions } from '@src/rq/signals';
import { getIsMissionLiked } from '../MissionControlCard/common/utils';
import Reserve from '../Reserve';
import {
  useGetAllMissionsQuery,
  useGetRecommendedMissionsQuery,
} from '@src/rq/missions';

const findUserApplication = (
  mission: MissionCardObject,
  appliedMissions: AppliedMissionCardObject[],
): CurrentUserMissionApplicationObject | undefined => {
  const appliedMission = appliedMissions.find(
    (appliedMission) => appliedMission.mission.mid === mission.mid,
  );

  if (!appliedMission) {
    return undefined;
  }

  return appliedMission.application;
};

const AllMissions = (): ReactElement => {
  const { auth, missionControl } = useStores();
  const gridStyles = useGridMissionControlCardStyles();

  const { withAllMissionControlCards, withBuilderLikes } = auth;

  const { filtersOn, clearFilters, filterByUserSettings, appliedUserFilters } =
    missionControl;
  const {
    isLoading: isLoadingAllMissions,
    isError: isErrorLoadingAllMissions,
  } = useGetAllMissionsQuery();
  const {
    isLoading: isLoadingRecommendedMissions,
    isError: isErrorLoadingRecommendedMissions,
  } = useGetRecommendedMissionsQuery();

  const missions = useMemo(
    () => missionControl.filteredMissions,
    [
      isLoadingAllMissions,
      isErrorLoadingAllMissions,
      missionControl.filteredMissions,
    ],
  );
  const recommendedIndices = useMemo(
    () => missionControl.getRecommendedMissionIndices,
    [isLoadingRecommendedMissions, isErrorLoadingRecommendedMissions],
  );
  const appliedMissions = missionControl.getApplied;

  const { data } = useQueryClientSignalMissions({
    enabled: withBuilderLikes,
  });

  const {
    selectedApplication,
    confirmationModalOpen,
    onConfirmWithdrawModalOpen,
    onConfirmModalClose,
  } = useWithdrawApplication();

  const loading =
    (isLoadingAllMissions || isLoadingRecommendedMissions) &&
    !auth.currentUser &&
    !appliedUserFilters;

  const showEmptyState =
    auth.currentUser &&
    !missionControl.loading &&
    withAllMissionControlCards !== undefined &&
    !missions.length;

  const resetFilters = (): void => {
    if (auth && auth.currentUser) {
      filterByUserSettings(auth.currentUser);
    } else {
      clearFilters();
    }
  };

  const isLimitedAccess = auth.limitedAccess;

  if (isLimitedAccess) {
    return <Reserve />;
  }

  return (
    <>
      <MissionFilters disabled={loading} />
      {!loading &&
      withAllMissionControlCards !== undefined &&
      !withAllMissionControlCards ? (
        <CardList>
          <>
            {missions.map((mission) => (
              <AllMissionCard
                key={mission.mid}
                mission={toJS(mission)}
                application={findUserApplication(mission, appliedMissions)}
                onWithdrawClick={onConfirmWithdrawModalOpen}
                recommended={recommendedIndices.get(mission.mid) !== undefined}
              />
            ))}
            <WithdrawApplicationModal
              open={confirmationModalOpen}
              application={selectedApplication}
              onClose={onConfirmModalClose}
            />
          </>
        </CardList>
      ) : null}

      {!loading &&
        withAllMissionControlCards !== undefined &&
        withAllMissionControlCards && (
          <div className={gridStyles.container}>
            {missions.map((mission) => (
              <MissionControlCardV2
                key={mission.mid}
                mission={mission}
                hideMatchedOn
                companyLiked={getIsMissionLiked(data, mission.mid)}
              />
            ))}
          </div>
        )}

      {loading && (
        <div className={gridStyles.container}>
          <LoadingCards type={'all'} />
        </div>
      )}

      {showEmptyState &&
        (filtersOn ? (
          <NoResults
            imageType="noSearchResults"
            title="No results found!"
            text={
              <>
                <p>Sorry, we didn't find any missions that fit your needs.</p>
                <p>
                  Please update your selected filters or{' '}
                  <TextButton onClick={resetFilters} color="primary">
                    start again
                  </TextButton>
                  .
                </p>
              </>
            }
          />
        ) : (
          <NoResults
            imageType="noSearchResults"
            title="We don't have any new missions"
            text={
              <>
                <p>Unfortunately, right now we don't have any new missions.</p>
                <p>Please check Mission Control later.</p>
              </>
            }
          />
        ))}
    </>
  );
};

export default observer(AllMissions);
